import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    infoScreen: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      backgroundColor: theme.palette.common.white,
    },
    sharingFrame: {
      background: theme.palette.secondary.main,
    },
    sharingFrameLeftSide: {
      color: theme.palette.common.white,
      margin: theme.spacing(5, 0),
      padding: theme.spacing(0, 3),

      [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(3, 0),
      },
      [theme.breakpoints.down('xs')]: {
        textAlign: 'center',
      },
    },
    sharingFrameText: {
      '& span': {
        color: theme.palette.primary.main,
      },
    },
    sharingFrameRightSide: {
      display: 'flex',
      justifyContent: 'flex-end',
      '& img': {
        display: 'block',
        maxWidth: '100%',
        objectFit: 'cover',
        objectPosition: 'left',
      },
    },
    infoContainer: {
      display: 'flex',
      flex: 1,
      width: '100%',
      flexFlow: 'column nowrap',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      padding: theme.spacing(6, 4),

      '& img': {
        width: theme.spacing(20),
        marginBottom: theme.spacing(1),
      },

      [theme.breakpoints.down('sm')]: {
        '& img': {
          width: theme.spacing(15),
        },
      },
    },
    title: {
      fontSize: '2.5rem',

      [theme.breakpoints.down('sm')]: {
        fontSize: '1.75rem',
      },
    },
    subtitle: {
      fontWeight: 'normal',
      color: '#595959',
      lineHeight: '1.2em',

      [theme.breakpoints.down('sm')]: {
        fontSize: '1.125rem',
      },
    },
    carousel: {
      marginTop: theme.spacing(6),
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      '& .BrainhubCarousel__container': {
        maxWidth: theme.breakpoints.values.lg,
      },
      '& .BrainhubCarouselItem': {
        alignItems: 'flex-start',
      },
      '& .BrainhubCarousel__arrows': {
        backgroundColor: 'transparent',
        '&:hover:enabled': {
          backgroundColor: 'transparent',
          '& span': {
            borderColor: theme.palette.primary.main,
          },
        },
      },
      '& .BrainhubCarousel__arrowLeft span': {
        borderColor: theme.palette.primary.main,
      },
      '& .BrainhubCarousel__arrowRight span': {
        borderColor: theme.palette.primary.main,
      },
    },
    item: {
      maxWidth: 'min-content',

      '& > h5': {
        minWidth: 184,
        width: 'max-content',
      },
    },
  })
);
