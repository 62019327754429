import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { fontJosefinSans } from 'theme/mainTheme';

export default makeStyles((theme: Theme) =>
  createStyles({
    sideNavAvatar: {
      width: theme.spacing(9),
      height: theme.spacing(9),
      backgroundColor: theme.palette.primary.main,
      margin: theme.spacing(4, 'auto', 1),
    },
    sideNavDrawer: {
      width: theme.custom.dashboardDrawer.width,
      flexShrink: 0,
    },
    sideNavDrawerPaper: {
      width: theme.custom.dashboardDrawer.width,
      backgroundColor: theme.palette.secondary.main,
      padding: theme.spacing(3, 0),
    },
    sideNavItemText: {
      color: theme.palette.grey[300],
    },
    sideNavBottomItem: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
      color: theme.palette.primary.main,
    },
    sideNavBottomItemText: {
      color: theme.palette.primary.main,
    },
    sideNavLogoImg: {
      display: 'block',
      margin: theme.spacing(3, 'auto', 4),
    },
    sideNavWelcomeText: {
      color: theme.palette.common.white,
      fontWeight: 'bold',
    },
    sideNavDivider: {
      backgroundColor: theme.palette.grey[300],
      margin: theme.spacing(0, 3),
    },
    sideNavItem: {
      color: theme.palette.primary.main,
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      '&:hover $sideNavItemText': {
        color: theme.palette.primary.main,
      },
      '&[class*=Mui-selected]': {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        '& span': {
          fontWeight: theme.typography.fontWeightBold,
          color: theme.palette.primary.main,
        },
      },
    },
    sideNavItemIcon: {
      color: 'inherit',
      display: 'flex',
      justifyContent: 'center',
    },
    sideNavAppBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    sideNavToolbar: {
      padding: theme.spacing(1, 2),

      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(0, 2),
      },
    },
    logoText: {
      fontFamily: fontJosefinSans,
      fontSize: theme.spacing(2),
      textTransform: 'uppercase',
      color: theme.palette.common.white,
      marginLeft: theme.spacing(2.5),
    },
  })
);
