import React, { useEffect } from 'react';
import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';

import interbankLogo from 'assets/images/home/interbank.png';
import bcpLogo from 'assets/images/home/bcp.png';
import bbvaLogo from 'assets/images/home/bbva.png';
import scotiabankLogo from 'assets/images/home/scotiabank.png';
import { headerHeight, headerHeightSm } from 'theme/mainTheme';

import { Accordion, Alliances, ExchangeScreen, InfoScreen } from './components';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bankFrame: {
      background: '#e6e7e8',
      color: '#8A8C91',
    },
    bankFrameList: {
      '& h6': {
        fontWeight: 'normal',

        [theme.breakpoints.down('sm')]: {
          fontSize: '1.125rem',
        },
      },
      '& img': {
        maxHeight: 30,
      },
      '& img:not(:last-child)': {
        marginRight: theme.spacing(3),
      },
    },
  })
);

function Home() {
  const classes = useStyles();
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));
  const { hash } = useLocation();

  useEffect(() => {
    if (!hash) {
      return;
    }
    const elementOffset =
      document.getElementById(hash.slice(1))?.offsetTop || 0;
    const header = isSmDown ? headerHeightSm : headerHeight;
    window.scroll({
      top: elementOffset - header,
      left: 0,
      behavior: 'smooth',
    });
    // This effect will be called only when `hash` is changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash]);

  return (
    <>
      <ExchangeScreen />
      <Box py={5} px={6} className={classes.bankFrame}>
        <Grid container justify='space-around' alignItems='center' spacing={2}>
          <Grid
            item
            container
            xs
            justify='center'
            alignItems='center'
            spacing={2}
            className={classes.bankFrameList}
          >
            <Grid item>
              <Typography variant='h6' color='inherit'>
                Operaciones inmediatas:
              </Typography>
            </Grid>
            <Box display='flex' justifyContent='center' clone>
              <Grid item>
                <img src={bcpLogo} alt='bcp' />
                <img src={interbankLogo} alt='interbank' />
              </Grid>
            </Box>
          </Grid>
          <Grid
            item
            container
            xs
            justify='center'
            alignItems='center'
            spacing={2}
            className={classes.bankFrameList}
          >
            <Grid item>
              <Typography variant='h6' color='inherit'>
                Operaciones interbancarias:
              </Typography>
            </Grid>
            <Box display='flex' justifyContent='center' clone>
              <Grid item>
                <img src={bbvaLogo} alt='bbva' />
                <img src={scotiabankLogo} alt='scotiabank' />
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <InfoScreen />
      <Accordion />
      <Alliances />
    </>
  );
}

export default Home;
