import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';

import { Currency } from 'common/money';
import { Calculator } from 'components';
import { useExchangeContext } from 'context/Exchange';

import DiscountCodeModal from '../DiscountCodeModal';
import useStyles from './styles';

type ExchangeStepProps = {
  onPressNext: () => void;
  children: React.ReactNode;
};

export default function ExchangeStep({
  onPressNext,
  children,
}: ExchangeStepProps) {
  const {
    buy,
    sell,
    sendValue,
    coupon,
    setSendValue,
    getReceiveAmount,
    addCoupon,
    clearExchange,
  } = useExchangeContext();
  const [open, setOpen] = useState(false);
  const [sendCurrency, setSendCurrency] = useState(sendValue.currency);
  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFlip = (newCurrency: Currency) => {
    setSendCurrency(newCurrency);
  };

  const handleProceedExchange = (amount: string, currency: Currency) => {
    setSendValue({ amount, currency });
    onPressNext();
  };

  const handleSubmitCoupon = async (newCoupon: string) => {
    return addCoupon(newCoupon);
  };

  useEffect(() => {
    clearExchange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box display='flex' alignItems='center' flexDirection='column'>
      <Typography
        variant='h5'
        color='secondary'
        display='inline'
        align='center'
      >
        Vas a cambiar de{' '}
        {sendCurrency === Currency.USD ? 'Dólares a ' : 'Soles a '}
        <Typography variant='inherit' component='span' color='primary'>
          {sendCurrency === Currency.USD ? 'soles:' : 'dólares:'}
        </Typography>
      </Typography>
      {children}
      <Typography
        variant='h6'
        display='inline'
        align='center'
        className={classes.subtitle}
      >
        El tipo de cambio hoy es:
      </Typography>
      <Calculator
        buy={buy}
        sell={sell}
        onExchange={handleProceedExchange}
        buttonText='Cambiar ahora'
        defaultSendAmount={sendValue.amount}
        defaultSendCurrency={sendValue.currency}
        defaultReceiveAmount={getReceiveAmount()}
        extraButtonText='Tengo un cupón de descuento'
        onClickExtra={handleClickOpen}
        onFlip={handleFlip}
        wide
      />
      {!!coupon && (
        <Typography variant='body1' className={classes.couponText}>
          Ha aplicado el cupón: <strong>{coupon}</strong>
        </Typography>
      )}
      <DiscountCodeModal
        open={open}
        onClose={handleClose}
        onSubmit={handleSubmitCoupon}
      />
    </Box>
  );
}
