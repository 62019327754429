import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginBottom: theme.spacing(2),
    },
    grid: {
      marginBottom: theme.spacing(2),
      maxWidth: theme.breakpoints.values.md,
    },
    accountCard: {
      border: '3px solid #E8E8E8',
      padding: theme.spacing(1),
      borderRadius: 7,
      boxShadow: theme.custom.inputShadow,
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
      },

      color: '#777777',
      '& > img': {
        height: 64,
        marginLeft: theme.spacing(0.5),
      },
      '& > p': {
        fontSize: '0.75rem',
        marginLeft: theme.spacing(1),
      },
    },
    addButton: {
      display: 'block',
      minHeight: 90,
      textTransform: 'none',
      textAlign: 'center',
      fontWeight: theme.typography.fontWeightRegular,
      height: '100%',
      fontSize: '0.875rem',

      '& svg': {
        display: 'block',
        margin: theme.spacing(0, 'auto'),
        marginBottom: theme.spacing(1),
        fontSize: '2.25rem',
      },
    },
    selectButton: {
      textTransform: 'none',
      fontWeight: 'normal',
    },
    radio: {
      alignSelf: 'flex-start',
      padding: 0,
    },
  })
);
