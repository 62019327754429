import React, { createContext, useContext, useEffect, useState } from 'react';

import { BackdropLoading, ErrorMessage } from 'components';
import ClientType from 'common/clientType';
import { api } from 'utils';

import { useSessionContext } from '../Session';

export interface Client {
  clientType: ClientType;
  name: string;
  fathersLastName: string;
  mothersLastName: string;
  businessName: string;
  dni: string;
  ruc: string;
  email: string;
  phone: string;
  occupation: number | null;
  occupationName: string;
}

const defaultContext = {
  client: null,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  updateClient: async (s: Partial<Client>) => {},
};

interface ProfileContextI {
  client: Client | null;
  updateClient: (c: Partial<Client>) => Promise<any>;
}

interface Props {
  children: React.ReactNode;
}

const ProfileContext = createContext<ProfileContextI>(defaultContext);

export function ProfileProvider({ children }: Props) {
  const [client, setClient] = useState<Client | null>(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { isAuthenticated } = useSessionContext();

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    const fetch = async () => {
      setLoading(true);
      setError(false);
      try {
        const response = await api.get('clients/client/');
        setClient(response.data);
      } catch (e) {
        console.error(e);
        setError(true);
      } finally {
        setLoading(false);
      }
    };
    fetch();
  }, [isAuthenticated]);

  const updateClient = async (newData: Partial<Client>) => {
    // NOTE: exceptions must be handled outside
    const response = await api.patch('clients/client/', newData);
    setClient(response.data);
    return response;
  };

  const context: ProfileContextI = {
    client,
    updateClient,
  };

  if (!loading && error) {
    return <ErrorMessage />;
  }

  return (
    <ProfileContext.Provider value={context}>
      {children}
      <BackdropLoading loading={loading} />
    </ProfileContext.Provider>
  );
}

export const useProfileContext = () => useContext(ProfileContext);
