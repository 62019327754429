import React from 'react';
import { Box, CircularProgress, Grid, Typography } from '@material-ui/core';
import { Img } from 'react-image';
import useAxios from 'axios-hooks';

import { sectionsIds } from 'components/Navbar';

import useStyles from './styles';

function Alliances() {
  const [{ data, error, loading }] = useAxios({
    url: 'configurations/agreements/',
    params: {
      pageSize: 20,
    },
  });
  const classes = useStyles();

  return (
    <Box
      py={10}
      px={2}
      className={classes.alliances}
      id={sectionsIds.alliances}
    >
      <Typography variant='h4' color='secondary' className={classes.title}>
        Nuestras{' '}
        <Typography variant='inherit' color='primary' component='span'>
          alianzas estratégicas
        </Typography>
      </Typography>
      <Grid
        container
        justify='center'
        spacing={4}
        className={classes.alliancesLogos}
      >
        {loading ? (
          <Grid>
            <CircularProgress />
          </Grid>
        ) : (
          !error &&
          data.results?.map(
            (v: { id: number; logoFile: string; companyName: string }) =>
              !!v.logoFile && (
                <Grid item key={v.id}>
                  <Img
                    src={v.logoFile}
                    alt={v.companyName}
                    loader={<CircularProgress />}
                  />
                </Grid>
              )
          )
        )}
      </Grid>
    </Box>
  );
}

export default Alliances;
