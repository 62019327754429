import React from 'react';

import { Container } from '@material-ui/core';

import ProfileHistoryTable from './components/ProfileHistoryTable';
import useStyles from './styles';

const ProfileHistory = () => {
  const classes = useStyles();

  return (
    <Container maxWidth='md' className={classes.root}>
      <ProfileHistoryTable />
    </Container>
  );
};

export default ProfileHistory;
