import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    stepper: {
      background: 'none',
    },
    boxContainer: {
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(1, 2),
      },
    },
  })
);
