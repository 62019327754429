import React from 'react';
import { Box, Container, Grid, Hidden, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Img } from 'react-image';
import Carousel from '@brainhubeu/react-carousel';

import peopleImg from 'assets/images/home/people.png';
import pcMoney from 'assets/images/home/pc-money.png';
import lock from 'assets/images/home/lock.png';

import creditCard from 'assets/images/home/creditCard.png';
import effectiveness from 'assets/images/home/effectiveness.png';
import businessPerson from 'assets/images/home/businessPerson.png';
import alliances from 'assets/images/home/alliances.png';
import yapePlin from 'assets/images/home/yapePlin.png';
import personRunning from 'assets/images/home/person-running.png';
import { sectionsIds } from 'components/Navbar';

import useStyles from './styles';

function InfoScreen() {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <div className={classes.infoScreen}>
      <Box className={classes.sharingFrame}>
        <Grid container alignItems='center'>
          <Grid
            item
            container
            xs
            spacing={2}
            alignItems='center'
            justify='center'
            className={classes.sharingFrameLeftSide}
          >
            <Grid item>
              <Typography variant='h5' className={classes.sharingFrameText}>
                Recomienda <span>y gana</span>
              </Typography>
              <Typography>
                Obtén cupones de descuento por cada vez que traigas un amigo a
                operar con Kambiazo.
              </Typography>
            </Grid>
          </Grid>
          <Hidden mdDown>
            <Grid item xs className={classes.sharingFrameRightSide}>
              <Img src={peopleImg} />
            </Grid>
          </Hidden>
        </Grid>
      </Box>
      <div className={classes.infoContainer} id={sectionsIds.weInfo}>
        <Container maxWidth='sm' disableGutters>
          <Typography
            variant='h4'
            color='secondary'
            gutterBottom
            className={classes.title}
          >
            Somos una plataforma digital de{' '}
            <Typography variant='inherit' component='span' color='primary'>
              cambio de divisas
            </Typography>
          </Typography>
          <Typography variant='h6' className={classes.subtitle}>
            Nuestro objetivo es brindarte el 100% de facilidades que necesitas
            para realizar tus operaciones de cambio de dólares, a un mejor tipo
            de cambio que los bancos, y priorizando tu seguridad, comodidad y
            tiempo.
          </Typography>
        </Container>
        <div className={classes.carousel}>
          <Carousel
            breakpoints={{
              [theme.breakpoints.values.lg]: {
                slidesPerPage: 4,
              },
              [theme.breakpoints.values.md]: {
                slidesPerPage: 3,
              },
              [theme.breakpoints.values.sm]: {
                slidesPerPage: 1,
              },
            }}
            draggable={false}
            slidesPerPage={4}
            autoPlay={6000}
            infinite
            arrows
          >
            <div className={classes.item}>
              <img src={pcMoney} alt='mejor tipo de cambio' />
              <Typography variant='h5' color='secondary'>
                El mejor tipo de <br />
                cambio{' '}
                <Typography variant='inherit' component='span' color='primary'>
                  online
                </Typography>
              </Typography>
              <Typography align='center'>
                Paga menos para comprar tus dólares. Recibe más al cambiarlos a
                soles. Siempre te ofreceremos un mejor tipo de cambio que el
                banco.
              </Typography>
            </div>
            <div className={classes.item}>
              <img src={lock} alt='operaciones seguras' />
              <Typography variant='h5' color='secondary'>
                Operaciones <br /> 100%{' '}
                <Typography variant='inherit' component='span' color='primary'>
                  seguras
                </Typography>
              </Typography>
              <Typography align='center'>
                Realiza tus operaciones sin retirar efectivo ni exponerte a los
                peligros de llevar dinero por la calle.
              </Typography>
            </div>
            <div className={classes.item}>
              <img src={personRunning} alt='operaciones rápidas' />
              <Typography variant='h5' color='secondary'>
                Rapidez en
                <br /> tus{' '}
                <Typography variant='inherit' component='span' color='primary'>
                  operaciones
                </Typography>
              </Typography>
              <Typography align='center'>
                Tus operaciones serán liquidadas en un tiempo promedio de 8
                minutos.
              </Typography>
            </div>
            <div className={classes.item}>
              <img src={effectiveness} alt='efectividad y comodidad' />
              <Typography variant='h5' color='secondary'>
                Efectividad
                <br /> y{' '}
                <Typography variant='inherit' component='span' color='primary'>
                  comodidad
                </Typography>
              </Typography>
              <Typography align='center'>
                Opera desde donde estés, usando solo tu celular y conexión a
                internet.
              </Typography>
            </div>
            <div className={classes.item}>
              <img src={yapePlin} alt='yape + plin' />
              <Typography variant='h5' color='secondary'>
                Cobertura
                <br />
                <Typography variant='inherit' component='span' color='primary'>
                  Yape + Plin
                </Typography>
              </Typography>
              <Typography align='center'>
                Puedes usar Yape o Plin como medio de pago de tus operaciones
                pequeñas.
              </Typography>
            </div>
            <div className={classes.item}>
              <img src={creditCard} alt='pago de tarjetas de crédito' />
              <Typography variant='h5' color='secondary'>
                Pago de tarjetas <br /> de{' '}
                <Typography variant='inherit' component='span' color='primary'>
                  crédito
                </Typography>
              </Typography>
              <Typography align='center'>
                Envía el monto en soles y Kambiazo paga tu deuda en dólares.
              </Typography>
            </div>
            <div className={classes.item}>
              <img src={businessPerson} alt='empresas y personas' />
              <Typography variant='h5' color='secondary'>
                Empresas
                <br /> y{' '}
                <Typography variant='inherit' component='span' color='primary'>
                  personas
                </Typography>
              </Typography>
              <Typography align='center'>
                Te proporcionamos una plataforma para que puedas llevar el
                control de tus operaciones.
              </Typography>
            </div>
            <div className={classes.item}>
              <img src={alliances} alt='alianzas estratégicas' />
              <Typography variant='h5' color='secondary'>
                Alianzas
                <br />
                <Typography variant='inherit' component='span' color='primary'>
                  estratégicas
                </Typography>
              </Typography>
              <Typography align='center'>
                Accede a tipos de cambio exclusivos siendo cliente de nuestros
                socios estratégicos.
              </Typography>
            </div>
          </Carousel>
        </div>
      </div>
    </div>
  );
}

export default InfoScreen;
