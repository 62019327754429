import React from 'react';

import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function DiscountIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox='0 0 22.1 21.3'>
      <g>
        <path
          d='M11.1,21C10.3,20.8,9.7,20.3,9,20c-1.1-0.6-2.2-1.3-3.3-1.9C5.6,18,5.5,18,5.4,18c-0.2,0-0.2-0.1-0.2-0.2
          c0,0,0-0.1,0-0.1c0-1.7,0-3.4,0-5.1c0-0.3,0-0.3,0.3-0.3c1,0,2,0,3,0c0.4,0,0.7,0.1,1,0.3c0.8,0.5,1.7,1,2.5,1.5
          c0.3,0.2,0.6,0.5,0.7,0.9c0.2,0.6,0,1.1-0.5,1.5c-0.4,0.3-1,0.3-1.5,0c-0.7-0.4-1.3-0.8-2-1.3c-0.1-0.1-0.2-0.2-0.4-0.2
          c-0.3-0.1-0.6,0.1-0.7,0.3c-0.1,0.3,0,0.6,0.3,0.7c0.7,0.5,1.4,0.9,2.2,1.4c0.3,0.2,0.7,0.3,1.1,0.4c0.7,0.1,1.2-0.1,1.8-0.5
          c0.7-0.6,1.1-1.3,1-2.2c0-0.2,0.1-0.3,0.2-0.4c0.8-0.4,1.7-0.9,2.5-1.3c1.5-0.7,3.4-0.3,4.4,1.1c0.1,0.1,0.1,0.2,0.2,0.3
          c0,0.1,0,0.2,0,0.3c-0.1,0.1-0.1,0.2-0.2,0.2c-2.7,1.7-5.3,3.4-8,5c-0.5,0.3-0.9,0.6-1.4,0.8C11.4,21,11.3,21,11.1,21z'
        />
        <path
          d='M18.4,0.2c0.7,0.1,1.2,0.5,1.6,1.1c0.4,0.7,0.3,1.4-0.1,2c-0.1,0.1-0.1,0.2-0.3,0.1
          c-0.2-0.2-0.5-0.3-0.8-0.4c-0.1,0-0.2-0.1-0.1-0.2c0.2-0.4,0.2-0.8-0.1-1.1c-0.4-0.3-0.9-0.3-1.2,0.1c-0.2,0.2-0.3,0.5-0.4,0.8
          c0,0.2-0.1,0.2-0.3,0.2c-0.3,0-0.6,0-1,0c-0.2,0-0.2-0.1-0.3-0.2c-0.1-0.3-0.2-0.5-0.3-0.8c-0.2-0.4-0.7-0.5-1.1-0.3
          c-0.4,0.2-0.5,0.6-0.4,1c0.1,0.2,0.1,0.2-0.2,0.2c-0.3,0-0.6,0-0.9,0c-0.2,0-0.2-0.1-0.3-0.2c-0.2-1.1,0.6-2.2,1.7-2.4
          c0,0,0.1,0,0.1,0c0.2,0,0.4,0,0.6,0c0.6,0.1,1.1,0.4,1.4,0.9c0.1,0.1,0.1,0.1,0.1,0c0.3-0.5,0.8-0.8,1.4-0.9
          C18.1,0.2,18.2,0.2,18.4,0.2z'
        />
        <path
          d='M9.3,8.6c-2.1,0-4.1,0-6.2,0c-0.3,0-0.3,0-0.3-0.3c0-1,0-1.9,0-2.9c0-0.9,0.6-1.5,1.5-1.5
          c3.7,0,7.5,0,11.2,0c0.2,0,0.3,0.1,0.3,0.3c0,1.4,0,2.8,0,4.2c0,0.3,0,0.3-0.3,0.3C13.5,8.6,11.4,8.6,9.3,8.6z'
        />
        <path
          d='M0.3,15.2c0-1.4,0-2.8,0-4.1c0-0.5,0.2-0.7,0.7-0.7c0.8,0,1.5,0,2.3,0C3.8,10.3,4,10.6,4,11
          c0,2.8,0,5.5,0,8.3c0,0.5-0.2,0.7-0.8,0.8c-0.7,0-1.4,0-2.2,0c-0.5,0-0.7-0.2-0.7-0.8C0.3,17.9,0.3,16.5,0.3,15.2z'
        />
        <path
          d='M10.3,9.8c1.7,0,3.5,0,5.2,0c0.3,0,0.3,0,0.3,0.3c0,0.7,0,1.4,0,2.1c0,0.2,0,0.3-0.2,0.3
          c-0.7,0.3-1.3,0.7-2,1c-0.1,0.1-0.2,0.1-0.3-0.1c-0.3-0.4-0.8-0.6-1.2-0.9c-0.7-0.4-1.5-0.9-2.2-1.3C9.5,11.1,9,11,8.4,11
          c-1,0-2,0-3,0c-0.2,0-0.3-0.1-0.3-0.3c0-0.3-0.1-0.5-0.2-0.7C4.8,9.8,4.8,9.8,5.1,9.8C6.8,9.8,8.6,9.8,10.3,9.8z'
        />
        <path
          d='M17,6.3c0-0.7,0-1.4,0-2.1c0-0.3,0-0.3,0.3-0.3c0.3,0,0.7,0,1,0c0.7,0.1,1.2,0.7,1.2,1.3c0,1,0,2.1,0,3.1
          c0,0.1-0.1,0.2-0.2,0.2c-0.7,0-1.4,0-2.1,0C17,8.7,17,8.6,17,8.4C17,7.7,17,7,17,6.3z'
        />
        <path
          d='M18.2,9.8c0.3,0,0.7,0,1,0c0.2,0,0.2,0.1,0.2,0.2c0,0.6,0,1.2,0,1.8c0,0.2-0.1,0.2-0.2,0.2
          c-0.7-0.2-1.4-0.2-2.1,0c-0.2,0-0.2,0-0.2-0.2c0-0.6,0-1.2,0-1.8c0-0.1,0.1-0.2,0.2-0.2C17.5,9.8,17.9,9.8,18.2,9.8z'
        />
      </g>
    </SvgIcon>
  );
}
