import React, { useEffect, useState } from 'react';
import { Button, Grid, Link as MuiLink, Typography } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import { Field, Form, Formik } from 'formik';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { api, Yup } from 'utils';
import { parseError } from 'utils/api';
import { useSessionContext } from 'context/Session';
import { commonError } from 'common/errorMessages';
import { recoverPasswordRoute, registerRoute } from 'routes';

import { PasswordAdornment } from '../../../Register/components';
import useStyles from './styles';

const initialValues = {
  email: '',
  password: '',
};

const validationSchema = Yup.object({
  email: Yup.string().required().email().max(254),
  password: Yup.string().required().max(128),
});

export default function LoginForm() {
  const [showPassword, setShowPassword] = useState(false);
  const { setToken } = useSessionContext();
  const history = useHistory();
  const { state } = useLocation<{ message: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  useEffect(() => {
    if (state?.message) {
      enqueueSnackbar(state.message, {
        variant: 'info',
        autoHideDuration: 10000,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enqueueSnackbar, state?.message]);

  const handleClickShowPassword = () => {
    setShowPassword((v) => !v);
  };

  return (
    <>
      <Grid item xs={10} sm={6} lg={3}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (
            { email, password }: typeof initialValues,
            { setSubmitting, setErrors }
          ) => {
            try {
              const response = await api.post('clients/login/', {
                username: email,
                password,
              });
              setToken(response.data.token);
              setSubmitting(false);
              history.push('/select-type', state);
            } catch (e) {
              if (e?.response?.data) {
                setErrors(parseError(e.response.data, 'email'));
              } else {
                enqueueSnackbar(commonError, { variant: 'error' });
              }
              setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <Field
                component={TextField}
                name='email'
                type='email'
                autoComplete='email'
                placeholder='Ingresa tu e-mail'
                variant='outlined'
                size='small'
                margin='normal'
                fullWidth
                className={classes.loginInput}
              />
              <Field
                component={TextField}
                name='password'
                type={showPassword ? 'text' : 'password'}
                autoComplete='current-password'
                placeholder='Ingresa tu contraseña'
                variant='outlined'
                size='small'
                margin='normal'
                fullWidth
                className={classes.loginInput}
                InputProps={{
                  endAdornment: (
                    <PasswordAdornment
                      onClick={handleClickShowPassword}
                      show={showPassword}
                    />
                  ),
                }}
              />
              <Button
                type='submit'
                variant='contained'
                color='primary'
                className={classes.loginSubmitBtn}
                disabled={isSubmitting}
              >
                INICIAR SESIÓN
              </Button>
            </Form>
          )}
        </Formik>

        <Typography color='primary' gutterBottom align='center'>
          <MuiLink
            component={Link}
            to={recoverPasswordRoute}
            underline='always'
          >
            ¿Olvidaste tu contraseña?
          </MuiLink>
        </Typography>

        <Typography color='primary' align='center'>
          Si aún no tienes cuenta, regístrate{' '}
          <MuiLink component={Link} to={registerRoute} underline='always'>
            aquí
          </MuiLink>
          .
        </Typography>
      </Grid>
    </>
  );
}
