import * as Yup from 'yup';

Yup.setLocale({
  mixed: {
    required: 'Este campo es requerido',
  },
  string: {
    email: 'Este campo debe contener un correo válido',
    // eslint-disable-next-line no-template-curly-in-string
    max: 'Este campo puede tener como máximo ${max} caracteres',
  },
});

export default Yup;
