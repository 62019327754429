import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      [theme.breakpoints.up('md')]: {
        maxWidth: 1100,
      },
    },
    historyWrapper: {
      paddingTop: theme.spacing(7),
      paddingBottom: theme.spacing(7),
      borderRadius: '16px',
    },
    selectorText: {
      fontWeight: 'normal',
      color: '#595959',
      lineHeight: '1.2em',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.125rem',
      },
    },
    inputBackground: {
      background: theme.palette.background.paper,
      boxShadow: theme.custom.inputShadow,
      margin: 8,
    },
    selector: {
      background: theme.palette.background.paper,
      boxShadow: theme.custom.inputShadow,
      margin: theme.spacing(1),
      minWidth: 200,
      maxWidth: 250,
      '& legend': {
        width: 0,
      },
    },
    shrink: {},
    selectLabel: {
      '&$shrink': {
        display: 'none',
      },
    },
    selectorContainerSearchCode: {
      height: '100%',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-end',
      [theme.breakpoints.down('sm')]: {
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
    selectorContainerState: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      [theme.breakpoints.down('sm')]: {
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
    tableWrapper: {
      maxWidth: '100vw',
    },
    tableContainer: {
      position: 'relative',
      marginTop: theme.spacing(4),
      border: 'none',
    },
    reportButton: {
      width: 120,
      fontSize: 9,
      padding: 5,
    },
    trashIcon: {
      color: '#8c1515',
    },
    iconSize: {
      fontSize: 21,
    },
    infoIcon: {
      marginRight: 10,
      color: theme.palette.primary.main,
      fontSize: 26,
    },
    changesTitle: {
      display: 'flex',
      alignItems: 'center',
    },
    contentDialogChanges: {
      color: '#4e4f52',
    },
    dialogActionsChanges: {
      justifyContent: 'center',
      paddingBottom: 20,
    },
    closeButton: {
      textTransform: 'none',
      background: theme.palette.error.main,
      color: theme.palette.background.paper,
      '&:hover': {
        backgroundColor: theme.palette.error.dark,
        borderColor: theme.palette.error.dark,
        boxShadow: 'none',
      },
    },
    deleteButton: {
      textTransform: 'none',
    },
    deleteLoadingContainer: {
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      background: 'rgba(0,0,0, 0.2)',
      top: 0,
      left: 0,
    },
    questionText: {
      padding: 10,
    },
    paginationContainer: {
      margin: theme.spacing(2, 0, 5),
    },
    selectedPaginationLabel: {
      color: `${theme.palette.background.paper} !important`,
    },
    loadingTableContainer: {
      position: 'absolute',
      height: 'calc(100% - 70px)',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: 'rgba(0,0,0, 0.2)',
      minHeight: 150,
      zIndex: 2,
    },
    emptyCell: {
      padding: 20,
      paddingLeft: 0,
    },
    transactionTable: {
      position: 'relative',
    },
    bankAccountNumber: {
      letterSpacing: 1.1,
    },
    reportOperationWrapper: {
      display: 'flex',
      minWidth: theme.breakpoints.values.sm + 50,
      height: 450,
      alignItems: 'center',

      [theme.breakpoints.down('sm')]: {
        paddingTop: theme.spacing(5),
        flexDirection: 'column',
        boxShadow: 'none',
        minWidth: 'auto',
        height: 'auto',
      },
    },
    reportOperationSideImg: {
      flex: 1,
      height: '100%',
    },
    reportOperationDetails: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      padding: 10,
    },
    titleOperationModal: {
      fontWeight: 'bold',
    },
    reportOperationInput: {
      marginBottom: theme.spacing(3),
    },
    titleCell: {
      color: theme.palette.secondary.main,
      fontWeight: 'bold',
      border: 'none',
      padding: '2px 0',
    },
    contentCell: {
      border: 'none',
      padding: '0 5px',
    },
    reportButtonContainer: {
      display: 'flex',
      justifyContent: 'center',
      margin: '15px 0',
    },
    minLoadingContainer: {
      minHeight: 300,
    },
    historyPagination: {
      '& .Mui-selected': {
        color: theme.palette.common.white,
      },
    },
    overflowVisible: {
      overflow: 'visible',
    },
    deleteModalTitle: {
      '& span': {
        color: theme.palette.primary.main,
      },
      marginBottom: theme.spacing(2),
    },
  })
);
