import React, { memo, KeyboardEvent, ChangeEvent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import { Box, Grid, Typography, InputAdornment } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

import useStyles from '../styles';
import Status from '../enums';

interface Props {
  searchCode: string;
  handleChangeSearchCode: (event: ChangeEvent<{ value: unknown }>) => void;
  selectState: string;
  handleChangeSelectState: (event: ChangeEvent<{ value: unknown }>) => void;
  handleSearch: (event: KeyboardEvent<HTMLDivElement>) => void;
}

const ProfileHistoryHeader = memo(
  ({
    searchCode,
    handleChangeSearchCode,
    selectState,
    handleChangeSelectState,
    handleSearch,
  }: Props) => {
    const classes = useStyles();

    return (
      <Grid container justify='center' spacing={4}>
        <Grid item xs={12} md={12}>
          <Box pt={5}>
            <Typography variant='h5' color='secondary' align='center'>
              Revisa aquí el historial{' '}
              <Typography variant='inherit' component='span' color='primary'>
                de tus ordenes
              </Typography>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container className={classes.selectorContainerSearchCode}>
            <Typography
              variant='h6'
              component='span'
              className={classes.selectorText}
            >
              Buscar por código:
            </Typography>
            <TextField
              className={classes.inputBackground}
              value={searchCode}
              onChange={handleChangeSearchCode}
              label=''
              variant='outlined'
              size='small'
              onKeyDown={handleSearch}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='start'>
                    <FontAwesomeIcon icon={faSearch} size='xs' />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container className={classes.selectorContainerState}>
            <Typography
              variant='h6'
              component='span'
              className={classes.selectorText}
            >
              Estado:
            </Typography>
            <TextField
              select
              label='-Seleccionar-'
              variant='outlined'
              size='small'
              margin='normal'
              value={selectState}
              onChange={handleChangeSelectState}
              className={classes.selector}
              InputLabelProps={{
                classes: {
                  root: classes.selectLabel,
                  shrink: classes.shrink,
                },
              }}
            >
              <MenuItem value={Status.Todos}>Todos</MenuItem>
              <MenuItem value={Status.Pedido}>Pedido</MenuItem>
              <MenuItem value={Status.Espera}>En espera</MenuItem>
              <MenuItem value={Status.Validado}>Validado</MenuItem>
              <MenuItem value={Status.Terminado}>Terminado</MenuItem>
            </TextField>
          </Grid>
        </Grid>
      </Grid>
    );
  }
);

export default ProfileHistoryHeader;
