import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    textContainer: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 'max-content',

      '& > h5': {
        minWidth: 'max-content',
        marginBottom: theme.spacing(2),
      },
      '& > h6': {
        color: '#606060',
        lineHeight: 1.25,
        fontWeight: theme.typography.fontWeightRegular,
        fontStyle: 'italic',
        marginBottom: theme.spacing(2),
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(2),
      },
    },
    accountNumber: {
      display: 'block',
      fontStyle: 'normal',
      fontSize: '1.75rem',
      fontWeight: theme.typography.fontWeightBold,
    },
    accountDescription: {
      display: 'block',
      fontStyle: 'normal',
    },
    dataContainer: {
      margin: theme.spacing(5, 0),
      maxWidth: '100%',
      [theme.breakpoints.up('md')]: {
        '& > div:first-child': {
          borderRight: '3px solid #7F7F7F',
        },
      },
    },
    dataItem: {
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    selectInput: {
      '& [class*="MuiInputBase-root"]': {
        backgroundColor: theme.palette.common.white,
      },
    },
    button: {
      textTransform: 'none',
    },
    buttonLabel: {
      fontSize: '1.125rem',
      fontWeight: theme.typography.fontWeightRegular,
      lineHeight: 1.45,
      whiteSpace: 'nowrap',
    },
    buttonIconPrimary: {
      color: theme.palette.primary.main,
    },
    stepButton: {
      textTransform: 'none',
      fontWeight: 'normal',
    },
    buttonIconSecondary: {
      color: theme.palette.secondary.main,
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: theme.palette.common.white,
    },
    divider: {
      height: 2,
    },
  })
);
