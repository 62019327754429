import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { headerHeight, headerHeightSm } from 'theme/mainTheme';
import slideBackground from 'assets/images/home/slide-background.png';

export default makeStyles((theme: Theme) =>
  createStyles({
    exchangeScreen: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: `calc(100vh - ${headerHeight}px)`,
      marginTop: headerHeight,
      backgroundImage: `url(${slideBackground})`,
      backgroundSize: 'cover',
      backgroundPosition: 'bottom center',
      padding: theme.spacing(4, 2),

      [theme.breakpoints.down('sm')]: {
        height: 'auto',
        marginTop: headerHeightSm,
        minHeight: `calc(100vh - ${headerHeightSm}px)`,
        background: theme.palette.common.white,
      },

      '& > img': {
        marginTop: 'auto',
        marginBottom: -theme.spacing(4),
        maxHeight: '85%',
        maxWidth: '35%',

        [theme.breakpoints.down('md')]: {
          display: 'none',
        },
      },
    },
    textAndCalculator: {
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        justifyContent: 'center',
      },
      [theme.breakpoints.down('sm')]: {
        flexFlow: 'column nowrap',
      },
    },
    text: {
      maxWidth: 520,
      marginLeft: theme.spacing(4),
      [theme.breakpoints.up('xl')]: {
        marginLeft: theme.spacing(8),
      },
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
        marginBottom: theme.spacing(4),
        textAlign: 'center',
      },
      [theme.breakpoints.down('xs')]: {
        marginBottom: theme.spacing(2),
      },
    },
    calculator: {
      marginLeft: 32,

      [theme.breakpoints.up('lg')]: {
        marginLeft: theme.spacing(8),
      },

      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
      },
    },
    title: {
      fontSize: '2.5rem',
      [theme.breakpoints.down('xs')]: {
        fontSize: '2.0rem',
      },
    },
    subtitle: {
      fontWeight: 'normal',
      color: '#595959',
      lineHeight: '1.2em',

      [theme.breakpoints.down('xs')]: {
        fontSize: '1.0rem',
      },
    },
  })
);
