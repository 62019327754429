import React from 'react';
import { Box, Container, Typography } from '@material-ui/core';

import useStyles from './styles';
import BaseModal from '../BaseModal';

interface Props {
  open: boolean;
  onClose: () => void;
}

function TermsAndConditionsModal({ open, onClose }: Props) {
  const classes = useStyles();

  return (
    <BaseModal open={open} onClose={onClose}>
      <Box p={7} className={classes.box}>
        <Container maxWidth='sm' disableGutters className={classes.container}>
          <Typography variant='h5' align='center'>
            TÉRMINOS Y CONDICIONES
          </Typography>
          <Typography variant='h6' align='center' gutterBottom>
            KAMBIAZO S.A.C.
          </Typography>
          <h3>1. GENERALIDADES</h3>
          <p>
            Mediante el presente Términos y Condiciones representan un contrato
            (en adelante, el “Contrato”) entre usted (en adelante, “EL CLIENTE”)
            y KAMBIAZO S.A.C (en adelante, “KAMBIAZO”). El presente Contrato
            detalla los servicios que presta KAMBIAZO y las condiciones que debe
            aceptar EL CLIENTE para acceder a los mismos.
          </p>
          <h3>2. EMPRESA</h3>
          <p>
            KAMBIAZO SAC es una empresa constituida en el Perú, inscrita en la
            Superintendencia Nacional de Registros Públicos SUNARP mediante la
            partida electrónica N° 14508572 y debidamente registrada en la
            Superintendencia Nacional de Administración Tributaria SUNAT con
            Registro Único de Contribuyente (RUC) Nº 20606406046.
          </p>
          <h3>3. SERVICIO</h3>
          <p>
            KAMBIAZO brinda sus servicios a través de plataformas digitales y
            cuenta con una web en internet con el nombre “kambiazo.pe” las
            cuales, en adelante, denominaremos en su conjunto como “LAS
            PLATAFORMAS”. KAMBIAZO brinda sus servicios a todo el Perú a través
            de LAS PLATAFORMAS.
          </p>
          <p>
            Tener en cuenta que los datos e información incluidos en LAS
            PLATAFORMAS brindan elementos para la toma de decisión financiera,
            por lo cual no debe tomarse como una asesoría o sugerencia por parte
            de KAMBIAZO para la compra o venta de dólares o cualquier
            transacción o negocio.
          </p>
          <h3>4. CONTRATO</h3>
          <p>
            Este Contrato tiene como objeto que EL CLIENTE se informe de la
            Política de Privacidad y los Términos y Condiciones de KAMBIAZO. En
            base a ello, le pedimos tome conocimiento del contrato que a
            continuación se detalla y asegúrese de entenderlo antes de utilizar
            nuestros servicios.
          </p>
          <p>
            El simple uso y acceso a LAS PLATAFORMAS o uso de sus servicios
            digitales que KAMBIAZO pone a su disposición, se entenderá como la
            aceptación de estos y por ende su compromiso para respetar las
            condiciones de uso y la observancia de la normativa que rige a las
            casas de cambio conforme a la regulación del Perú. Le recomendamos a
            su vez, que guarde una copia de este contrato.
          </p>
          <p>
            En caso usted no esté de acuerdo no podrá registrarse ni utilizar
            los servicios de la plataforma
          </p>
          <h3>5. TARIFAS Y COMISIONES</h3>
          <p>
            KAMBIAZO no realiza ningún cobro de comisión por el uso de la
            plataforma.
          </p>
          <p>
            Podrían aplicar comisiones dependiendo del tipo de servicio y método
            de pago seleccionado. Sin embargo, EL CLIENTE debe considerar que
            existen cobros de comisiones o impuesto a las transacciones
            financieras (ITF) efectuados por las entidades financieras.
            Adicionalmente, en caso de que EL CLIENTE utilice cuentas bancarias
            de distintas plazas que no sean de Lima, KAMBIAZO trasladará al
            cliente las comisiones cargadas por las entidades financieras.
          </p>
          <h3>6. PROPIEDAD INTELECTUAL</h3>
          <p>
            KAMBIAZO, está protegido por los derechos de propiedad de autor.
            Todos los derechos involucrados como por ejemplo el contenido, el
            diseño visual, logos y eslogan que forman parte del contenido y
            servicios, pertenecen a KAMBIAZO. En base a ello, se prohíbe
            utilizar, codificar, copiar, distribuir, transmitir o comercializar
            los derechos involucrados sin el permiso expreso por escrito.
          </p>
          <p>
            EL CLIENTE no adquiere ningún derecho de propiedad intelectual por
            el uso de los Servicios y Contenidos de LAS PLATAFORMAS, no siendo
            considerado una autorización ni licencia para utilizar los servicios
            y contenidos con fines distintos a los mencionados en el presente
            contrato.
          </p>
          <h3>7. HORARIO DE ATENCIÓN</h3>
          <p>
            El horario de atención de KAMBIAZO es de lunes aviernes de 9:00 am a
            8:00 pm, siempre y cuando sean días hábiles, EL CLIENTE podrá
            realizar operaciones y transferencias según el horario establecido
            por la empresa.
          </p>
          <p>
            En caso EL CLIENTE utilice LOS SERVICIOS fuera del horario de
            atención, la operación podrá ser anulada o aceptada bajo las
            condiciones que KAMBIAZO considere pertinentes y se atenderá al día
            siguiente hábil.
          </p>
          <h3>8. REGISTRO DE USUARIO</h3>
          <p>
            EL CLIENTE de la plataforma www.kambiazo.pe se distinguen en persona
            natural y persona jurídica, a continuación, se detalla las
            especificaciones:
          </p>
          <p>
            Persona Natural: EL CLIENTE es una persona natural que debe contar
            al menos con dieciocho (18) años cumplidos, deberá tener algún
            documento de identidad para el caso de peruanos, pasaporte o carnet
            de extranjería para extranjeros.
          </p>
          <p>
            Persona Jurídica: EL CLIENTE deberá ser constituido como una persona
            jurídica, la información ingresada en el sistema tendrá las veces de
            declaración jurada dado que los datos ingresados deberán ser
            verdaderos y vigentes.
          </p>
          <p>
            Cuando EL CLIENTE se registra deberá ingresar un correo electrónico
            y una clave según criterio y seguridad, a la cual solo él tiene
            acceso. EL CLIENTE tiene la exclusiva responsabilidad de ella, y no
            deberá compartirla para proteger su privacidad y seguridad. KAMBIAZO
            almacena las contraseñas encriptadas.
          </p>
          <p>
            Al registrarse como usuario deberá registrar sus datos personales y
            de contacto lo cual incluye adjuntar el documento de identidad de
            manera frontal de forma digital, a consecuencia de esto EL CLIENTE
            nos brinda su consentimiento para hacer uso de sus datos personales,
            para la verificación de identidad y operaciones de tipo de cambio
            cerradas.
          </p>
          <p>
            Cuando EL CLIENTE se registra, el personal de KAMBIAZO validará la
            información registrada por EL CLIENTE validando su perfil, una vez
            validado se enviará un correo de bienvenida, con el perfil de EL
            CLIENTE validado podrá crear los espacios de trabajo de PERSONAL y/o
            EMPRESA.
          </p>
          <p>
            Cuando elija la opción La EMPRESA del espacio de trabajo deberá
            completar la información asociada a la empresa que está registrando,
            la información que la persona registra en el espacio de trabajo se
            considerara verídica y de manera confidencial.
          </p>
          <ul>
            <li>REGISTRO DE CUENTA DE BANCO</li>
          </ul>
          <p>
            EL CLIENTE deberá registrar datos de la cuenta bancaria, tanto en
            soles como en dólares americanos.
          </p>
          <p>
            La Cuenta de Cargo desde donde EL CLIENTE hará la transferencia,
            siempre debe pertenecer al usuario/perfil que está registrando la
            operación. KAMBIAZO se reserva el derecho rechazar la operación en
            caso detecte que el propietario de la Cuenta de Cargo es distinto al
            usuario/perfil que registró la operación.
          </p>
          <p>
            En KAMBIAZO aceptamos cuentas de las siguientes entidades
            financieras:
          </p>
          <ul>
            <li>BCP con cuentas generadas a nivel nacional.</li>
            <li>Interbank solo en Lima</li>
            <li>
              Otros Bancos, con cuentas generadas en el departamento de Lima –
              Perú. Para este tipo de transferencias se solicitará el código de
              cuenta interbancaria y será EL CLIENTE quien asuma los costos de
              la comisión interbancaria de envío de fondos desde la Cuenta de
              Cargo a KAMBIAZO, en caso de existir, cobrada por su entidad
              financiera. KAMBIAZO asumirá el costo de la transferencia regular
              desde su cuenta operativa hasta la Cuenta de Destino cuando esta
              pertenezca a bancos en los que KAMBIAZO no tiene cuentas
              generadas, siempre y cuando, se trate de una cuenta generada en
              Lima. Para cuentas generadas fuera de Lima, será EL CLIENTE quien
              asuma las comisiones cargadas por la entidad financiera.
            </li>
            <li>
              Durante la operación, EL CLIENTE deberá verificar la Cuenta de
              Destino (Banco, Moneda, Tipo de Cuenta, Beneficiario), puesto que
              KAMBIAZO no se hace responsable en caso de que los datos se
              encuentren mal registrados y existan tiempos de rebote de la
              operación o comisiones adicionales cobradas por las entidades
              financieras.
            </li>
          </ul>
          <p>KAMBIAZO no será responsable por:</p>
          <ul>
            <li>
              El bloqueo de cuentas bancarias por parte de la entidad financiera
              de EL CLIENTE.
            </li>
            <li>
              Las comisiones cobradas por las entidades financieras por concepto
              de transferencia inmediata o transferencia regular desde la cuenta
              de EL CLIENTE a las cuentas de KAMBIAZO.
            </li>
            <li>
              Los tiempos de transferencia de dinero en que incurra la entidad
              financiera de la Cuenta de Cargo de EL CLIENTE para trasladar los
              fondos a las cuentas de KAMBIAZO; sea esto para el caso de una
              transferencia a cuentas del mismo banco o interbancaria.
            </li>
            <li>
              Gastos, comisiones o tiempos incurridos debidos a un error
              generado por EL CLIENTE, cuando éste haya transferido a una cuenta
              distinta a la especificada por KAMBIAZO en el registro de la
              operación.
            </li>
            <li>
              En caso de que EL CLIENTE no tenga cuentas bancarias en Lima y
              opere con una cuenta distinta a la del banco BCP y Interbank,
              KAMBIAZO deducirá del monto a enviar, los costos bancarios
              asociados al envío del dinero.
            </li>
          </ul>
          <p>
            KAMBIAZO podrá solicitar el registro de información adicional, a fin
            de cumplir con la normativa que rige la Ley de Prevención de Lavado
            de Activos y del Financiamiento del Terrorismo (PLAFT).
          </p>
          <ul>
            <li>REGISTRO DE TARJETA DE CRÉDITO</li>
          </ul>
          <p>
            KAMBIAZO no se hace responsable por las comisiones o intereses que
            le genere la decisión propia de EL CLIENTE por hacer uso de la
            opción de disposición de efectivo de su tarjeta de crédito para
            hacer uso de LOS SERVICIOS ofrecidos por KAMBIAZO.
          </p>
          <p>
            EL CLIENTE podrá registrar su Tarjeta de crédito como Cuenta de
            Destino de Fondos o como cuenta de cargo de fondos.
          </p>
          <p>
            Las tarjetas de crédito podrán ser Visa y/o Mastercard, emitidas por
            el banco BCP, son las únicas aceptadas para poder hacer uso del
            servicio de cambio de divisas para pago de tarjeta de crédito.
          </p>
          <p>
            EL CLIENTE registrará datos básicos de la tarjeta de crédito donde
            desea recibir o cargar el fondo producto de la operación de cambio
            generada.
          </p>
          <p>
            La tarjeta de crédito que desea pagar podrá corresponder a un
            tercero beneficiario, pero tendrá que declararlo y registrar los
            nombres y datos solicitados del tercero beneficiario.
          </p>
          <h3>9. VERIFICACIÓN</h3>
          <p>
            KAMBIAZO realizará acciones para verificar la autenticidad del
            usuario creado, se realizará cruces de información con entidades del
            Estado y/o privadas con el objeto de verificar que la información
            registrada en la cuenta sea real.
          </p>
          <p>
            KAMBIAZO es una empresa que cumple con la normativa vigente de PLAFT
            otorgada por la Unidad de Inteligencia Financiera (UIF-SBS) y por lo
            tanto, KAMBIAZO realizará la verificación de los datos personales y,
            de ser necesario, establecerá contacto con EL CLIENTE en cualquier
            momento que lo considere pertinente.
          </p>
          <p>
            KAMBIAZO podrá solicitarle información adicional necesaria para
            verificar la identidad de EL CLIENTE.
          </p>
          <p>
            Se reservará el derecho de rechazar cualquier nuevo usuario o
            transferencia a discreción ante la posibilidad de operaciones
            fraudulentas y/o delictivas.
          </p>
          <h3>10. CUENTAS DUPLICADAS</h3>
          <p>
            KAMBIAZO realizará continuamente revisión de las cuentas, en caso
            detecte cuentas duplicadas se procederá a comunicar al cliente y
            tomar las medidas pertinentes, en caso no llegue a tener una
            comunicación con EL CLIENTE se procederá a cerrar la cuenta sin
            previo aviso al titular de la cuenta.
          </p>
          <h3>11. REGISTRO DE OPERACIONES</h3>
          <p>
            Para registrar una operación EL CLIENTE debe iniciar sesión con su
            usuario ya validado por KAMBIAZO.
          </p>
          <p>
            Los pasos que deberá seguir EL CLIENTE para concretar una operación
            de cambio son:
          </p>
          <ul>
            <li>
              EL CLIENTE deberá ingresar a la opción Nueva Operación y
              seleccionar una de las dos opciones, “quiero vender” (equivalente
              a compra) o “quiero comprar” (equivalente a venta).
            </li>
            <li>
              EL CLIENTE deberá ingresar el monto en dólares que desea cambiar,
              seleccionado las cuentas en dólares o soles a donde los fondos
              estarán abonando KAMBIAZO. En caso KAMBIAZO no tenga cuenta en el
              banco donde se abonará los fondos, lo hará mediante transferencia
              interbancaria.
            </li>
            <li>
              EL CLIENTE debe seleccionar el método de pago y deberá realizar la
              transferencia del monto origen desde su cuenta bancaria
              previamente registrada, hacia una cuenta de KAMBIAZO en un tiempo
              máximo de quince (15) minutos, contado a partir del momento del
              registro de la operación para poder mantener el acuerdo de cambio
              de dólares.
            </li>
            <li>
              EL CLIENTE deberá realizar la transferencia desde una cuenta
              personal o de la empresa que representa no se aceptará depósitos
              en efectivo, ni cheques, por lo que al detectarse alguno de estos
              casos la operación quedará anulada y se procederá a devolver los
              fondos transferidos a EL CLIENTE descontando algún costo asociado
              a la transferencia.
            </li>
            <li>
              Es responsabilidad de EL CLIENTE enviar los fondos en el tiempo
              adecuado para asegurar el procesamiento de operación de cambio
              cerrada. KAMBIAZO no tendrá responsabilidad de los tiempos de
              liquidación de las operaciones con las entidades bancarios y
              proveedores de precios.
            </li>
          </ul>
          <p>
            Cabe resaltar que KAMBIAZO no se hará responsable del tiempo de
            transferencia de fondos por parte de las entidades financieras.
          </p>
          <p>
            En caso EL CLIENTE realice una transferencia regular, deberá asumir
            los tiempos de transferencias de su entidad bancaria. Si KAMBIAZO
            recibiera los fondos después de las 20:00 horas del día de
            registrada la operación, se liquidará al día siguiente teniendo en
            cuenta los precios en el tipo de cambio en ese momento.
          </p>
          <p>
            Una vez que EL CLIENTE grabe la operación de cambio, KAMBIAZO
            procesara la operación en un tiempo máximo de 15 minutos siempre y
            cuando los fondos se encuentren en la cuenta y el banco de destino
            sea el mismo donde KAMBIAZO mantenga la cuenta.
          </p>
          <h3>12. LIQUIDACIÓN Y TIEMPO DE ATENCIÓN DE LOS SERVICIOS</h3>
          <p>
            La operación se procesará a partir del momento en que se reciban los
            fondos. El tiempo para que KAMBIAZO ejecute la transferencia de los
            fondos a la Cuenta de Destino de EL CLIENTE para el servicio de
            compra y venta de dólares será de un aproximado de treinta (30)
            minutos, siempre y cuando no se hayan establecido tiempos diferentes
            entre las partes, existan problemas por lentitud de la banca en
            línea o se produzca una caída de dichos servicios de la entidad
            financiera de la Cuenta de Destino y/o no se trate de una
            transferencia interbancaria.
          </p>
          <p>
            Es importante destacar que los tiempos mencionados se encuentran
            sujetos a variaciones causadas por verificaciones adicionales de
            seguridad que puedan ser necesarias, a la magnitud del monto
            requerido por el usuario o disponibilidad de las plataformas
            bancarias utilizadas. Esto podría retrasar el procesamiento y
            ejecución de la operación.
          </p>
          <p>
            KAMBIAZO tiene cuentas en soles y dólares en el BCP e Interbank, las
            transferencias hacia otros bancos como BBVA, Scotiabank, entre otros
            serán consideradas transferencias interbancarias. Tanto EL CLIENTE
            como KAMBIAZO asumirán el costo de la comisión interbancaria que
            dependerá del banco donde se realice el abono.
          </p>
          <p>
            Las transferencias interbancarias pueden demorar hasta tomar
            aproximadamente hasta cuarenta y ocho (48) horas hábiles (excluyendo
            sábados, domingos y feriados), el tiempo de la transferencia depende
            del banco emisor y de la CCE (Cámara de Compensación Electrónica).
            KAMBIAZO recibirá los fondos en sus cuentas a través de
            transferencias bancarias y, por esta misma vía, devolverá los fondos
            a los clientes, asimismo asumirá los costos de transacción
            incurridos al enviar los fondos al cliente. Los costos de envió de
            fondos hacia KAMBIAZO serán asumidos por EL CLIENTE.
          </p>
          <p>
            KAMBIAZO solo liquidará operaciones de cuentas de provincias con el
            Banco de Crédito del Perú (BCP), Interbank o por medio de nuestros
            socios estratégicos. En caso la operación sea de cuenta de provincia
            y no se realice la liquidación mediante nuestro socio estratégico se
            deducirá las comisiones del monto a transferir.
          </p>
          <p>
            En lo que respecta al servicio de operaciones de cambio para el pago
            de tarjeta de crédito, EL CLIENTE acepta y reconoce que KAMBIAZO no
            será responsable cuando el pago de la tarjeta de crédito se realice
            pasada la fecha de vencimiento establecida por el banco, que pueda
            generar la obligación de pago de penalidades y/o intereses por parte
            de EL CLIENTE. De esa manera, EL CLIENTE se compromete a tomar las
            previsiones del caso en lo que respecta a los plazos referenciales
            estipulados para la ejecución de la operación.
          </p>
          <p>
            El movimiento de ingreso para el servicio de operaciones de cambio
            para el pago de tarjeta de crédito recién podrá ser visible en la
            tarjeta de crédito de EL CLIENTE entre cuarenta y ocho (48) a
            setenta y dos (72) horas hábiles, es decir, se excluyen los sábados,
            domingos y feriados
          </p>
          <h3>13. SEGURIDAD</h3>
          <p>
            Al momento de registrarse en cualquiera de las PLATAFORMAS, EL
            CLIENTE crea una contraseña a la cual solo él tiene acceso y no
            deberá compartirla para proteger su privacidad y seguridad. KAMBIAZO
            protegerá su privacidad, almacenando de forma encriptada, la empresa
            no revelará los detalles de su cuenta, dirección o correo
            electrónico a nadie, salvo sea exigido legalmente como se detalla en
            nuestra Política de privacidad.
          </p>
          <p>
            Las operaciones que realizas son seguras, debido que la
            transferencia de fondos se realiza desde la plataforma de tu banco.
          </p>
          <p>
            Todos los datos brindados en propia voluntad por EL CLIENTE están
            sujetos a controles de seguridad en línea con la política SPLAFT de
            la SBS. Adicionalmente, si es necesaria una verificación adicional,
            le informaremos.
          </p>
          <p>KAMBIAZO solo utilizara sus datos para los siguientes fines:</p>
          <ul>
            <li>
              Validar su identidad en cumplimiento con lo establecido por la
              normativa vigente referente al lavado de activos y prevención del
              terrorismo (SPLAFT) de la SBS.
            </li>
            <li>Validar la veracidad de la información entregada</li>
            <li>
              Ponernos en contacto con EL CLIENTE por actividades propias de LOS
              SERVICIOS
            </li>
            <li>
              Para realizar seguimiento de respuesta a consultas o reclamos.
            </li>
            <li>Para analizar data estadística de manera anónima.</li>
            <li>Almacenamiento de datos personales.</li>
            <li>Para notificar cambios en nuestro servicio.</li>
            <li>
              Para cumplir la normativa vigente establecida por la Unidad de
              Inteligencia Financiera o cualquier regulación vigente y futura
              establecida por la SBS y otras disposiciones legales.
            </li>
          </ul>
          <h3>14. PRECIOS</h3>
          <p>
            Cuando EL CLIENTE realiza la operación en sistema tendrá 15 minutos
            para transferir los fondos a la cuenta de Tu Cambio S.A.C en el
            banco seleccionado. De no ser completada la transacción dentro del
            plazo señalado en el párrafo anterior, no se podrá grabar la
            operación por lo cual deberá de iniciar nuevamente a precios de
            pizarra que estén publicados en la web. De tratarse de una operación
            interbancaria, el tipo de cambio se respetará por el tiempo que
            demore la transferencia.
          </p>
          <h3>15. TIPO DE CAMBIO PREFERENCIAL</h3>
          <p>
            Para montos mayores a USD 3,000.00 o su equivalente en soles, el
            tipo de cambio podrá ser distinto al publicado en la página web
            www.kambiazo.pe, por lo cual EL CLIENTE deberá comunicarse por los
            canales de atención de la empresa. De tratarse de una operación
            interbancaria, el tipo de cambio se respetará por el tiempo que
            demore la transferencia.
          </p>
          <h3>16. CÓDIGO PROMOCIONAL O CUPÓN DE DESCUENTO</h3>
          <p>
            Los clientes podrán ingresar un cupón de descuento o código
            promocional para acceder a un tipo de cambio preferencial. Los
            cupones generalmente se entregan por ocasiones especiales o alianzas
            con socios estratégicos.
          </p>
          <h3>17. CONCEPTOS GENERALES</h3>
          <ul>
            <li>
              Compra: operación donde EL CLIENTE hace la transferencia de una
              moneda extranjera y KAMBIAZO hace el cambio a soles.
            </li>
            <li>
              Venta: operación donde EL CLIENTE hace la transferencia de soles y
              KAMBIAZO hace el cambio a una moneda extranjera.
            </li>
            <li>BCP: Banco de Crédito del Perú.</li>
            <li>Interbank: Banco Internacional del Perú.</li>
            <li>
              Transferencias a cuentas del mismo banco: cuando las cuentas
              origen o destino de EL CLIENTE son en alguno de los bancos donde
              KAMBIAZO también tiene cuentas bancarias, se denomina una
              transferencia entre cuentas del mismo banco. Es decir, cuando EL
              CLIENTE tiene cuentas en el banco BCP e Interbank al igual que
              KAMBIAZO.
            </li>
            <li>
              Transferencias interbancarias: cuando las transferencias son entre
              cuentas de distintos bancos locales. Es decir, cuando EL CLIENTE
              tiene cuentas en otros bancos distintos al banco BCP e Interbank
              como los tiene KAMBIAZO.
            </li>
            <li>
              Transferencias inmediatas: transferencias interbancarias que se
              pueden ejecutar en un plazo aproximado de quince (15) minutos,
              donde la entidad financiera establecerá una comisión por dicho
              servicio que EL CLIENTE deberá asumir.
            </li>
            <li>
              Transferencias regulares: transferencias interbancarias que pueden
              tener una comisión cobrada por la entidad financiera, y puede
              tomar hasta cuarenta y ocho (48) horas llegar a la cuenta destino
              una vez ejecutada dicha transferencia por EL CLIENTE. El tiempo de
              la transferencia dependerá del momento en que EL CLIENTE ejecutó
              su transferencia desde su banco y los horarios de transferencias
              interbancarias establecidas por la entidad financiera (de la
              cuenta origen y la cuenta destino).
            </li>
            <li>
              Transferencias interplaza: KAMBIAZO trabaja con cuentas del banco
              BCP a nivel nacional y para todo el resto de bancos sólo trabaja
              con cuentas de Lima. Cualquier comisión de las entidades
              financieras por el traslado de fondos a una plaza distinta a la de
              Lima deberá ser asumido por EL CLIENTE.
            </li>
            <li>
              Tiempo de Transferencia: el tiempo total de espera entre que EL
              CLIENTE registra la operación en LAS PLATAFORMAS, se realiza la
              transferencia y depósito del fondo en las cuentas de KAMBIAZO.
            </li>
          </ul>
          <h3>18. ABONOS EN EFECTIVO</h3>
          <p>
            KAMBIAZO no recibirá abonos en efectivo en sus cuentas de banco, ni
            entregará los fondos mediante abonos en efectivo. Solo se permitirá
            transferencias bancarias entre las cuentas de EL CLIENTE y KAMBIAZO.
            En caso EL CLIENTE haya realizado un abono en efectivo no se
            procesará la operación, devolviendo los fondos a una cuenta del
            banco a nombre de EL CLIENTE. En caso EL CLIENTE no cuente con
            alguna cuenta bancaria se devolverá los fondos mediante un cheque de
            gerencia deduciéndole el costo de la emisión del cheque. EL CLIENTE
            deberá utilizar las plataformas móviles y web de la banca digital de
            su banco.
          </p>
          <h3>19. ANULACIÓN DE OPERACIONES</h3>
          <p>
            Si EL CLIENTE se demora en realizar la transferencia y/o ésta exceda
            el plazo de recepción de fondos, KAMBIAZO no podrá mantenerle el
            tipo de cambio y la operación podrá quedar ANULADA.
          </p>
          <p>
            Se entiende por operación ANULADA, cuando esta última y el tipo de
            cambio no tiene vigencia y por tanto KAMBIAZO no asume el compromiso
            de mantener el tipo de cambio acordado.
          </p>
          <p>
            En el supuesto de que EL CLIENTE haya realizado la transferencia del
            monto acordado en el registro de la operación o el monto acordado
            haya ingresado a la cuenta bancaria de KAMBIAZO, pasado el plazo de
            recepción de fondos, EL CLIENTE podrá:
          </p>
          <ul>
            <li>
              Aceptar el nuevo tipo de cambio en base a la fecha y hora en que
              fue recibido el fondo por parte de KAMBIAZO. Esta fecha y hora
              será aquella que señale la banca en línea de la entidad financiera
              que recibe el fondo.
            </li>
            <li>
              Solicitar la devolución del monto, para lo cual KAMBIAZO procederá
              a hacer la devolución del monto en la cuenta registrada
              previamente, aceptando los costos (comisiones, impuestos y otros)
              en que incurra KAMBIAZO para tales efectos.
            </li>
          </ul>
          <p>
            Sin perjuicio de lo anteriormente mencionado, en caso KAMBIAZO
            detecte alguna anomalía en el tipo de cambio o no pueda procesar la
            operación, por razones ajenas a la responsabilidad de EL CLIENTE,
            tendrá la posibilidad de anular la operación de cambio de moneda
            procediendo con la devolución inmediata del monto abonado por EL
            CLIENTE.
          </p>
          <h3>20. CANCELACIÓN DE OPERACIONES</h3>
          <p>
            Ingresado el registro de la operación y transferencia de fondos por
            parte de EL CLIENTE, la ejecución de proceso de cambio de divisas
            por parte de KAMBIAZO es irreversible e irreivindicable.
          </p>
          <h3>21. LÍMITES DE OPERACIÓN</h3>
          <p>
            La cantidad máxima de moneda aceptada para el cambio varía
            dinámicamente en función de las condiciones del mercado.
            Inicialmente no hay límite para realizar cambios de monedas; sin
            embargo, KAMBIAZO se reserva el derecho de rechazar operaciones
            sobre montos establecidos durante el día en curso. Para ello se lo
            indicará a EL CLIENTE previo a que este ejecute la transferencia.
          </p>
          <h3>22. LIBRO DE RECLAMACIONES</h3>
          <p>
            Para ingresar un reclamo deberá ingresar por medio del libro de
            reclamaciones en la plataforma o escribirnos al correo
            contacto@kambiazo.pe nuestra finalidad gestionar de manera oportuna
            las dudas o consultas que EL CLIENTE tenga.
          </p>
          <h3>23. COMUNICACIONES</h3>
          <p>
            Al aceptar este contrato, aceptas que podamos comunicarnos por:
            teléfono, correo electrónico, whatsapp o publicando avisos en LAS
            PLATAFORMAS.
          </p>
          <h3>24. ACTUALIZACIÓN DE LAS CONDICIONES</h3>
          <p>
            KAMBIAZO podrá realizar actualizaciones y cambios sobre los Términos
            y Condiciones, de acuerdo con cambios y/o exigencias regulatorias, o
            por adecuaciones propias del servicio establecidas a criterio de
            KAMBIAZO. Toda actualización entrará en vigor desde su publicación a
            través de cualquiera de LAS PLATAFORMAS.
          </p>
          <p>
            Finalmente, KAMBIAZO realizará los esfuerzos necesarios para enviar
            una notificación de dicha actualización al CLIENTE que se encuentre
            operando a través de su correo registrado o haciendo su publicación
            en LAS PLATAFORMAS.
          </p>
          <h3>25. ACUERDO COMPLETO</h3>
          <p>
            Este contrato y cualquier documento expresamente mencionado en él,
            constituye el acuerdo entero entre EL CLIENTE y KAMBIAZO y sustituye
            cualquier discusión, correspondencia, acuerdos o entendimientos
            anteriores entre nosotros
          </p>
          <h3>26. LEY Y JURISDICCIÓN</h3>
          <p>
            Para la interpretación, cumplimiento y ejecución de estos términos y
            condiciones, las partes están de acuerdo en que serán aplicables las
            leyes vigentes en la República del Perú, renunciando expresamente a
            cualquier otra jurisdicción que pudiera corresponderles en razón de
            sus domicilios presente o futuros, sometiéndose a la competencia y
            jurisdicción de los Jueces y Tribunales de Lima, Perú.
          </p>
        </Container>
      </Box>
    </BaseModal>
  );
}

export default TermsAndConditionsModal;
