import React from 'react';
import ReactDOM from 'react-dom';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import '@brainhubeu/react-carousel/lib/style.css';
import { SnackbarProvider } from 'notistack';
import smoothscroll from 'smoothscroll-polyfill';

import 'theme/reset.scss';
import 'theme/fonts.scss';
import mainTheme from 'theme/mainTheme';
import { runAxiosHooksConfig } from 'utils/api';

import * as serviceWorker from './serviceWorker';
import AppRoutes from './AppRoutes';
import { SessionProvider } from './context/Session';
import { ExchangeProvider } from './context/Exchange';
import { ProfileProvider } from './context/Profile';

smoothscroll.polyfill();

runAxiosHooksConfig();

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={mainTheme}>
      <CssBaseline />
      <SnackbarProvider
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        maxSnack={1}
      >
        <SessionProvider>
          <ExchangeProvider>
            <ProfileProvider>
              <AppRoutes />
            </ProfileProvider>
          </ExchangeProvider>
        </SessionProvider>
      </SnackbarProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
