import React, { useState } from 'react';
import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';

import ClientType from 'common/clientType';
import peopleImg from 'assets/images/login/people.png';
import plusImg from 'assets/images/login/plus.png';
import workersImg from 'assets/images/login/workers.png';
import { useSessionContext } from 'context/Session';
import { useProfileContext, Client } from 'context/Profile';
import { exchangeRoute } from '../../../Dashboard/routes';

import useStyles from './styles';
import NewCorporateRole from './NewCorporateRole';
import NewPersonRole from './NewPersonRole';

interface CommonProps {
  onClick: () => void;
  client: Client | null;
}

function NaturalPerson({ onClick, client }: CommonProps) {
  const classes = useStyles();

  const name = client
    ? `${client.name} ${client.fathersLastName || client.mothersLastName}`
    : '';

  return (
    <Grid item xs={12} md={6} className={classes.gridCard}>
      <Card className={classes.cardWrapper}>
        <CardActionArea onClick={onClick}>
          <CardMedia
            className={classes.media}
            image={peopleImg}
            title='Continuar como persona natural'
          />
          <CardContent className={classes.cardContent}>
            <Typography
              className={classes.cardSmText}
              variant='caption'
              color='textSecondary'
              component='p'
              align='center'
            >
              Inicia sesión como:
            </Typography>
            <Typography
              className={classes.cardBigText}
              gutterBottom
              variant='h6'
              align='center'
              color='secondary'
            >
              {name}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions disableSpacing>
          <Button
            variant='contained'
            color='primary'
            onClick={onClick}
            className={classes.cardActionsBtn}
          >
            Continuar
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
}

function Company({ onClick, client }: CommonProps) {
  const classes = useStyles();

  return (
    <Grid item xs={12} md={6} className={classes.gridCard}>
      <Card className={classes.cardWrapper}>
        <CardActionArea onClick={onClick}>
          <CardMedia
            className={classes.media}
            image={workersImg}
            title='Continuar como persona jurídica'
          />
          <CardContent className={classes.cardContent}>
            <Typography
              className={classes.cardSmText}
              variant='caption'
              color='textSecondary'
              component='p'
              align='center'
            >
              Inicia sesión como:
            </Typography>
            <Typography
              className={classes.cardBigText}
              gutterBottom
              variant='h6'
              align='center'
              color='secondary'
            >
              {client?.businessName}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions disableSpacing>
          <Button
            variant='contained'
            color='primary'
            onClick={onClick}
            className={classes.cardActionsBtn}
          >
            Continuar
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
}

interface CreateNewProps {
  newType: ClientType;
}

function CreateNew({ newType }: CreateNewProps) {
  const [showModal, setShowModal] = useState(false);
  const { client, updateClient } = useProfileContext();
  const classes = useStyles();

  const handleSubmit = async (values: Partial<Client>) => {
    return updateClient({
      ...values,
      ...(client ? { email: client.email } : {}),
    });
  };

  return (
    <>
      <Grid item xs={12} md={6} className={classes.gridCard}>
        <Card className={classes.cardWrapper}>
          <CardActionArea onClick={() => setShowModal(true)}>
            <CardMedia
              className={classes.media}
              image={plusImg}
              title={
                newType === ClientType.Company
                  ? 'Crea un nuevo perfil para tu empresa'
                  : 'Crea un perfil como persona natural'
              }
            />
            <CardContent className={classes.cardContent}>
              <Typography
                className={classes.cardSmText}
                variant='caption'
                color='textSecondary'
                component='p'
                align='center'
              >
                {newType === ClientType.Company
                  ? 'Crea un perfil para'
                  : 'Crea un perfil'}
              </Typography>
              <Typography
                className={classes.cardBigText}
                gutterBottom
                variant='h6'
                align='center'
                color='secondary'
              >
                {newType === ClientType.Company ? 'Tu empresa' : 'Personal'}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
      {newType === ClientType.Company ? (
        <NewCorporateRole
          open={showModal}
          onSubmit={handleSubmit}
          onClose={() => setShowModal(false)}
        />
      ) : (
        <NewPersonRole
          open={showModal}
          onSubmit={handleSubmit}
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  );
}

export default function ClientTypeSelector() {
  const { state } = useLocation<{ redirectTo: string }>();
  const { setCurrentClientType } = useSessionContext();
  const { client } = useProfileContext();
  const history = useHistory();

  const handleClick = (clientType: ClientType) => {
    setCurrentClientType(clientType);
    if (state?.redirectTo) {
      history.push(state.redirectTo);
    } else {
      history.push(exchangeRoute);
    }
  };

  if (client?.clientType === ClientType.Person) {
    return (
      <Grid container item xs={12} md={8} spacing={8}>
        <NaturalPerson
          client={client}
          onClick={() => handleClick(ClientType.Person)}
        />
        <CreateNew newType={ClientType.Company} />
      </Grid>
    );
  }
  if (client?.clientType === ClientType.Company) {
    return (
      <Grid container item xs={12} md={8} spacing={8}>
        <Company
          client={client}
          onClick={() => handleClick(ClientType.Company)}
        />
        <CreateNew newType={ClientType.Person} />
      </Grid>
    );
  }
  return (
    <Grid container item xs={12} md={8} spacing={8}>
      <NaturalPerson
        client={client}
        onClick={() => handleClick(ClientType.Person)}
      />
      <Company
        client={client}
        onClick={() => handleClick(ClientType.Company)}
      />
    </Grid>
  );
}
