import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
      // padding 2 is to fix a bug related with skew in `li` elements
      paddingTop: 2,
      paddingBottom: 2,
      paddingLeft: theme.spacing(12),
      paddingRight: theme.spacing(16),

      [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap',
        padding: 0,
        paddingTop: theme.spacing(4),
        '& > div:first-child': {
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
        },
      },
    },
    title: {
      fontSize: '2.5rem',
      marginBottom: theme.spacing(1),

      [theme.breakpoints.down('lg')]: {
        fontSize: '2.25rem',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '1.5rem',
      },
    },
    subtitle: {
      maxWidth: theme.spacing(55),
      fontWeight: 'normal',
      color: theme.palette.common.white,
      lineHeight: '1.2em',

      [theme.breakpoints.up('md')]: {
        paddingRight: theme.spacing(5),
      },
      [theme.breakpoints.down('lg')]: {
        maxWidth: theme.spacing(45),
        fontSize: '1.125rem',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '1rem',
      },
    },
    accordion: {
      flex: 1,
      maxWidth: theme.breakpoints.values.lg,
      marginLeft: theme.spacing(2),
      height: theme.spacing(80),
      color: theme.palette.common.white,

      '&  ul': {
        display: 'flex',
        height: '100%',

        '& li': {
          flex: 1,
          transition: 'all 500ms ease-in-out',
          position: 'relative',

          '&::before': {
            content: '""',
            display: 'block',
            height: '100%',
            transform: 'skewX(-14deg)',
          },

          '&:hover': {
            flex: 2,

            '&::before': {
              boxShadow: 'inset -1px -1px 10px 0 rgba(0,0,0,0.125)',
            },

            '& span': {
              opacity: 0,
            },

            '& div': {
              opacity: 1,
            },
          },

          '& span, div': {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            transition: 'all 250ms ease-in-out',
          },

          '& div': {
            opacity: 0,
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'center',
            textAlign: 'center',

            '& img': {
              height: theme.spacing(15),
              marginBottom: theme.spacing(2),
            },
          },

          '& span': {
            fontSize: '7rem',
            fontWeight: theme.typography.fontWeightBold,
          },
        },

        '& li:nth-child(1)': {
          '&::before': {
            backgroundColor: '#023144',
            outline: '1px solid #023144',
            border: '1px solid #023144',
          },
          '& span': {
            opacity: 1,
          },
        },
        '& li:nth-child(2)': {
          '&::before': {
            backgroundColor: '#042C38',
            outline: '1px solid #042C38',
            border: '1px solid #042C38',
          },
          '& span': {
            opacity: 0.85,
          },
        },
        '& li:nth-child(3)': {
          '&::before': {
            backgroundColor: '#04252D',
            outline: '1px solid #04252D',
            border: '1px solid #04252D',
          },

          '& span': {
            opacity: 0.75,
          },
        },
        '& li:nth-child(4)': {
          '&::before': {
            backgroundColor: '#061F23',
            outline: '1px solid #061F23',
            border: '1px solid #061F23',
          },
          '& span': {
            opacity: 0.42,
          },
        },
      },
    },

    accordionText: {
      width: theme.spacing(35),
      [theme.breakpoints.down('lg')]: {
        width: theme.spacing(25),
      },
    },
    childColors: {
      position: 'relative',
      marginTop: theme.spacing(3),
      '& li:nth-child(1n)': {
        backgroundColor: '#023144',
      },
      '& li:nth-child(2n)': {
        backgroundColor: '#042C38',
      },
      '& li:nth-child(3n)': {
        backgroundColor: '#04252D',
      },
      '& li:nth-child(4n)': {
        backgroundColor: '#061F23',
      },
      '& .BrainhubCarousel__customArrows': {
        zIndex: 1,
      },
      '& .BrainhubCarousel__custom-arrowRight': {
        '& $navIcons': {
          right: 0,
        },
      },
    },
    navIcons: {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
    },
    flexItem: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      '& img': {
        height: theme.spacing(15),
        marginBottom: theme.spacing(2),
      },

      '& p': {
        width: theme.spacing(30),
      },
    },
    borders: {
      padding: theme.spacing(4),
    },
  })
);
