import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

import logo from 'assets/images/common/logo.png';
import { mainRoute as appMainRoute, loginRoute, registerRoute } from 'routes';
import { profileRoute } from 'pages/Dashboard/routes';

import useStyles from './styles';
import { useSessionContext } from '../../context/Session';

interface ButtonsProps {
  isAuthenticated: boolean;
  handleLogout: () => void;
}

function Buttons({ isAuthenticated, handleLogout }: ButtonsProps) {
  return isAuthenticated ? (
    <>
      <Button
        variant='contained'
        color='primary'
        component={Link}
        to={profileRoute}
      >
        Ir a mi Perfil
      </Button>
      <Button variant='outlined' color='primary' onClick={handleLogout}>
        Cerrar Sesión
      </Button>
    </>
  ) : (
    <>
      <Button
        variant='contained'
        color='primary'
        component={Link}
        to={loginRoute}
      >
        Iniciar sesión
      </Button>
      <Button
        variant='outlined'
        color='primary'
        component={Link}
        to={registerRoute}
      >
        Registrarse
      </Button>
    </>
  );
}

export const sectionsIds = {
  home: 'home',
  weInfo: 'we-info',
  howItWorks: 'how-it-works',
  alliances: 'alliances',
  contactUs: 'contact-us',
};

const headerLinks = [
  { text: 'Inicio', path: `#${sectionsIds.home}` },
  { text: 'Nosotros', path: `#${sectionsIds.weInfo}` },
  { text: '¿Cómo funciona?', path: `#${sectionsIds.howItWorks}` },
  { text: 'Alianzas', path: `#${sectionsIds.alliances}` },
  { text: 'Contáctanos', path: `#${sectionsIds.contactUs}` },
];

function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { isAuthenticated, removeToken } = useSessionContext();
  const history = useHistory();
  const classes = useStyles();

  const handleLogout = () => {
    history.push(appMainRoute);
    removeToken();
  };

  return (
    <>
      <header className={classes.navBar}>
        <Link to={appMainRoute}>
          <Box display='flex' alignItems='center'>
            <img src={logo} alt='logo' className={classes.logo} />
            <p className={classes.logoText}>kambiazo.pe</p>
          </Box>
        </Link>
        <Hidden mdDown>
          <div className={classes.links}>
            {headerLinks.map((v) => (
              <Link key={v.text} to={{ pathname: appMainRoute, hash: v.path }}>
                {v.text}
              </Link>
            ))}
          </div>
        </Hidden>
        <Hidden smDown>
          <div className={classes.buttons}>
            <Buttons
              isAuthenticated={isAuthenticated}
              handleLogout={handleLogout}
            />
          </div>
        </Hidden>
        <Hidden lgUp>
          <Box ml={{ xs: 'auto', md: 4 }}>
            <IconButton onClick={() => setIsMenuOpen(true)} color='primary'>
              <FontAwesomeIcon icon={faBars} />
            </IconButton>
          </Box>
        </Hidden>
      </header>

      <Drawer
        anchor='right'
        open={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
      >
        <Box width={250}>
          <List component='nav'>
            {headerLinks.map((v) => (
              <ListItem
                key={v.text}
                button
                component={Link}
                to={{ pathname: appMainRoute, hash: v.path }}
                onClick={() => setIsMenuOpen(false)}
              >
                <ListItemText primary={v.text} />
              </ListItem>
            ))}
          </List>
          <Divider />
          <div className={classes.rightMenuButtons}>
            <Buttons
              isAuthenticated={isAuthenticated}
              handleLogout={handleLogout}
            />
          </div>
        </Box>
      </Drawer>
    </>
  );
}

export default Navbar;
