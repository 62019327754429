import React, { memo, KeyboardEvent, ChangeEvent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import {
  Button,
  Grid,
  MenuItem,
  TextField,
  Typography,
  InputAdornment,
  Box,
} from '@material-ui/core';

import { CurrencyName } from 'common/money';

import useStyles from '../styles';

interface Props {
  searchCode: string;
  handleChangeSearchCode: (event: ChangeEvent<{ value: unknown }>) => void;
  selectCurrency: string;
  handleChangeSelectCurrency: (event: ChangeEvent<{ value: unknown }>) => void;
  handleSearch: (event: KeyboardEvent<HTMLDivElement>) => void;
  handleClickOpen: () => void;
}

const BankAccountsHeader = memo(
  ({
    searchCode,
    handleChangeSearchCode,
    selectCurrency,
    handleChangeSelectCurrency,
    handleSearch,
    handleClickOpen,
  }: Props) => {
    const classes = useStyles();

    return (
      <>
        <Grid item xs={12}>
          <Box pt={5}>
            <Typography
              className={classes.bankAccountsTitle}
              variant='h5'
              color='secondary'
              align='center'
              gutterBottom
            >
              Tus cuentas <span>bancarias</span>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={5} className={classes.bankAccountsTopGrid}>
          <Typography
            variant='h6'
            component='span'
            className={classes.selectorText}
          >
            Buscar por:
          </Typography>
          <TextField
            id='search'
            variant='outlined'
            size='small'
            className={classes.inputBackground}
            value={searchCode}
            onChange={handleChangeSearchCode}
            onKeyDown={handleSearch}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position='start'>
                  <FontAwesomeIcon icon={faSearch} size='xs' />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} md={5} className={classes.bankAccountsTopGrid}>
          <Typography
            variant='h6'
            component='span'
            className={classes.selectorText}
          >
            Tipo de Moneda:
          </Typography>
          <TextField
            id='currency'
            className={classes.inputBackgroundSelector}
            select
            variant='outlined'
            size='small'
            value={selectCurrency}
            onChange={handleChangeSelectCurrency}
            fullWidth
          >
            <MenuItem value=''>Todas</MenuItem>
            {Object.entries(CurrencyName).map(([key, name]) => (
              <MenuItem key={key} value={key}>
                {name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid container item xs={12} md={2} alignItems='center'>
          <Button
            className={classes.bankAccountsAddBtn}
            variant='contained'
            color='secondary'
            size='small'
            fullWidth
            onClick={handleClickOpen}
          >
            Agregar Cuenta
          </Button>
        </Grid>
      </>
    );
  }
);

export default BankAccountsHeader;
