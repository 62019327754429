export enum Document {
  DNI = 'D',
  RUC = 'R',
  IMMIGRATION = 'I',
}

export const DocumentName = {
  [Document.DNI]: 'DNI',
  [Document.RUC]: 'RUC',
  [Document.IMMIGRATION]: 'Carnet de Extranjería',
};
