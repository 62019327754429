import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  Card,
} from '@material-ui/core';
import clsx from 'clsx';
import { format, differenceInMinutes, addMinutes } from 'date-fns';
import { useSnackbar } from 'notistack';

import { ErrorMessage } from 'components';
import { CurrencyName, formatMoney } from 'common/money';
import { DocumentName } from 'common/document';
import { commonError } from 'common/errorMessages';
import { useExchangeContext } from 'context/Exchange';
import { api } from 'utils';
import ReportOperationModal from 'pages/ProfileHistory/components/ReportOperationModal';

import ExchangeInfo from '../ExchangeInfo';
import useStyles from './styles';

type ReviewStepProps = {
  onPressNext: () => void;
  children: React.ReactNode;
};

export default function ReviewStep({ onPressNext, children }: ReviewStepProps) {
  const [operationModalOpen, setOperationModalOpen] = useState(false);
  const [minutesLeft, setMinutesLeft] = useState(20);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const {
    exchange,
    sendValue,
    getExchangeRate,
    getReceiveCurrency,
    getReceiveAmount,
    clearExchange,
  } = useExchangeContext();
  const classes = useStyles();

  useEffect(() => {
    if (exchange) {
      const timer = setInterval(() => {
        const differenLeft = differenceInMinutes(
          addMinutes(new Date(exchange.date), 20),
          new Date()
        );
        setMinutesLeft(differenLeft);
        if (differenLeft === 0) clearInterval(timer);
        return () => {
          if (timer) clearInterval(timer);
        };
      }, 60 * 1000);
    }
  }, [exchange]);

  const handleReportOperation = useCallback(
    async (transactionNumber) => {
      setLoading(true);
      try {
        await api.patch(`clients/exchange/save-transaction-code/`, {
          token: exchange?.token,
          transactionNumber,
        });
        setOperationModalOpen(false);
        clearExchange();
        setLoading(false);
        onPressNext();
      } catch (error) {
        enqueueSnackbar(commonError, { variant: 'error' });
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setLoading, setOperationModalOpen, exchange, enqueueSnackbar]
  );

  const handleOperationModalOpen = () => {
    setOperationModalOpen(true);
  };
  const handleOperationModalClose = () => {
    setOperationModalOpen(false);
  };

  if (!exchange) {
    return <ErrorMessage />;
  }

  return (
    <>
      <Box
        display='flex'
        alignItems='center'
        flexDirection='column'
        width='100%'
      >
        <Typography
          variant='h5'
          color='secondary'
          display='inline'
          align='center'
        >
          Transfiere desde{' '}
          <Typography variant='inherit' component='span' color='primary'>
            tu banco:
          </Typography>
        </Typography>
        {children}

        <Container maxWidth='md' disableGutters>
          <ExchangeInfo
            sendCurrency={sendValue.currency}
            sendValue={sendValue.amount}
            receiveCurrency={getReceiveCurrency()}
            receiveValue={getReceiveAmount()}
            exchange={getExchangeRate()}
          />

          <Typography
            variant='h6'
            align='center'
            className={classes.code}
            noWrap
          >
            Tu número de orden es:
            <br />
            <strong>{exchange.token}</strong>
          </Typography>

          <Container maxWidth='sm' disableGutters>
            <Card className={classes.card}>
              <Box mb={4}>
                <Typography
                  align='center'
                  className={clsx(
                    classes.description,
                    classes.textWithDecorations
                  )}
                >
                  Operación registrada. Por favor, transfiera{' '}
                  <strong>
                    {formatMoney(sendValue.currency, sendValue.amount)}
                  </strong>{' '}
                  desde tu <strong>Banca Móvil o Banca por Internet</strong> a
                  la siguiente cuenta:
                </Typography>
              </Box>
              <Grid container justify='center' spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography
                    align='center'
                    color='primary'
                    className={classes.descriptionItem}
                  >
                    {exchange.fromAdminAccount.bank === exchange.fromBank ? (
                      <>
                        Nº de Cuenta:
                        <span>{exchange.fromAdminAccount.number}</span>
                      </>
                    ) : (
                      <>
                        CCI
                        <span>{exchange.fromAdminAccount.interbankNumber}</span>
                      </>
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    align='center'
                    color='primary'
                    className={classes.descriptionItem}
                  >
                    Banco:
                    <span>{exchange.fromAdminAccount.bankName}</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    align='center'
                    color='primary'
                    className={classes.descriptionItem}
                  >
                    Titular:
                    <span>{exchange.fromAdminAccount.holdersName}</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    align='center'
                    color='primary'
                    className={classes.descriptionItem}
                  >
                    Moneda:
                    <span>
                      {CurrencyName[exchange.fromAdminAccount.currency]}
                    </span>
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography
                    align='center'
                    color='primary'
                    className={classes.descriptionItem}
                  >
                    Tipo de cuenta:
                    <span>{exchange.fromAdminAccount.accountTypeName}</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    align='center'
                    color='primary'
                    className={classes.descriptionItem}
                  >
                    {DocumentName[exchange.fromAdminAccount.documentType]}
                    <span>{exchange.fromAdminAccount.documentNumber}</span>
                  </Typography>
                </Grid>
                {!!exchange.coupon && (
                  <Grid item xs={12} sm={6}>
                    <Typography
                      align='center'
                      color='primary'
                      className={classes.descriptionItem}
                    >
                      Cupón:
                      <span>{exchange.coupon}</span>
                    </Typography>
                  </Grid>
                )}
              </Grid>
              <Box mt={4}>
                <Typography
                  variant='body2'
                  align='center'
                  gutterBottom
                  className={classes.textWithDecorations}
                >
                  Tienes {minutesLeft} minutos{' '}
                  <strong>
                    para realizar la
                    <br /> transferencia
                  </strong>{' '}
                  sin perder el tipo de cambio
                </Typography>
                <Typography
                  variant='body2'
                  align='center'
                  className={classes.textWithDecorations}
                >
                  (Hora de inicio: {format(new Date(exchange.date), 'h:mm a')}{' '}
                  &nbsp;&nbsp;&nbsp;&nbsp; Hora de finalización:{' '}
                  {format(addMinutes(new Date(exchange.date), 20), 'h:mm a')})
                </Typography>
              </Box>
            </Card>
          </Container>

          <Button
            color='primary'
            variant='contained'
            onClick={handleOperationModalOpen}
            className={classes.button}
          >
            Ya Transferí
          </Button>
        </Container>
      </Box>

      <ReportOperationModal
        open={operationModalOpen}
        reportOperation={exchange}
        handleCloseReportOperationModal={handleReportOperation}
        handleCloseModal={handleOperationModalClose}
        loadingReport={loading}
      />
    </>
  );
}
