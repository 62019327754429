import React, { useState, useCallback, ChangeEvent } from 'react';

import {
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';

import { BaseModal } from 'components';
import { commonError } from 'common/errorMessages';
import { api } from 'utils';

import useStyles from '../styles';

interface Entity {
  id: number;
  created: string;
  modified: string;
  companyName: string;
}

interface Props {
  open: boolean;
  handleClose: () => void;
  entity: Entity;
}

const EnterCodeModal = ({ open, handleClose, entity }: Props) => {
  const classes = useStyles();
  const [codeInput, setCodeInput] = useState('');
  const [loadingCode, setLoadingCode] = useState(false);
  const [codeSuccess, setCodeSuccess] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleChangeCodeInput = useCallback(
    ({ target: { value } }: ChangeEvent<{ value: unknown }>) => {
      setCodeInput(value as string);
    },
    [setCodeInput]
  );

  const handleConfirm = useCallback(async () => {
    setLoadingCode(true);
    try {
      await api.post(`clients/discounts-request/`, {
        operatorCode: codeInput,
        agreement: entity.id,
        exchangeRate: null,
      });
      setCodeSuccess(true);
      setLoadingCode(false);
    } catch (error) {
      enqueueSnackbar(commonError, { variant: 'error' });
      setLoadingCode(false);
    }
  }, [codeInput, enqueueSnackbar, entity]);

  const handleCloseSuccess = useCallback(() => {
    setCodeSuccess(false);
    setCodeInput('');
    handleClose();
  }, [handleClose]);

  const handleCloseNormal = useCallback(() => {
    setCodeInput('');
    handleClose();
  }, [handleClose]);

  if (!entity) return null;

  return (
    <BaseModal
      open={open}
      onClose={codeSuccess ? handleCloseSuccess : handleCloseNormal}
    >
      {!codeSuccess ? (
        <Box
          display='flex'
          flexDirection='column'
          alignItems='center'
          py={7}
          px={10}
        >
          <Typography
            variant='h5'
            color='textPrimary'
            gutterBottom
            className={classes.modalTitle}
          >
            {entity.companyName}
          </Typography>
          <Typography color='secondary' gutterBottom>
            <b>Ingrasa tu código de operador:</b>
          </Typography>
          <TextField
            className={classes.modalInput}
            variant='outlined'
            margin='dense'
            size='small'
            value={codeInput}
            onChange={handleChangeCodeInput}
          />
          <Button
            variant='contained'
            color='primary'
            className={classes.getDiscountsBtn}
            onClick={handleConfirm}
          >
            Confirmar
          </Button>
          {loadingCode && (
            <div className={classes.deleteLoadingContainer}>
              <CircularProgress />
            </div>
          )}
        </Box>
      ) : (
        <Box
          display='flex'
          flexDirection='column'
          alignItems='center'
          py={7}
          px={10}
        >
          <Typography variant='h5' color='textPrimary' gutterBottom>
            {entity.companyName}
          </Typography>
          <Typography color='secondary' gutterBottom>
            <b>Solicitud registrada</b>
          </Typography>
          <Typography color='textSecondary' gutterBottom align='center'>
            Los datos le serán corroborados en un <br />
            tiempo máximo de 30 min.
          </Typography>
          <Button
            variant='contained'
            color='primary'
            className={classes.getDiscountsBtn}
            onClick={handleCloseSuccess}
          >
            Regresar
          </Button>
        </Box>
      )}
    </BaseModal>
  );
};

export default EnterCodeModal;
