import React from 'react';

import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function CrediCardsIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox='0 0 22.1 21.3'>
      <g>
        <path
          d='M17.3,21.1c-5.1,0-10.2,0-15.4,0c0,0-0.1,0-0.1-0.1c-0.6-0.2-1-0.7-1-1.4c0-3.1,0-6.2,0-9.3
          c0-0.1,0-0.2,0-0.3C1,9.2,1.6,8.8,2.3,8.8c2.8,0,5.6,0,8.4,0c2.1,0,4.2,0,6.3,0c0.8,0,1.4,0.6,1.4,1.4c0,3.2,0,6.3,0,9.5
          c0,0.3-0.1,0.6-0.3,0.8C17.9,20.8,17.6,21,17.3,21.1z M11.5,14.9c-0.1,0-0.2,0-0.3,0c-1.2,0.1-2,1.3-1.7,2.4
          c0.4,1.5,2,2.1,3.2,1.1c0.1-0.1,0.2-0.1,0.3,0c0.3,0.3,0.7,0.4,1.1,0.4c0.8,0,1.5-0.2,1.9-0.9c0.5-0.8,0.4-1.5-0.1-2.3
          c-0.7-0.9-2-1.1-2.9-0.4c-0.1,0.1-0.2,0.1-0.3,0C12.3,15.1,11.9,14.9,11.5,14.9z M7.3,12.1c-1.1,0-2.3,0-3.4,0
          c-0.2,0-0.2,0.1-0.2,0.2c0,0.2,0,0.4,0,0.7c0,0.3,0,0.3,0.3,0.3c1.8,0,3.7,0,5.5,0c0.4,0,0.8,0,1.3,0c0.1,0,0.2-0.1,0.2-0.2
          c0-0.2,0-0.5,0-0.7c0-0.3,0-0.3-0.3-0.3C9.5,12.1,8.4,12.1,7.3,12.1z M14.4,12.1c-0.5,0-1,0-1.4,0c-0.1,0-0.2,0-0.2,0.2
          c0,0.3,0,0.6,0,0.9c0,0.1,0.1,0.2,0.2,0.2c1,0,1.9,0,2.9,0c0.1,0,0.2-0.1,0.2-0.2c0-0.3,0-0.5,0-0.8c0-0.2-0.1-0.3-0.3-0.3
          C15.2,12.1,14.8,12.1,14.4,12.1z'
        />
        <path
          d='M21.8,8.5c-0.1,0.4-0.3,0.7-0.6,0.9c-0.5,0.4-0.9,0.9-1.3,1.3c0,0-0.1,0.1-0.1,0.1c-0.1,0,0-0.1,0-0.2
          c0-0.5,0-1-0.2-1.5c-0.5-1.1-1.4-1.6-2.5-1.6c-1.5,0-3,0-4.5,0c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0c0,0,0-0.1,0.1-0.1
          c0,0,0.1-0.1,0.1-0.1c1.5-1.5,3-3,4.5-4.5c0.2-0.2,0.2-0.2,0.4,0c1.4,1.4,2.8,2.8,4.2,4.2c0.2,0.2,0.4,0.5,0.5,0.8
          C21.8,8.1,21.8,8.3,21.8,8.5z'
        />
        <path
          d='M5.7,7.6c0-0.1,0.1-0.2,0.2-0.2c2.2-2.2,4.5-4.5,6.7-6.7c0.7-0.7,1.5-0.7,2.2,0c0.3,0.3,0.7,0.7,1,1
          c0.2,0.2,0.2,0.2,0,0.4c-1.5,1.5-3,3-4.5,4.5c-0.3,0.3-0.6,0.6-0.9,0.9c-0.1,0.1-0.2,0.1-0.3,0.1c-1.4,0-2.8,0-4.3,0
          C5.8,7.6,5.7,7.6,5.7,7.6z'
        />
        <path
          d='M12.2,16.9c0,0.5-0.4,0.8-0.9,0.8c-0.5,0-0.8-0.4-0.8-0.8c0-0.5,0.4-0.8,0.9-0.8
          C11.9,16.1,12.2,16.4,12.2,16.9z'
        />
        <path
          d='M14.2,17.7c-0.5,0-0.8-0.4-0.8-0.8c0-0.5,0.4-0.8,0.8-0.8c0.5,0,0.8,0.4,0.8,0.8
          C15.1,17.4,14.7,17.7,14.2,17.7z'
        />
      </g>
    </SvgIcon>
  );
}
