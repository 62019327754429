import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { fontJosefinSans, headerHeight, headerHeightSm } from 'theme/mainTheme';

export default makeStyles((theme: Theme) =>
  createStyles({
    navBar: {
      display: 'flex',
      alignItems: 'center',
      zIndex: 2,
      position: 'fixed',
      top: 0,
      height: headerHeight,
      width: '100vw',
      backgroundColor: theme.palette.secondary.main,
      boxShadow: '0 0 15px 10px rgba(0, 0, 0, 0.3)',
      padding: theme.spacing(0, 9),

      '& a': {
        textDecoration: 'none',
      },

      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(0, 5),
      },
      [theme.breakpoints.down('sm')]: {
        height: headerHeightSm,
      },
    },
    logo: {
      height: theme.spacing(8),
      [theme.breakpoints.down('xs')]: {
        height: theme.spacing(6),
      },
    },
    logoText: {
      fontFamily: fontJosefinSans,
      fontSize: theme.spacing(2),
      textTransform: 'uppercase',
      color: theme.palette.common.white,
      marginLeft: theme.spacing(2.5),
    },
    links: {
      marginLeft: theme.spacing(7),
      whiteSpace: 'nowrap',

      '& > a': {
        fontFamily: ['Myriad', 'sans-serif'].join(),
        fontSize: theme.spacing(2.25),
        color: theme.palette.common.white,
        margin: '0 16px',
        outline: 0,
      },
    },
    buttons: {
      margin: `0 ${theme.spacing(1)}px 0 auto`,
      display: 'flex',

      '& > a, button': {
        margin: theme.spacing(0.5),
        whiteSpace: 'nowrap',
      },
    },
    rightMenuButtons: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: theme.spacing(2),
      padding: theme.spacing(0, 2),

      '& > a': {
        margin: theme.spacing(2, 0),
      },
    },
  })
);
