import React, {
  useState,
  useCallback,
  useEffect,
  ChangeEvent,
  KeyboardEvent,
} from 'react';

import {
  Box,
  Grid,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import { Pagination, Skeleton } from '@material-ui/lab';
import { useSnackbar } from 'notistack';

import { TrashCanIcon } from 'components/CustomIcons';
import { CreateAccountModal } from 'pages/Exchange/components';
import { CurrencyName, Currency } from 'common/money';
import { commonError } from 'common/errorMessages';
import { api } from 'utils';

import BankAccountsHeader from './components/BankAccountsHeader';
import WarningDeleteModal from './components/WarningDeleteModal';
import useStyles from './styles';

interface BankAccount {
  id: number;
  currency: Currency;
  documentType: string;
  number: string;
  interbankNumber: string;
  holdersName: string;
  documentNumber: string;
  bank: number;
  bankName: string;
  accountType: number;
  accountTypeName: string;
}

const PAGE_SIZE = 5;

export default function BankAccounts() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [firstTime, setFirstTime] = useState(true);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [loadingTable, setLoadingTable] = useState(true);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [indexWarning, setIndexWarning] = useState<null | number>(null);
  const [rows, setRows] = useState<BankAccount[]>([]);
  const [searchCode, setSearchCode] = useState('');
  const [selectCurrency, setSelectCurrency] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleGetTableData = useCallback(async () => {
    setLoadingTable(true);
    if (firstTime) {
      setRows([]);
    }
    try {
      const {
        data: { results, count },
      } = await api.get(`clients/accounts/`, {
        params: {
          page,
          page_size: PAGE_SIZE,
          currency: selectCurrency,
          search: searchCode,
        },
      });
      if (firstTime) {
        setFirstTime(false);
      }
      setRows(results);
      setPageCount(Math.ceil(count / PAGE_SIZE));
      setLoadingTable(false);
    } catch (error) {
      enqueueSnackbar(commonError, { variant: 'error' });
      setLoadingTable(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, selectCurrency, firstTime]);

  useEffect(() => {
    handleGetTableData();
  }, [page, handleGetTableData]);

  const handleChangePage = useCallback(
    (_: ChangeEvent<unknown>, value: number) => {
      setPage(value);
    },
    [setPage]
  );

  const handleChangeSelectCurrency = useCallback(
    ({ target: { value } }: ChangeEvent<{ value: unknown }>) => {
      setSelectCurrency(value as string);
    },
    [setSelectCurrency]
  );

  const handleChangeSearchCode = useCallback(
    ({ target: { value } }: ChangeEvent<{ value: unknown }>) => {
      setSearchCode(value as string);
    },
    [setSearchCode]
  );

  const handleSearch = useCallback(
    async (e: KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'Enter') {
        setLoadingTable(true);
        try {
          const {
            data: { results, count },
          } = await api.get(`clients/accounts/`, {
            params: {
              page: 1,
              page_size: PAGE_SIZE,
              search: searchCode,
              currency: selectCurrency,
            },
          });
          setRows(results);
          setPageCount(Math.ceil(count / 5));
          setPage(1);
          setLoadingTable(false);
        } catch (error) {
          enqueueSnackbar(commonError, { variant: 'error' });
          setLoadingTable(false);
        }
      }
    },
    [enqueueSnackbar, searchCode, selectCurrency]
  );

  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleClickOpenConfirm = useCallback(
    (index: number) => {
      setOpenConfirm(true);
      setIndexWarning(index);
    },
    [setOpenConfirm, setIndexWarning]
  );

  const handleCloseConfirm = useCallback(() => {
    setOpenConfirm(false);
    setIndexWarning(null);
  }, [setOpenConfirm, setIndexWarning]);

  const handleCloseDelete = useCallback(async () => {
    setLoadingDelete(true);
    try {
      await api.delete(`clients/accounts/${indexWarning}/`);
      setIndexWarning(null);
      setOpenConfirm(false);
      setLoadingDelete(false);
      handleGetTableData();
    } catch (error) {
      enqueueSnackbar(commonError, { variant: 'error' });
      setLoadingDelete(false);
    }
  }, [
    setLoadingDelete,
    setIndexWarning,
    setOpenConfirm,
    indexWarning,
    handleGetTableData,
    enqueueSnackbar,
  ]);

  const handleSuccessCreateAccount = useCallback(
    (newAccount) => {
      if (newAccount) handleGetTableData();
    },
    [handleGetTableData]
  );

  return (
    <>
      <Grid container item xs={12} md={10} spacing={3}>
        <BankAccountsHeader
          searchCode={searchCode}
          handleChangeSearchCode={handleChangeSearchCode}
          selectCurrency={selectCurrency}
          handleChangeSelectCurrency={handleChangeSelectCurrency}
          handleSearch={handleSearch}
          handleClickOpen={handleClickOpen}
        />
        <Grid item xs={12} className={classes.bankAccountsTableWrapper}>
          <Box py={2}>
            {loadingTable ? (
              <>
                <Skeleton width='100%' height={100} animation='wave' />
                <Skeleton width='100%' height={40} animation='wave' />
                <Skeleton width='100%' height={40} animation='wave' />
                <Skeleton width='100%' height={40} animation='wave' />
                <Skeleton width='100%' height={40} animation='wave' />
              </>
            ) : (
              <TableContainer>
                <Table className={classes.bankAccountsTable}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Banco:</TableCell>
                      <TableCell>N° de Cuenta:</TableCell>
                      <TableCell>Titular:</TableCell>
                      <TableCell>Moneda:</TableCell>
                      <TableCell align='center'>Eliminar Cuenta:</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.length !== 0 ? (
                      rows.map((row) => (
                        <TableRow key={row.number}>
                          <TableCell>{row.bankName}</TableCell>
                          <TableCell>{row.number}</TableCell>
                          <TableCell>{row.holdersName}</TableCell>
                          <TableCell>{CurrencyName[row.currency]}</TableCell>
                          <TableCell align='center'>
                            <IconButton
                              className={classes.bankAccountsTrashIcon}
                              onClick={() => handleClickOpenConfirm(row.id)}
                            >
                              <TrashCanIcon fontSize='small' />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <>
                        {!firstTime && (
                          <TableRow>
                            <TableCell
                              colSpan={8}
                              className={classes.emptyCell}
                            >
                              No tiene cuentas bancarias registradas
                            </TableCell>
                          </TableRow>
                        )}
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Box>
          <Pagination
            className={classes.bankAccountsPagination}
            disabled={loadingTable}
            count={pageCount}
            color='primary'
            size='small'
            page={page}
            onChange={handleChangePage}
          />
        </Grid>
      </Grid>
      <CreateAccountModal
        open={open}
        onClose={handleClose}
        onSuccess={handleSuccessCreateAccount}
      />
      <WarningDeleteModal
        openConfirm={openConfirm}
        loadingDelete={loadingDelete}
        handleCloseConfirm={handleCloseConfirm}
        handleCloseDelete={handleCloseDelete}
      />
    </>
  );
}
