import React from 'react';

import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function TrashCanIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox='0 0 17 19'>
      <g>
        <path
          d='M8.3,16.7c-1.5,0-2.9,0-4.4,0c-0.4,0-0.6-0.2-0.7-0.6c-0.1-0.8-0.2-1.7-0.2-2.5c-0.1-0.7-0.1-1.5-0.2-2.2
          c-0.1-0.8-0.2-1.7-0.2-2.5C2.5,8.1,2.4,7.4,2.4,6.7C2.3,6,2.2,5.3,2.2,4.6c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.3,0-0.4,0
          C1.2,4.4,1,4.1,1,3.8c0-0.3,0.3-0.6,0.6-0.6c1.2,0,2.4,0,3.6,0c0.2,0,0.2,0,0.2-0.2c0-0.4,0-0.8,0.3-1.2C6.1,1.3,6.5,1,7.1,1
          c0.7,0,1.5,0,2.2,0c1,0,1.7,0.7,1.7,1.7c0,0.1,0,0.3,0,0.4c0,0.1,0,0.2,0.2,0.2c0.3,0,0.6,0,0.9,0c0.9,0,1.9,0,2.8,0
          c0.2,0,0.4,0.1,0.5,0.3c0.1,0.2,0.1,0.4-0.1,0.6c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.3,0-0.4,0c-0.1,0-0.2,0-0.2,0.2
          c0,0.4-0.1,0.7-0.1,1.1c-0.1,0.7-0.1,1.5-0.2,2.2C14,8.5,14,9.1,13.9,9.7c-0.1,0.6-0.1,1.3-0.2,1.9c-0.1,0.6-0.1,1.2-0.2,1.8
          c-0.1,0.6-0.1,1.2-0.2,1.9c0,0.3-0.1,0.5-0.1,0.8c0,0.4-0.3,0.6-0.6,0.6C11.2,16.7,9.8,16.7,8.3,16.7z M8.3,4.4
          c-0.6,0-1.2,0-1.8,0c-1,0-2,0-3,0c-0.1,0-0.2,0-0.2,0.2c0,0.1,0,0.3,0,0.4c0.1,0.7,0.1,1.5,0.2,2.2C3.6,7.8,3.7,8.4,3.7,9
          c0.1,0.7,0.1,1.5,0.2,2.2C4,11.9,4,12.5,4.1,13.1c0.1,0.7,0.1,1.5,0.2,2.2c0,0.2,0,0.2,0.2,0.2c2.1,0,4.2,0,6.3,0
          c0.4,0,0.8,0,1.3,0c0.1,0,0.2,0,0.2-0.1c0.1-0.7,0.1-1.4,0.2-2.1c0.1-0.6,0.1-1.2,0.2-1.8c0.1-0.7,0.1-1.5,0.2-2.2
          c0.1-0.7,0.1-1.5,0.2-2.2c0.1-0.8,0.2-1.7,0.2-2.5c0-0.2,0-0.2-0.2-0.2C11.4,4.4,9.9,4.4,8.3,4.4z M8.3,3.2c0.5,0,1,0,1.5,0
          c0.1,0,0.2,0,0.2-0.2c0-0.1,0-0.3,0-0.4c0-0.3-0.2-0.5-0.5-0.5c-0.8,0-1.5,0-2.3,0c-0.3,0-0.5,0.2-0.5,0.5c0,0.1,0,0.3,0,0.4
          c0,0.1,0,0.2,0.2,0.2C7.3,3.2,7.8,3.2,8.3,3.2z'
        />
        <path
          d='M8.9,10c0,1.2,0,2.5,0,3.7c0,0.1,0,0.2,0,0.3c0,0.3-0.3,0.5-0.6,0.5c-0.3,0-0.5-0.2-0.6-0.5
          c0-0.1,0-0.2,0-0.3c0-2.5,0-5,0-7.5c0-0.1,0-0.2,0-0.3c0-0.3,0.3-0.5,0.6-0.5c0.3,0,0.5,0.2,0.6,0.5c0,0.1,0,0.2,0,0.3
          C8.9,7.5,8.9,8.7,8.9,10z'
        />
        <path
          d='M6.6,13.7c0,0.1,0,0.2,0,0.4c-0.1,0.2-0.3,0.4-0.6,0.4c-0.2,0-0.5-0.2-0.5-0.4c-0.1-0.2,0-0.3-0.1-0.5
          c-0.1-0.9-0.1-1.9-0.2-2.8C5.2,9.7,5.1,8.6,5,7.5c0-0.5-0.1-1-0.1-1.5c0-0.3,0.2-0.5,0.4-0.6C5.6,5.4,6,5.6,6,6
          c0,0.5,0.1,1.1,0.1,1.6c0,0.7,0.1,1.3,0.1,2c0,0.6,0.1,1.3,0.1,1.9c0,0.6,0.1,1.3,0.1,1.9C6.6,13.5,6.6,13.6,6.6,13.7z'
        />
        <path
          d='M9.9,13.7c0.1-0.8,0.1-1.7,0.2-2.5c0-0.6,0.1-1.3,0.1-1.9c0.1-0.8,0.1-1.6,0.2-2.3c0-0.3,0-0.6,0.1-0.9
          c0-0.3,0.3-0.6,0.6-0.5c0.3,0,0.6,0.3,0.6,0.6c0,0.4,0,0.7-0.1,1.1c0,0.5-0.1,1-0.1,1.5c-0.1,0.8-0.1,1.6-0.2,2.4
          c0,0.5-0.1,0.9-0.1,1.4s-0.1,0.9-0.1,1.4c0,0.3-0.2,0.5-0.5,0.6c-0.3,0-0.5-0.1-0.6-0.4C9.9,14,9.9,13.8,9.9,13.7z'
        />
      </g>
    </SvgIcon>
  );
}
