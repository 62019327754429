import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    box: {
      maxHeight: 'calc(100vh - 40px)',
      overflowY: 'scroll',
    },
    container: {
      '& h3': {
        fontWeight: theme.typography.fontWeightBold,
        marginBottom: '1rem',
      },
      '& p': {
        marginBottom: '1.5rem',
      },
      '& ul': {
        listStyle: 'disc',
        marginLeft: theme.spacing(3),
        marginBottom: '1.5rem',
      },
    },
  })
);
