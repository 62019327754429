import React from 'react';

import { Typography, Grid, Button } from '@material-ui/core';

import useStyles from '../styles';

interface Props {
  id: number;
  companyName: string;
  handleClickOpen: (id: number) => void;
}

const DiscountEntity = ({ id, companyName, handleClickOpen }: Props) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} md={4}>
      <Typography
        variant='h5'
        color='textPrimary'
        gutterBottom
        align='center'
        className={classes.modalTitle}
      >
        {companyName}
      </Typography>
      <Button
        onClick={() => handleClickOpen(id)}
        variant='contained'
        color='primary'
        className={classes.getDiscountsBtn}
      >
        Introducir Código
      </Button>
    </Grid>
  );
};

export default DiscountEntity;
