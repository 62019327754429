import React from 'react';
import { Box, Button, Container, Typography } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import { useSnackbar } from 'notistack';

import ClientType from 'common/clientType';
import { commonError } from 'common/errorMessages';
import { BaseModal } from 'components';
import { Yup } from 'utils';
import { rucValidator } from 'utils/validators';
import { parseError } from 'utils/api';
import { Field, Form, Formik } from 'formik';
import { Client } from 'context/Profile';

interface Props {
  open: boolean;
  onSubmit: (values: Partial<Client>) => Promise<any>;
  onClose: () => void;
}

const initialValues = {
  ruc: '',
  businessName: '',
};

const validationSchema = Yup.object({
  ruc: rucValidator,
  businessName: Yup.string().required().max(200),
});

export default function NewCorporateRole({ open, onSubmit, onClose }: Props) {
  const { enqueueSnackbar } = useSnackbar();

  return (
    <BaseModal open={open} onClose={onClose}>
      <Container maxWidth='sm'>
        <Box display='flex' flexDirection='column' alignItems='center' p={7}>
          <Typography
            variant='h5'
            color='secondary'
            display='inline'
            align='center'
            gutterBottom
          >
            Crea un perfil para
            <br />
            <Typography variant='inherit' component='span' color='primary'>
              tu empresa
            </Typography>
          </Typography>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (
              { ruc, businessName }: typeof initialValues,
              { setErrors }
            ) => {
              try {
                await onSubmit({
                  ruc,
                  businessName,
                  clientType: ClientType.Both,
                });
                // NOTE: setSubmitting is automatically set to false because this is an async function
                //  also the modal is closed because the component unmounts
                enqueueSnackbar('Nuevo rol creado exitósamente', {
                  variant: 'success',
                });
              } catch (e) {
                if (e?.response?.data) {
                  setErrors(parseError(e.response.data));
                } else {
                  enqueueSnackbar(commonError, { variant: 'error' });
                }
              }
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <Field
                  component={TextField}
                  name='businessName'
                  label='Ingresa tu Razón Social'
                  variant='outlined'
                  size='small'
                  margin='normal'
                  fullWidth
                />
                <Field
                  component={TextField}
                  name='ruc'
                  label='Ingresa tu RUC'
                  variant='outlined'
                  size='small'
                  margin='normal'
                  fullWidth
                />
                <Box display='block' mt={2} mx='auto' clone>
                  <Button
                    variant='contained'
                    color='primary'
                    type='submit'
                    disabled={isSubmitting}
                  >
                    Crear
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Container>
    </BaseModal>
  );
}
