import React, { useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import { Field, Form, FormikProps, withFormik } from 'formik';

import { Yup } from 'utils';
import {
  nonRequiredPasswordConfirmValidator,
  rucValidator,
} from 'utils/validators';
import { parseError } from 'utils/api';

import useStyles from './styles';
import PasswordAdornment from '../../../Register/components/PasswordAdornment';

interface FormValues {
  clientType: string;
  businessName: string;
  ruc: string;
  email: string;
  phone: string;
  password?: string | null;
  passwordConfirm?: string | null;
}

interface Props {
  initialValues: FormValues | null;
  onSubmit: (v: any) => Promise<any>;
  onError: () => void;
}

const validationSchema = Yup.object({
  businessName: Yup.string().required(),
  ruc: rucValidator,
  email: Yup.string().required().email(),
  phone: Yup.string(),
  password: Yup.string().nullable().max(128),
  passwordConfirm: nonRequiredPasswordConfirmValidator,
});

function CompanyEditForm({ isSubmitting }: FormikProps<FormValues>) {
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const classes = useStyles();

  const handleClickShowPassword = () => {
    setShowPassword((v) => !v);
  };

  const handleClickShowPasswordConfirm = () => {
    setShowPasswordConfirm((v) => !v);
  };

  return (
    <Grid container component={Form} spacing={2} className={classes.form}>
      <Grid item xs={12} md={6}>
        <Field
          component={TextField}
          name='businessName'
          autoComplete='organization'
          label='Razón social'
          variant='outlined'
          size='small'
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Field
          component={TextField}
          name='ruc'
          label='RUC'
          variant='outlined'
          size='small'
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Field
          component={TextField}
          name='email'
          autoComplete='email'
          type='email'
          label='Email'
          variant='outlined'
          size='small'
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Field
          component={TextField}
          name='phone'
          autoComplete='tel'
          type='tel'
          label='Teléfono'
          variant='outlined'
          size='small'
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Field
          component={TextField}
          name='password'
          type={showPassword ? 'text' : 'password'}
          label='Nueva contraseña'
          variant='outlined'
          size='small'
          autoComplete='new-password'
          fullWidth
          InputProps={{
            endAdornment: (
              <PasswordAdornment
                onClick={handleClickShowPassword}
                show={showPassword}
              />
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Field
          component={TextField}
          name='passwordConfirm'
          type={showPasswordConfirm ? 'text' : 'password'}
          label='Confirmar nueva contraseña'
          variant='outlined'
          size='small'
          autoComplete='new-password'
          fullWidth
          InputProps={{
            endAdornment: (
              <PasswordAdornment
                onClick={handleClickShowPasswordConfirm}
                show={showPasswordConfirm}
              />
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} className={classes.profileActions}>
        <Button
          type='submit'
          variant='contained'
          color='primary'
          className={classes.profileFormBtn}
          disabled={isSubmitting}
        >
          Actualizar Información
        </Button>
      </Grid>
    </Grid>
  );
}

export default withFormik<Props, FormValues>({
  displayName: 'CompanyEditForm',
  mapPropsToValues: ({ initialValues }) => {
    if (!initialValues) {
      return {
        clientType: '',
        businessName: '',
        ruc: '',
        email: '',
        phone: '',
        password: '',
        passwordConfirm: '',
      };
    }

    const { clientType, businessName, ruc, email, phone } = initialValues;
    return {
      clientType,
      businessName,
      ruc,
      email,
      phone,
      password: '',
      passwordConfirm: '',
    };
  },
  handleSubmit: async (values, { setSubmitting, setErrors, props }) => {
    try {
      await props.onSubmit({ ...values, passwordConfirm: null });
    } catch (e) {
      if (e?.response?.data) {
        setErrors(parseError(e.response.data));
      } else {
        props.onError();
      }
    } finally {
      setSubmitting(false);
    }
  },

  validationSchema,
  enableReinitialize: true,
})(CompanyEditForm);
