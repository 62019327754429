import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';

import { Currency, formatMoney } from 'common/money';

import useStyles from './styles';

const cardDefaultProps = {
  smallDecimals: false,
};

type CardProps = {
  title: string;
  value: string;
} & typeof cardDefaultProps;

function Card({ title, value, smallDecimals }: CardProps) {
  const classes = useStyles();

  let splitValue = ['', ''];
  if (smallDecimals) {
    const split = value.split('.');
    const last = split.length - 1;
    splitValue = [split.slice(0, last).join('.'), split[last]];
  }

  return (
    <Grid item xs={6} md={3}>
      <Box py={1.5} className={classes.card}>
        <Typography variant='h6' noWrap>
          {title}
        </Typography>
        {!smallDecimals ? (
          <Typography variant='body1' noWrap>
            {value}
          </Typography>
        ) : (
          <Typography variant='body1' noWrap>
            {splitValue[0]}.<span>{splitValue[1]}</span>
          </Typography>
        )}
      </Box>
    </Grid>
  );
}

Card.defaultProps = cardDefaultProps;

type ExchangeInfoProps = {
  sendCurrency: Currency;
  sendValue: string;
  receiveCurrency: Currency;
  receiveValue: string;
  exchange: string;
};

export default function ExchangeInfo({
  sendCurrency,
  sendValue,
  receiveCurrency,
  receiveValue,
  exchange,
}: ExchangeInfoProps) {
  const infoString =
    sendCurrency === Currency.PEN ? 'Soles a dólares' : 'Dólares a soles';
  const sendString = formatMoney(sendCurrency, sendValue);
  const receiveString = formatMoney(receiveCurrency, receiveValue);
  const exchangeString = Number(exchange).toLocaleString('en', {
    maximumFractionDigits: 3,
    minimumFractionDigits: 3,
  });

  return (
    <Grid container spacing={2}>
      <Card title='Cambio' value={infoString} />
      <Card title='Envías' value={sendString} smallDecimals />
      <Card title='Recibes' value={receiveString} smallDecimals />
      <Card title='Tipo de cambio' value={exchangeString} />
    </Grid>
  );
}
