/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useState,
  useCallback,
  memo,
  useEffect,
  ChangeEvent,
  KeyboardEvent,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faTimesCircle,
  faMinusCircle,
} from '@fortawesome/free-solid-svg-icons';
import { format, differenceInMinutes, addMinutes } from 'date-fns';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';

import { withStyles, Theme } from '@material-ui/core/styles';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  IconButton,
} from '@material-ui/core';
import { Pagination, Skeleton } from '@material-ui/lab';

import { TrashCanIcon } from 'components/CustomIcons';
import { api } from 'utils';
import { formatMoney } from 'common/money';
import { commonError } from 'common/errorMessages';

import useStyles from '../styles';
import Status from '../enums';
import Transaction from '../interfaces';
import WarningDeleteOrder from './WarningDeleteOrder';
import ReportOperationModal from './ReportOperationModal';
import ProfileHistoryHeader from './ProfileHistoryHeader';

const KambiazoTableHeaderRow = withStyles((theme: Theme) => ({
  root: {
    border: 'none',
    borderBottom: `3px solid ${theme.palette.secondary.main}`,
    whiteSpace: 'nowrap',
  },
}))(TableRow);

const KambiazoTableRow = withStyles((theme: Theme) => ({
  root: {
    border: 'none',
    '&:not(:last-child)': {
      borderBottom: `2px solid ${theme.palette.grey[300]}`,
    },
  },
}))(TableRow);

const KambiazoHeaderCell = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.secondary.main,
    fontSize: '1rem',
    fontWeight: 'bold',
  },
}))(TableCell);

const KambiazoTableCell = withStyles(() => ({
  root: {
    border: 'none',
  },
}))(TableCell);

const PAGE_SIZE = 5;

const ProfileHistoryTable = memo(() => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openReport, setOpenReport] = useState(false);
  const [loadingReport, setLoadingReport] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingTable, setLoadingTable] = useState(true);
  const [indexWarning, setIndexWarning] = useState<null | number>(null);
  const [reportOperation, setReportOperation] = useState<Transaction | null>(
    null
  );
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [firstTime, setFirstTime] = useState(true);
  const [rows, setRows] = useState<Transaction[]>([]);
  const [searchCode, setSearchCode] = useState('');
  const [selectState, setSelectState] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleGetTableData = useCallback(async () => {
    setLoadingTable(true);
    if (firstTime) {
      setRows([]);
    }
    try {
      const {
        data: { results, count },
      } = await api.get(`clients/exchange/`, {
        params: {
          page,
          page_size: PAGE_SIZE,
          state: selectState,
          search: searchCode,
        },
      });
      if (firstTime) {
        setFirstTime(false);
      }
      setRows(results);
      setPageCount(Math.ceil(count / PAGE_SIZE));
      setLoadingTable(false);
    } catch (error) {
      enqueueSnackbar(commonError, { variant: 'error' });
      setLoadingTable(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, selectState, firstTime]);

  useEffect(() => {
    handleGetTableData();
  }, [page, handleGetTableData]);

  const handleChangePage = useCallback(
    (_: ChangeEvent<unknown>, value: number) => {
      setPage(value);
    },
    [setPage]
  );

  const handleChangeSelectState = useCallback(
    ({ target: { value } }: ChangeEvent<{ value: unknown }>) => {
      setSelectState(value as string);
    },
    [setSelectState]
  );

  const handleChangeSearchCode = useCallback(
    ({ target: { value } }: ChangeEvent<{ value: unknown }>) => {
      setSearchCode(value as string);
    },
    [setSearchCode]
  );

  const handleSearch = useCallback(
    async (e: KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'Enter') {
        setLoadingTable(true);
        try {
          const {
            data: { results, count },
          } = await api.get(`clients/exchange/`, {
            params: {
              page: 1,
              page_size: PAGE_SIZE,
              search: searchCode,
              state: selectState,
            },
          });
          setRows(results);
          setPageCount(Math.ceil(count / 5));
          setPage(1);
          setLoadingTable(false);
        } catch (error) {
          enqueueSnackbar(commonError, { variant: 'error' });
          setLoadingTable(false);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchCode, selectState]
  );

  const openWarning = useCallback(
    (index: number) => {
      setOpen(true);
      setIndexWarning(index);
    },
    [setOpen, setIndexWarning]
  );

  const closeWarning = useCallback(() => {
    setOpen(false);
    setIndexWarning(null);
  }, [setOpen, setIndexWarning]);

  const deleteOrder = useCallback(async () => {
    setLoadingDelete(true);
    try {
      await api.patch(`clients/exchange/hide-exchange/`, {
        id: indexWarning,
      });
      setIndexWarning(null);
      setOpen(false);
      setLoadingDelete(false);
      handleGetTableData();
    } catch (error) {
      enqueueSnackbar(commonError, { variant: 'error' });
      setLoadingDelete(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    setLoadingDelete,
    setIndexWarning,
    setOpen,
    indexWarning,
    handleGetTableData,
  ]);

  const openReportOperation = useCallback(
    (transaction: Transaction) => {
      setOpenReport(true);
      setReportOperation(transaction);
    },
    [setOpenReport]
  );

  const closeReportOperation = useCallback(() => {
    setOpenReport(false);
    setReportOperation(null);
  }, [setOpenReport, setReportOperation]);

  const handleReportOperation = useCallback(
    async (transactionNumber) => {
      setLoadingReport(true);
      try {
        await api.patch(`clients/exchange/save-transaction-code/`, {
          token: reportOperation?.token,
          transactionNumber,
        });
        setReportOperation(null);
        setOpenReport(false);
        setLoadingReport(false);
        handleGetTableData();
      } catch (error) {
        enqueueSnackbar(commonError, { variant: 'error' });
        setLoadingReport(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      setLoadingReport,
      setReportOperation,
      setOpenReport,
      reportOperation,
      enqueueSnackbar,
    ]
  );

  const getStatusIcon = useCallback((status) => {
    switch (status) {
      case Status.Pedido:
        return (
          <FontAwesomeIcon
            icon={faTimesCircle}
            color='#cc2029'
            className={classes.iconSize}
          />
        );
      case Status.Validado:
      case Status.Espera:
        return (
          <FontAwesomeIcon
            icon={faMinusCircle}
            color='#f9e10f'
            className={classes.iconSize}
          />
        );
      case Status.Terminado:
        return (
          <FontAwesomeIcon
            icon={faCheckCircle}
            color='#43a448'
            className={classes.iconSize}
          />
        );
      default:
        return null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showReportButton = (row: Transaction) => {
    if (row.state === Status.Pedido) {
      if (
        differenceInMinutes(addMinutes(new Date(row.date), 20), new Date()) > 0
      )
        return true;
    }
    return false;
  };

  return (
    <>
      <ProfileHistoryHeader
        searchCode={searchCode}
        handleChangeSearchCode={handleChangeSearchCode}
        selectState={selectState}
        handleChangeSelectState={handleChangeSelectState}
        handleSearch={handleSearch}
      />
      <WarningDeleteOrder
        open={open}
        handleCloseDeleteOrderModal={deleteOrder}
        handleCloseModal={closeWarning}
        loadingDelete={loadingDelete}
      />
      <ReportOperationModal
        open={openReport}
        reportOperation={reportOperation as Transaction}
        handleCloseReportOperationModal={handleReportOperation}
        handleCloseModal={closeReportOperation}
        loadingReport={loadingReport}
      />
      <Grid container spacing={3} className={classes.tableWrapper}>
        <Grid item xs={12}>
          {loadingTable ? (
            <>
              <Skeleton width='100%' height={100} animation='wave' />
              <Skeleton width='100%' height={40} animation='wave' />
              <Skeleton width='100%' height={40} animation='wave' />
              <Skeleton width='100%' height={40} animation='wave' />
              <Skeleton width='100%' height={40} animation='wave' />
            </>
          ) : (
            <TableContainer
              className={clsx(
                classes.tableContainer,
                firstTime && classes.minLoadingContainer,
                loadingTable &&
                  (rows.length === 0 || rows.length === 1) &&
                  classes.overflowVisible
              )}
            >
              <Table className={classes.transactionTable}>
                <TableHead>
                  <KambiazoTableHeaderRow>
                    <KambiazoHeaderCell align='left'>Orden:</KambiazoHeaderCell>
                    <KambiazoHeaderCell align='left'>
                      Fecha y hora:
                    </KambiazoHeaderCell>
                    <KambiazoHeaderCell align='left'>Envías</KambiazoHeaderCell>
                    <KambiazoHeaderCell align='left'>
                      Recíbes:
                    </KambiazoHeaderCell>
                    <KambiazoHeaderCell align='left'>
                      Tipo de cambio:
                    </KambiazoHeaderCell>
                    <KambiazoHeaderCell align='left'>
                      Estado:
                    </KambiazoHeaderCell>
                    <KambiazoHeaderCell />
                    <KambiazoHeaderCell />
                  </KambiazoTableHeaderRow>
                </TableHead>
                <TableBody>
                  {rows.length !== 0 ? (
                    rows.map((row) => (
                      <KambiazoTableRow key={`${row.id}`}>
                        <KambiazoTableCell align='left'>
                          {row.token}
                        </KambiazoTableCell>
                        <KambiazoTableCell align='left'>
                          {format(new Date(row.date), "dd/MM/yyyy H:mm'hrs'")}
                        </KambiazoTableCell>
                        <KambiazoTableCell align='left'>
                          <p>
                            {formatMoney(
                              row.fromAdminAccount.currency,
                              row.amount
                            )}
                          </p>
                          <p className={classes.bankAccountNumber}>
                            N°{row.fromAdminAccount.number}
                          </p>
                          <p>{row.fromAdminAccount.bankName}</p>
                        </KambiazoTableCell>
                        <KambiazoTableCell align='left'>
                          <p>
                            {formatMoney(
                              row.depotAccountCurrency,
                              row.changedAmount
                            )}
                          </p>
                          <p>{row.fromBankName}</p>
                        </KambiazoTableCell>
                        <KambiazoTableCell align='left'>
                          {row.rate}
                        </KambiazoTableCell>
                        <KambiazoTableCell align='center'>
                          {getStatusIcon(row.state)}
                        </KambiazoTableCell>
                        <KambiazoTableCell align='center'>
                          {showReportButton(row) ? (
                            <Button
                              className={classes.reportButton}
                              size='small'
                              variant='contained'
                              color='primary'
                              onClick={() => openReportOperation(row)}
                            >
                              Reportar operación
                            </Button>
                          ) : null}
                        </KambiazoTableCell>
                        <KambiazoTableCell align='center'>
                          <IconButton
                            className={classes.trashIcon}
                            onClick={() => openWarning(row.id)}
                          >
                            <TrashCanIcon fontSize='small' />
                          </IconButton>
                        </KambiazoTableCell>
                      </KambiazoTableRow>
                    ))
                  ) : (
                    <>
                      {!firstTime && (
                        <KambiazoTableRow>
                          <TableCell colSpan={8} className={classes.emptyCell}>
                            No tiene registrado transacciones
                          </TableCell>
                        </KambiazoTableRow>
                      )}
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>
      </Grid>
      <Grid
        container
        justify='flex-end'
        className={classes.paginationContainer}
      >
        <Grid item>
          <Pagination
            className={classes.historyPagination}
            disabled={loadingTable}
            count={pageCount}
            color='primary'
            size='small'
            page={page}
            onChange={handleChangePage}
          />
        </Grid>
      </Grid>
    </>
  );
});

export default ProfileHistoryTable;
