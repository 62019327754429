import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import whiteBackground from '../../assets/images/common/curly-white-background.png';

export default makeStyles((theme: Theme) =>
  createStyles({
    dashboardWrapper: {
      display: 'flex',
    },
    dashboardContent: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '100vh',
      backgroundColor: theme.palette.background.default,
      background: `url(${whiteBackground})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100% 100%',

      [theme.breakpoints.up('md')]: {
        maxWidth: `calc(100vw - ${theme.custom.dashboardDrawer.width}px)`,
      },

      [theme.breakpoints.down('sm')]: {
        backgroundSize: 'cover',
      },
    },
    dashboardDrawerHeader: {
      ...theme.mixins.toolbar,
      marginTop: theme.spacing(2),
    },
  })
);
