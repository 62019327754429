import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    registerText: {
      '& span': {
        color: theme.palette.secondary.main,
        fontWeight: 'bold',
      },
    },
    registerBtnWrapper: {
      display: 'flex',
      justifyContent: 'center',
    },
    registerBtn: {
      textTransform: 'capitalize',
      '&:first-child': {
        marginRight: theme.spacing(2),
      },
    },
  })
);
