import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    footer: {
      color: theme.palette.common.white,
      background: theme.palette.secondary.main,
      minHeight: 320,
      paddingTop: 80,

      [theme.breakpoints.down('md')]: {
        paddingBottom: 40,
      },

      [theme.breakpoints.down('xs')]: {
        textAlign: 'center',
        paddingTop: theme.spacing(3),
      },

      '& a': {
        cursor: 'pointer',
      },
    },
    contactsUs: {
      '& img': {
        width: 32,
        height: 32,
        marginRight: theme.spacing(1),
      },
      marginBottom: theme.spacing(2),
    },
    contactUsItem: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(1),

      [theme.breakpoints.down('xs')]: {
        justifyContent: 'center',
      },
    },
    socialBtn: {
      background: theme.palette.primary.main,
      width: 42,
      height: 42,
      '&:hover': {
        background: theme.palette.primary.dark,
      },
      '&:not(:last-child)': {
        marginRight: 12,
      },
    },
    footerLink: {
      display: 'block',
      textDecoration: 'none',
    },
    footerMaxContent: {
      display: 'block',
      textDecoration: 'none',
      minWidth: 'max-content',
    },
    footerBigImg: {
      minHeight: 320,
      position: 'relative',
      '& img': {
        position: 'absolute',
        bottom: 0,
      },
    },
    footerLogoImg: {
      display: 'block',
      margin: '0 auto',
    },
  })
);
