import React from 'react';
import { Box, Grid, Hidden, IconButton, Typography } from '@material-ui/core';
import Carousel from '@brainhubeu/react-carousel';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';

import phone from 'assets/images/home/phone.png';
import report from 'assets/images/home/report.png';
import transfer from 'assets/images/home/transfer.png';
import receive from 'assets/images/home/receive.png';
import { sectionsIds } from 'components/Navbar';

import useStyles from './styles';

const data = [
  {
    id: 1,
    title: 'Regístrate',
    content: `
    Regístrate como personal natural y/o jurídica y crea tu espacio
     de trabajo para gestionar tus operaciones.
    `,
    image: phone,
    alt: 'regístrate',
  },

  {
    id: 2,
    title: 'Cotiza',
    content: `
    Cotiza el mejor tipo de cambio del mercado. Mejora tu cotización
     usando cupones de descuento.
    `,
    image: report,
    alt: 'cotiza',
  },

  {
    id: 3,
    title: 'Transfiere',
    content: `
    Desde cualquier dispositivo o lugar. Ingresa el número o adjunta
     una imagen de tu transferencia. No recibimos abonos en efectivo.
    `,
    image: transfer,
    alt: 'transfiere',
  },

  {
    id: 4,
    title: 'Recibe',
    content: `
    Recibe el monto negociado en tu cuenta bancaria registrada.
    `,
    image: receive,
    alt: 'recibe',
  },
];

function Accordion() {
  const classes = useStyles();

  return (
    <div className={classes.container} id={sectionsIds.howItWorks}>
      <div>
        <Typography variant='h4' color='inherit' className={classes.title}>
          ¿Cómo{' '}
          <Typography variant='inherit' color='primary' component='span'>
            funciona?
          </Typography>
        </Typography>

        <Typography variant='h6' className={classes.subtitle}>
          En tan solo cuatro (04) pasos, compra/vende dólares en el entorno
          seguro y confiable que tú y tu dinero merecen. No cobramos comisiones.
        </Typography>
      </div>
      <Hidden mdDown>
        <div className={classes.accordion}>
          <ul>
            {data.map((val) => (
              <li key={val.id}>
                <span>{val.id}</span>
                <div>
                  <img src={val.image} alt={val.alt} />
                  <Typography
                    variant='h4'
                    color='inherit'
                    className={classes.title}
                  >
                    {val.title}
                  </Typography>
                  <Typography
                    variant='body1'
                    color='inherit'
                    className={classes.accordionText}
                  >
                    {val.content}
                  </Typography>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </Hidden>
      <Hidden xsDown lgUp>
        <Grid
          container
          justify='center'
          spacing={5}
          component='ul'
          className={classes.childColors}
        >
          {data.map((val) => (
            <Grid key={val.id} item xs={6} component='li'>
              <div className={classes.flexItem}>
                <img src={val.image} alt={val.alt} />
                <Typography
                  variant='h4'
                  color='inherit'
                  className={classes.title}
                >
                  {val.id}. {val.title}
                </Typography>
                <Typography variant='body1' color='inherit'>
                  {val.content}
                </Typography>
              </div>
            </Grid>
          ))}
        </Grid>
      </Hidden>

      <Hidden smUp>
        <Box className={classes.childColors} clone>
          <Carousel
            autoPlay={4000}
            infinite
            stopAutoPlayOnHover
            addArrowClickHandler
            draggable={false}
            arrowLeft={
              <IconButton color='inherit' className={classes.navIcons}>
                <FontAwesomeIcon icon={faChevronLeft} />
              </IconButton>
            }
            arrowRight={
              <IconButton color='inherit' className={classes.navIcons}>
                <FontAwesomeIcon icon={faChevronRight} />
              </IconButton>
            }
          >
            {data.map((val) => (
              <div
                key={val.id}
                className={clsx(classes.flexItem, classes.borders)}
              >
                <img src={val.image} alt={val.alt} />
                <Typography
                  variant='h4'
                  color='inherit'
                  className={classes.title}
                >
                  {val.id}. {val.title}
                </Typography>
                <Typography variant='body1' color='inherit'>
                  {val.content}
                </Typography>
              </div>
            ))}
          </Carousel>
        </Box>
      </Hidden>
    </div>
  );
}

export default Accordion;
