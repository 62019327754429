import React from 'react';

import { Box, Button, Grid, Link, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUserCircle,
  faBriefcase,
  faCircle,
} from '@fortawesome/free-solid-svg-icons';
import { Link as RouteLink } from 'react-router-dom';

import { globalSecondaryColor } from 'theme/mainTheme';

import useStyles from './styles';

interface StartViewProps {
  handleIsNaturalPerson: (value: boolean) => void;
}

export default function StartView({ handleIsNaturalPerson }: StartViewProps) {
  const classes = useStyles();

  return (
    <Grid container justify='center'>
      <Grid item>
        <Box pb={3}>
          <Typography align='center' gutterBottom>
            Crear una cuenta como:
          </Typography>
        </Box>
        <Button
          fullWidth
          color='secondary'
          variant='contained'
          startIcon={<FontAwesomeIcon icon={faUserCircle} />}
          className={classes.registerBtn}
          onClick={() => handleIsNaturalPerson(true)}
        >
          Persona
        </Button>
        <Button
          fullWidth
          color='secondary'
          variant='contained'
          className={classes.registerBtn}
          onClick={() => handleIsNaturalPerson(false)}
          startIcon={
            <span className='fa-layers fa-fw'>
              <FontAwesomeIcon icon={faCircle} />
              <FontAwesomeIcon
                icon={faBriefcase}
                color={globalSecondaryColor}
                transform='shrink-6'
              />
            </span>
          }
        >
          Empresa
        </Button>
        <Typography align='center'>
          Si ya tienes cuenta,
          <br /> inicia sesion{' '}
          <Link
            color='primary'
            underline='always'
            component={RouteLink}
            to='/login'
            className={classes.link}
          >
            AQUÍ
          </Link>
        </Typography>
      </Grid>
    </Grid>
  );
}
