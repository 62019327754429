import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    alliances: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: theme.palette.common.white,
      alignItems: 'center',
      textAlign: 'center',
    },
    title: {
      fontSize: '2.5rem',
      marginBottom: theme.spacing(6),

      [theme.breakpoints.down('lg')]: {
        fontSize: '2.25rem',
      },
    },
    alliancesLogos: {
      '&  img': {
        maxHeight: 90,
        maxWidth: '100%',
      },
    },
  })
);
