import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    subtitle: {
      color: '#606060',
      fontWeight: theme.typography.fontWeightRegular,
      marginBottom: theme.spacing(3),
    },
    couponText: {
      fontSize: '1.125rem',
      color: '#606060',
      margin: theme.spacing(2, 0),
    },
  })
);
