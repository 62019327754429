import React, { useCallback } from 'react';
import XRegExp from 'xregexp';
import { fieldToTextField, TextFieldProps } from 'formik-material-ui';
import { TextField as MuiTextField } from '@material-ui/core';

const unicodeWord = XRegExp('^[\\pL ]*$');

function OnlyNumbers(props: TextFieldProps) {
  const {
    form: { setFieldValue },
    field: { name },
  } = props;
  const onChange = useCallback(
    (event) => {
      const { value } = event.target;
      if (/^\d*$/.test(value)) {
        setFieldValue(name, value);
      }
    },
    [setFieldValue, name]
  );
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <MuiTextField {...fieldToTextField(props)} onChange={onChange} />;
}

function LettersUpperCase(props: TextFieldProps) {
  const {
    form: { setFieldValue },
    field: { name },
  } = props;
  const onChange = React.useCallback(
    (event) => {
      const { value } = event.target;
      if (unicodeWord.test(value)) {
        setFieldValue(name, value ? value.toUpperCase() : '');
      }
    },
    [setFieldValue, name]
  );
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <MuiTextField {...fieldToTextField(props)} onChange={onChange} />;
}

function AlphaNumericUpperCase(props: TextFieldProps) {
  const {
    form: { setFieldValue },
    field: { name },
  } = props;
  const onChange = React.useCallback(
    (event) => {
      const { value } = event.target;
      if (/^\w*$/.test(value)) {
        setFieldValue(name, value ? value.toUpperCase() : '');
      }
    },
    [setFieldValue, name]
  );
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <MuiTextField {...fieldToTextField(props)} onChange={onChange} />;
}

export default {
  OnlyNumbers,
  LettersUpperCase,
  AlphaNumericUpperCase,
};
