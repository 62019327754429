import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    textWithDecorations: {
      color: '#606060',
      '& > strong': {
        color: theme.palette.primary.main,
      },
    },
    button: {
      marginTop: theme.spacing(3),
      textTransform: 'none',
      fontWeight: 'normal',
    },
  })
);
