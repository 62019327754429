import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  Grid,
  TextField as MuiTextField,
} from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import { Autocomplete } from 'formik-material-ui-lab';
import { Field, Form, FormikProps, withFormik } from 'formik';
import { debounce } from 'lodash';
import { useSnackbar } from 'notistack';

import { api, Yup } from 'utils';
import {
  dniValidator,
  nonRequiredPasswordConfirmValidator,
} from 'utils/validators';
import { parseError } from 'utils/api';
import { commonError } from 'common/errorMessages';

import useStyles from './styles';
import PasswordAdornment from '../../../Register/components/PasswordAdornment';

function AutocompleteOccupationField() {
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState<FetchData[]>([]);
  const { enqueueSnackbar } = useSnackbar();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchInput = useCallback(debounce(setSearch, 500), [setSearch]);

  useEffect(() => {
    if (!search) {
      setLoading(false);
      return;
    }

    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await api.get('clients/occupations/', {
          params: { search },
        });
        setData(response.data.results);
      } catch (e) {
        enqueueSnackbar(commonError, { variant: 'error' });
      } finally {
        setLoading(false);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <Field
      component={Autocomplete}
      name='occupation'
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onInputChange={(e: any, newValue: string, reason: string) => {
        if (reason === 'input') {
          setLoading(true);
          handleSearchInput(newValue);
        }
      }}
      getOptionSelected={(option: FetchData, value: FetchData) =>
        option?.id === value.id
      }
      getOptionLabel={(option: FetchData) => option?.name || ''}
      options={data}
      loading={loading}
      noOptionsText={
        search ? 'No se encontraron resultados' : 'Realice una búsqueda'
      }
      loadingText='Cargando...'
      renderInput={(params: any) => (
        <MuiTextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          label='Ocupación actual'
          variant='outlined'
          size='small'
          fullWidth
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color='inherit' size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}

interface FormValues {
  clientType: string;
  name: string;
  fathersLastName: string;
  mothersLastName: string;
  email: string;
  phone: string;
  dni: string;
  occupation: { id: number } | null;
  password?: string | null;
  passwordConfirm?: string | null;
}

type InitialValues = Omit<FormValues, 'occupation'> & {
  occupation: number | null;
  occupationName: string | null;
};

interface FetchData {
  id: number;
  name: string;
}

interface Props {
  initialValues: InitialValues | null;
  onSubmit: (v: any) => Promise<any>;
  onError: () => void;
}

const validationSchema = Yup.object({
  name: Yup.string().required(),
  fathersLastName: Yup.string(),
  mothersLastName: Yup.string(),
  email: Yup.string().required().email(),
  phone: Yup.string(),
  dni: dniValidator,
  occupation: Yup.object().nullable(),
  password: Yup.string().nullable().max(128),
  passwordConfirm: nonRequiredPasswordConfirmValidator,
});

function PersonEditForm({ isSubmitting }: FormikProps<FormValues>) {
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const classes = useStyles();

  const handleClickShowPassword = () => {
    setShowPassword((v) => !v);
  };

  const handleClickShowPasswordConfirm = () => {
    setShowPasswordConfirm((v) => !v);
  };

  return (
    <Grid container component={Form} spacing={2} className={classes.form}>
      <Grid item xs={12} md={6}>
        <Field
          component={TextField}
          name='name'
          autoComplete='given-name'
          label='Nombre'
          variant='outlined'
          size='small'
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Field
          component={TextField}
          name='fathersLastName'
          autoComplete='family-name'
          label='Apellido Paterno'
          variant='outlined'
          size='small'
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Field
          component={TextField}
          name='mothersLastName'
          autoComplete='family-name'
          label='Apellido Materno'
          variant='outlined'
          size='small'
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Field
          component={TextField}
          name='email'
          autoComplete='email'
          type='email'
          label='Email'
          variant='outlined'
          size='small'
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Field
          component={TextField}
          name='phone'
          autoComplete='tel'
          type='tel'
          label='Teléfono'
          variant='outlined'
          size='small'
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Field
          component={TextField}
          name='dni'
          label='DNI'
          variant='outlined'
          size='small'
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Field
          component={TextField}
          name='password'
          type={showPassword ? 'text' : 'password'}
          label='Nueva contraseña'
          variant='outlined'
          size='small'
          autoComplete='new-password'
          fullWidth
          InputProps={{
            endAdornment: (
              <PasswordAdornment
                onClick={handleClickShowPassword}
                show={showPassword}
              />
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Field
          component={TextField}
          name='passwordConfirm'
          type={showPasswordConfirm ? 'text' : 'password'}
          label='Confirmar nueva contraseña'
          variant='outlined'
          size='small'
          autoComplete='new-password'
          fullWidth
          InputProps={{
            endAdornment: (
              <PasswordAdornment
                onClick={handleClickShowPasswordConfirm}
                show={showPasswordConfirm}
              />
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <AutocompleteOccupationField />
      </Grid>
      <Grid item xs={12} className={classes.profileActions}>
        <Button
          type='submit'
          variant='contained'
          color='primary'
          className={classes.profileFormBtn}
          disabled={isSubmitting}
        >
          Actualizar Información
        </Button>
      </Grid>
    </Grid>
  );
}

export default withFormik<Props, FormValues>({
  displayName: 'PersonEditForm',
  mapPropsToValues: ({ initialValues }) => {
    if (!initialValues) {
      return {
        clientType: '',
        name: '',
        fathersLastName: '',
        mothersLastName: '',
        email: '',
        phone: '',
        dni: '',
        occupation: null,
        password: '',
        passwordConfirm: '',
      };
    }

    const {
      name,
      fathersLastName,
      mothersLastName,
      email,
      phone,
      dni,
      occupation,
      occupationName,
      clientType,
    } = initialValues;
    return {
      clientType,
      name,
      fathersLastName,
      mothersLastName,
      email,
      phone,
      dni,
      occupation: { id: occupation as number, name: occupationName as string },
      password: '',
      passwordConfirm: '',
    };
  },
  handleSubmit: async (values, { setSubmitting, setErrors, props }) => {
    try {
      const { occupation } = values;
      const newValues = {
        ...values,
        occupation: occupation?.id,
        passwordConfirm: null,
      };
      await props.onSubmit(newValues);
    } catch (e) {
      if (e?.response?.data) {
        setErrors(parseError(e.response.data));
      } else {
        props.onError();
      }
    } finally {
      setSubmitting(false);
    }
  },
  validationSchema,
  enableReinitialize: true,
})(PersonEditForm);
