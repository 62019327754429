import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Hidden } from '@material-ui/core';

import { BankAccounts, ProfileEdit, SideNav, GetDiscounts } from './components';
import useStyles from './styles';

import Exchange from '../Exchange';
import NotFound from '../NotFound';
import ProfileHistory from '../ProfileHistory';

import {
  bankAccountsRoute,
  exchangeRoute,
  confirmExchangeRoute,
  mainRoute,
  profileRoute,
  getDiscountsRoute,
  operationsRoute,
} from './routes';

export default function Dashboard() {
  const classes = useStyles();

  return (
    <div className={classes.dashboardWrapper}>
      <SideNav />
      <main className={classes.dashboardContent}>
        <Hidden mdUp implementation='css'>
          <div className={classes.dashboardDrawerHeader} />
        </Hidden>
        <Switch>
          <Redirect from={mainRoute} exact to={profileRoute} />
          <Route path={profileRoute} exact component={ProfileEdit} />
          <Route
            path={[exchangeRoute, confirmExchangeRoute]}
            exact
            component={Exchange}
          />
          <Route path={operationsRoute} exact component={ProfileHistory} />
          <Route path={bankAccountsRoute} exact component={BankAccounts} />
          <Route path={getDiscountsRoute} exact component={GetDiscounts} />
          <Route component={NotFound} />
        </Switch>
      </main>
    </div>
  );
}
