import React, { useEffect, useState } from 'react';

import {
  Box,
  CircularProgress,
  Container,
  Step,
  StepLabel,
  Stepper,
} from '@material-ui/core';
import { useParams } from 'react-router-dom';

import { ErrorMessage } from 'components';
import { useExchangeContext } from 'context/Exchange';

import useStyles from './styles';

import {
  ExchangeStep,
  LastStep,
  ReviewStep,
  SelectAccountsStep,
} from './components';

function getStepContent(
  stepIndex: number,
  handleBack: () => void,
  handleNext: () => void,
  StepperComponent: React.ComponentType<any>
) {
  switch (stepIndex) {
    case 0:
      return (
        <ExchangeStep onPressNext={handleNext}>
          <StepperComponent />
        </ExchangeStep>
      );
    case 1:
      return (
        <SelectAccountsStep onPressNext={handleNext} onPressPrev={handleBack}>
          <StepperComponent />
        </SelectAccountsStep>
      );
    case 2:
      return (
        <ReviewStep onPressNext={handleNext}>
          <StepperComponent />
        </ReviewStep>
      );
    case 3:
      return (
        <LastStep>
          <StepperComponent />
        </LastStep>
      );
    default:
      return null;
  }
}

export default function Exchange() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const { token } = useParams();
  const { loadExchange } = useExchangeContext();

  useEffect(() => {
    if (!token) {
      setLoading(false);
      return;
    }
    const getData = async () => {
      setLoading(true);
      try {
        await loadExchange(token);
        setActiveStep(2);
      } catch {
        setError(true);
      }
      setLoading(false);
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const steps = [0, 1, 2, 3];
  const classes = useStyles();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const renderStepper = () => (
    <Container maxWidth='xs'>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        className={classes.stepper}
      >
        {steps.map((id) => (
          <Step key={id}>
            <StepLabel />
          </Step>
        ))}
      </Stepper>
    </Container>
  );

  if (loading) {
    return <CircularProgress color='primary' />;
  }
  if (error) {
    return <ErrorMessage />;
  }

  return (
    <Box py={6} px={3} width='100%' className={classes.boxContainer}>
      {getStepContent(activeStep, handleBack, handleNext, renderStepper)}
    </Box>
  );
}
