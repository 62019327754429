import React, { useState } from 'react';
import {
  Box,
  Button,
  Grid,
  Link as MuiLink,
  Typography,
} from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import { Field, Form, Formik } from 'formik';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { Yup, api } from 'utils';
import { parseError } from 'utils/api';
import { passwordConfirmValidator } from 'utils/validators';
import { loginRoute } from 'routes';

import { PasswordAdornment } from '../../../Register/components';
import useStyles from './styles';

const initialValues = {
  password: '',
  passwordConfirmValidator,
};

const validationSchema = Yup.object({
  password: Yup.string().required().max(128),
  passwordConfirm: passwordConfirmValidator,
});

export default function LoginForm() {
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const history = useHistory();
  const { token } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const handleClickShowPassword = () => {
    setShowPassword((v) => !v);
  };

  const handleClickShowPasswordConfirm = () => {
    setShowPasswordConfirm((v) => !v);
  };

  return (
    <>
      <Grid item xs={10} sm={6} lg={3}>
        <Box color='common.white'>
          <Typography color='inherit' align='center' gutterBottom>
            Cambia tu contraseña
          </Typography>
        </Box>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (
            { password }: typeof initialValues,
            { setSubmitting, setErrors }
          ) => {
            try {
              await api.put('clients/change-password/', {
                token,
                password,
              });
              setSubmitting(false);
              history.push(loginRoute, {
                message: 'El cambio de contraseña se realizó exitosamente',
              });
            } catch (e) {
              if (e?.response?.data) {
                if (e.response.data?.token) {
                  enqueueSnackbar(
                    'El token proporcionado no es válido, por favor vuelva a solicitar un cambio de contraseña',
                    { variant: 'error' }
                  );
                } else {
                  setErrors(parseError(e.response.data));
                }
              } else {
                enqueueSnackbar(
                  'No se pudo cambiar la contraseña, por favor vuelva a intentarlo más tarde',
                  { variant: 'error' }
                );
              }

              setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <Field
                component={TextField}
                name='password'
                type={showPassword ? 'text' : 'password'}
                placeholder='Ingresa una nueva contraseña'
                variant='outlined'
                size='small'
                margin='normal'
                autoComplete='new-password'
                fullWidth
                className={classes.loginInput}
                InputProps={{
                  endAdornment: (
                    <PasswordAdornment
                      onClick={handleClickShowPassword}
                      show={showPassword}
                    />
                  ),
                }}
              />
              <Field
                component={TextField}
                name='passwordConfirm'
                type={showPasswordConfirm ? 'text' : 'password'}
                placeholder='Confirma tu nueva contraseña'
                variant='outlined'
                size='small'
                margin='normal'
                autoComplete='new-password'
                fullWidth
                className={classes.loginInput}
                InputProps={{
                  endAdornment: (
                    <PasswordAdornment
                      onClick={handleClickShowPasswordConfirm}
                      show={showPasswordConfirm}
                    />
                  ),
                }}
              />

              <Button
                type='submit'
                variant='contained'
                color='primary'
                className={classes.loginSubmitBtn}
                disabled={isSubmitting}
              >
                Cambiar contraseña
              </Button>
            </Form>
          )}
        </Formik>

        <Typography color='primary' gutterBottom align='center'>
          <MuiLink component={Link} to={loginRoute} underline='always'>
            Regresar
          </MuiLink>
        </Typography>
      </Grid>
    </>
  );
}
