import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    endViewWrapper: {
      marginBottom: theme.spacing(3),
      textAlign: 'center',
    },
  })
);
