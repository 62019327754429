import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    card: {
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      flexFlow: 'column nowrap',
      borderRadius: 16,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,

      [theme.breakpoints.up('sm')]: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
      },

      '& > h6': {
        fontWeight: theme.typography.fontWeightBold,
        fontSize: '0.875rem',
        textTransform: 'uppercase',
        [theme.breakpoints.down('sm')]: {
          fontSize: '0.813rem',
        },
      },
      '& > p': {
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: '1.125rem',
        [theme.breakpoints.down('sm')]: {
          fontSize: '1rem',
        },
        '& span': {
          fontSize: '0.875rem',
          [theme.breakpoints.down('sm')]: {
            fontSize: '0.813rem',
          },
        },
      },
    },
  })
);
