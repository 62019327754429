import React from 'react';
import { Box, Container, Typography } from '@material-ui/core';

import useStyles from './styles';
import BaseModal from '../BaseModal';

interface Props {
  open: boolean;
  onClose: () => void;
}

function PrivacyPolicyModal({ open, onClose }: Props) {
  const classes = useStyles();

  return (
    <BaseModal open={open} onClose={onClose}>
      <Box p={7} className={classes.box}>
        <Container maxWidth='sm' disableGutters className={classes.container}>
          <Typography variant='h5' align='center'>
            POLÍTICA DE PRIVACIDAD
          </Typography>
          <Typography variant='h6' align='center' gutterBottom>
            KAMBIAZO S.A.C.
          </Typography>
          <p>
            La presente Política de Privacidad establece los términos en que
            KAMBIAZO usa y protege la información que es proporcionada por sus
            usuarios al momento de utilizar su sitio web.
          </p>
          <p>
            KAMBIAZO está comprometida con la seguridad de los datos de sus
            usuarios. Cuando le pedimos llenar los campos de información
            personal con la cual usted pueda ser identificado, lo hacemos
            asegurando que sólo se empleará de acuerdo con los términos de este
            documento. Sin embargo esta Política de Privacidad puede cambiar con
            el tiempo o ser actualizada por lo que le recomendamos revisar
            continuamente esta página para asegurarse que está de acuerdo con
            dichos cambios.
          </p>
          <h3>Control de su información personal</h3>
          <p>
            Usted podría proporcionarnos información al llenar los formularios
            en nuestra página web y/o al interactuar con nosotros vía teléfono,
            correo electrónico o de otra forma. La información que nuestro sitio
            web podrá recoger puede ser: Nombre, dirección, correo, cuentas
            bancarias, información demográfica, fotos de documentos de
            identidad. Así mismo cuando sea necesario podrá ser requerida otra
            información específica para procesar la solicitud de usuario.
            Tambien podríamos recibir información de los bancos u otras
            instituciones financieras utilizadas para transferir fondos.
          </p>
          <h3>Cookies</h3>
          <p>
            Nuestro sitio web emplea las cookies para poder identificar las
            páginas que son visitadas y su frecuencia. Esta información es
            empleada únicamente para análisis estadístico y después la
            información se elimina de forma permanente. Usted puede eliminar las
            cookies en cualquier momento desde su ordenador. Sin embargo las
            cookies ayudan a proporcionar un mejor servicio de los sitios web,
            estás no dan acceso a información de su ordenador ni de usted, a
            menos de que usted así lo quiera y la proporcione directamente.
          </p>
          <p>
            Usted puede aceptar o negar el uso de cookies, sin embargo la
            mayoría de navegadores aceptan cookies automáticamente pues sirve
            para tener un mejor servicio web. También usted puede cambiar la
            configuración de su ordenador para declinar las cookies. Si se
            declinan es posible que no pueda utilizar algunos de nuestros
            servicios. La utilización de nuestro sitio web implica su aceptación
            del uso de cookies por nuestra parte.
          </p>
          <h3>Enlace a terceros</h3>
          <p>
            Este sitio web pudiera contener en laces a otros sitios que pudieran
            ser de su interés. Una vez que usted de clic en estos enlaces y
            abandone nuestra página, ya no tenemos control sobre al sitio al que
            es redirigido y por lo tanto no somos responsables de los términos o
            privacidad ni de la protección de sus datos en esos otros sitios
            terceros. Dichos sitios están sujetos a sus propias políticas de
            privacidad por lo cual es recomendable que los consulte para
            confirmar que usted está de acuerdo con estas.
          </p>
          <h3>Uso de la información recogida</h3>
          <p>
            <u>Utilizamos la información para:</u>
          </p>
          <ul>
            <li>
              Proporcionar el mejor servicio posible, particularmente para
              mantener un registro de usuarios, y mejorar nuestros productos y
              servicios.
            </li>
            <li>
              Es posible que sean enviados correos electrónicos periódicamente a
              través de nuestro sitio con información referente a nuestros
              servicios, nuevos productos y otra información publicitaria que
              consideremos relevante para usted o que pueda brindarle algún
              beneficio, estos correos electrónicos serán enviados a la
              dirección que usted nos proporcione y podrán ser cancelados en
              cualquier momento.
            </li>
            <li>
              Para cumplir con todos los requerimientos legales y regulatorios
              impuestos por los entes relevantes
            </li>
            <li>
              Para asegurarnos que nuestro contenido de la página web sea
              presentado de la manera más efectiva para usted y su computadora o
              dispositivo móvil.
            </li>
          </ul>
          <p>
            <u>Podríamos compartir la información con:</u>
          </p>
          <p>
            Negocios aliados, proveedores o terceros contratados para proveer
            cualquier contrato que tengamos con usted o para ayudar a mejorar
            nuestro servicio. Agencias de publicidad que requieran datos para
            seleccionar y enviar avisos relevantes a usted y a otros.
            Proveedores de análisis de datos y de buscadores que nos asistan en
            la mejora y optimización de nuestros servicios Entes reguladores
            como la Superintendencia de Banca y Seguros y la Unidad de
            Inteligencia Financiera. En el caso que se venda o compra cualquier
            unidad de negocio o activo y sea necesario revelar su información
            personal a potenciales compradores o vendedores de dichas unidades
            de negocio o activos. Si es que estamos bajo obligación de revelar o
            compartir su información personal para cumplir con cualquier
            obligación legal o para poder aplicar los Términos y Condiciones.
            Para intercambiar información con otras compañías y organizaciones
            con objetivos de protección frente a fraudes, investigación
            relacionada crímenes financieros o proveer datos para asistir al
            cumplimiento de la ley. Dónde almacenamos la información personal
          </p>
          <p>
            La información recolectada podría ser transferida y almacenada en un
            destino fuera del Perú. Esta podría ser también procesada por
            trabajadores operando fuera del Perú que trabajen para nosotros o
            para algún proveedor.
          </p>
          <p>
            La información que nos envíe se almacena en nuestros servidores
            seguros. Para cualquier transacción de pago se encriptará usando SSL
            y/o alguna otra tecnología de seguridad cibernética.
          </p>
          <p>
            Usted es responsable de mantener la confidencialidad de la
            contraseña seleccionada para acceder a ciertas partes de la página
            web. Le pedimos no compartir su contraseña con nadie ya que KAMBIAZO
            no se hará responsable ante la pérdida o robo de su contraseña.
          </p>
          <p>
            En KAMBIAZO, estamos comprometido para cumplir con el compromiso de
            mantener su información segura. Desafortunadamente la transmisión de
            información a través de internet no es completamente segura. A pesar
            de que haremos el mayor esfuerzo posible para proteger su
            información, no podemos garantizar la seguridad de la información
            transmitida a nuestra página web; cualquier transmisión es bajo su
            propio riesgo
          </p>
          <h3>Marco normativo</h3>
          <ul>
            <li>Ley N° 29733 - Protección de Datos Personales.</li>
            <li>Superintendencia de Banca, Seguros y AFP</li>
          </ul>
          <h3>Cambios en la política de privacidad</h3>
          <p>
            KAMBIAZO Se reserva el derecho de cambiar los términos de la
            presente Política de Privacidad en cualquier momento. Se recomienda
            revisar frecuentemente esta página para ver cualquier actualización
            o cambios a nuestra política de privacidad.
          </p>
          <h3>Control de su información personal</h3>
          <p>
            Usted tiene el derecho de solicitarnos no procesar su información
            para fines de marketing contactándonos a contacto@kambiazo.pe.
          </p>
          <p>Actualizado 09 de setiembre 2020</p>
        </Container>
      </Box>
    </BaseModal>
  );
}

export default PrivacyPolicyModal;
