import React from 'react';
import { Box, Button, Container, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

import useStyles from './styles';
import { operationsRoute } from '../../../Dashboard/routes';

type LastStepProps = {
  children: React.ReactNode;
};

export default function LastStep({ children }: LastStepProps) {
  const classes = useStyles();

  return (
    <>
      <Box
        display='flex'
        alignItems='center'
        flexDirection='column'
        width='100%'
      >
        <Typography
          variant='h4'
          color='secondary'
          display='inline'
          align='center'
        >
          ¡Operación{' '}
          <Typography variant='inherit' component='span' color='primary'>
            reportada!
          </Typography>
        </Typography>
        {children}

        <Container maxWidth='sm' className={classes.container}>
          <Typography
            variant='h6'
            align='center'
            className={classes.textWithDecorations}
          >
            Por favor, <strong>mantente en línea</strong> mientras corroboramos{' '}
            recepción de la <strong>transferencia</strong>.
          </Typography>

          <Button
            component={Link}
            to={operationsRoute}
            color='primary'
            variant='contained'
            className={classes.button}
          >
            Ir a mis operaciones
          </Button>
        </Container>
      </Box>
    </>
  );
}
