import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    bankAccountsTitle: {
      '& span': {
        color: theme.palette.primary.main,
      },
      marginBottom: theme.spacing(2),
    },
    bankAccountsTopGrid: {
      display: 'flex',
      alignItems: 'center',
      '& p': {
        whiteSpace: 'nowrap',
        paddingRight: theme.spacing(1),
      },
      '& [class*="MuiInputBase-root"]': {
        boxShadow: theme.custom.inputShadow,
        backgroundColor: theme.palette.common.white,
      },
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    bankAccountsAddBtn: {
      fontWeight: 'normal',
      textTransform: 'capitalize',
    },
    bankAccountsTable: {
      minWidth: '100%',
      '& th': {
        fontWeight: 'bold',
        color: theme.palette.secondary.main,
        borderColor: theme.palette.secondary.main,
        borderWidth: 3,
      },
      '& td': {
        borderBottom: `2px solid ${theme.palette.grey[300]}`,
      },
      '& tr:last-child td': {
        borderBottom: 'none',
      },
    },
    bankAccountsPagination: {
      display: 'flex',
      justifyContent: 'flex-end',
      '& .Mui-selected': {
        color: theme.palette.common.white,
      },
    },
    bankAccountsTrashIcon: {
      color: '#8c1515',
    },
    bankAccountsTableWrapper: {
      maxWidth: '100vw',
    },
    confirmModal: {
      borderRadius: 23,
    },
    closeBtn: {
      color: theme.palette.secondary.main,
      fontSize: 14,
      position: 'absolute',
      right: theme.spacing(1.5),
      top: theme.spacing(1),
      width: 50,
      height: 50,
    },
    minLoadingContainer: {
      minHeight: 300,
    },
    selectorText: {
      fontWeight: 'normal',
      color: '#595959',
      lineHeight: '1.2em',
      whiteSpace: 'nowrap',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.125rem',
      },
    },
    inputBackground: {
      background: theme.palette.background.paper,
      boxShadow: theme.custom.inputShadow,
      margin: 8,
    },
    inputBackgroundSelector: {
      background: theme.palette.background.paper,
      boxShadow: theme.custom.inputShadow,
      margin: 8,
    },
    emptyCell: {
      padding: 20,
      paddingLeft: 0,
    },
    deleteLoadingContainer: {
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      background: 'rgba(0,0,0, 0.2)',
      top: 0,
      left: 0,
    },
  })
);
