import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import loginBackground from 'assets/images/login/background.jpg';

export default makeStyles((theme: Theme) =>
  createStyles({
    loginWrapper: {
      width: '100%',
      paddingTop: theme.spacing(15),
      paddingBottom: theme.spacing(10),
      minHeight: '100vh',
      backgroundImage: `url(${loginBackground})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100% 100%',
      [theme.breakpoints.down('sm')]: {
        backgroundSize: 'cover',
      },
      [theme.breakpoints.down('xs')]: {
        paddingTop: theme.spacing(5),
      },
    },
    loginLogoImg: {
      display: 'block',
      width: 'max-content',
      margin: theme.spacing(0, 'auto', 4),
    },
  })
);
