import React, { useState } from 'react';
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  Link,
  Typography,
} from '@material-ui/core';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { CheckboxWithLabel, TextField } from 'formik-material-ui';
import { useSnackbar } from 'notistack';

import { api, Yup } from 'utils';
import { parseError } from 'utils/api';
import ClientType from 'common/clientType';
import { commonError } from 'common/errorMessages';
import { dniValidator, passwordConfirmValidator } from 'utils/validators';
import { TermsAndConditionsModal, PrivacyPolicyModal } from 'components';

import useStyles from './styles';
import PasswordAdornment from '../PasswordAdornment';

interface CorporateEntityFormProps {
  handleBack: () => void;
  handleNext: () => void;
}

const initialValues = {
  name: '',
  fathersLastName: '',
  mothersLastName: '',
  dni: '',
  email: '',
  password: '',
  passwordConfirm: '',
  termsAndConditions: false,
};

const validationSchema = Yup.object({
  name: Yup.string().required().max(200),
  fathersLastName: Yup.string().max(200),
  mothersLastName: Yup.string().max(200),
  dni: dniValidator,
  email: Yup.string().required().email().max(254),
  password: Yup.string().required().max(128),
  passwordConfirm: passwordConfirmValidator,
  termsAndConditions: Yup.boolean().oneOf(
    [true],
    'Debes leer y aceptar los términos y condiciones y la política de privacidad'
  ),
});

export default function CorporateEntityForm({
  handleBack,
  handleNext,
}: CorporateEntityFormProps) {
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [termsModalIsOpen, setTermsModalIsOpen] = useState(false);
  const [privacyModalIsOpen, setPrivacyModalIsOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const handleClickShowPassword = () => {
    setShowPassword((v) => !v);
  };

  const handleClickShowPasswordConfirm = () => {
    setShowPasswordConfirm((v) => !v);
  };

  const handleShowTermsModal = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setTermsModalIsOpen(true);
  };

  const handleShowPrivacyModal = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setPrivacyModalIsOpen(true);
  };

  return (
    <>
      <Typography className={classes.registerText} align='center' gutterBottom>
        Te estás registrando como <span>persona natural</span>
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (
          {
            email,
            dni,
            name,
            password,
            fathersLastName,
            mothersLastName,
          }: typeof initialValues,
          { setSubmitting, setErrors }
        ) => {
          try {
            await api.post('clients/register/', {
              email,
              dni,
              name,
              password,
              mothersLastName,
              fathersLastName,
              clientType: ClientType.Person,
            });
            setSubmitting(false);
            handleNext();
          } catch (e) {
            if (e?.response?.data) {
              setErrors(parseError(e.response.data));
            } else {
              enqueueSnackbar(commonError, { variant: 'error' });
            }
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <Grid container justify='center'>
              <Grid item xs={10} md={8}>
                <Field
                  component={TextField}
                  name='name'
                  label='Ingresa tus nombres'
                  autoComplete='given-name'
                  variant='outlined'
                  size='small'
                  margin='normal'
                  fullWidth
                />
                <Field
                  component={TextField}
                  name='fathersLastName'
                  label='Ingresa tu apellido paterno'
                  autoComplete='family-name'
                  variant='outlined'
                  size='small'
                  margin='normal'
                  fullWidth
                />
                <Field
                  component={TextField}
                  name='mothersLastName'
                  label='Ingresa tu apellido materno'
                  autoComplete='family-name'
                  variant='outlined'
                  size='small'
                  margin='normal'
                  fullWidth
                />
                <Field
                  component={TextField}
                  name='dni'
                  label='Ingresa tu DNI'
                  variant='outlined'
                  size='small'
                  margin='normal'
                  fullWidth
                />
                <Field
                  component={TextField}
                  name='email'
                  type='email'
                  autoComplete='email'
                  label='Ingresa tu e-mail'
                  variant='outlined'
                  size='small'
                  margin='normal'
                  fullWidth
                />
                <Field
                  component={TextField}
                  name='password'
                  type={showPassword ? 'text' : 'password'}
                  label='Ingresa tu contraseña'
                  variant='outlined'
                  size='small'
                  margin='normal'
                  autoComplete='new-password'
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <PasswordAdornment
                        onClick={handleClickShowPassword}
                        show={showPassword}
                      />
                    ),
                  }}
                />
                <Field
                  component={TextField}
                  name='passwordConfirm'
                  type={showPasswordConfirm ? 'text' : 'password'}
                  label='Confirma tu contraseña'
                  variant='outlined'
                  size='small'
                  margin='normal'
                  autoComplete='new-password'
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <PasswordAdornment
                        onClick={handleClickShowPasswordConfirm}
                        show={showPasswordConfirm}
                      />
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <Box p={2} className={classes.registerBtnWrapper}>
              <Button
                variant='contained'
                color='primary'
                className={classes.registerBtn}
                disabled={isSubmitting}
                onClick={() => handleBack()}
              >
                Regresar
              </Button>
              <Button
                variant='contained'
                color='secondary'
                type='submit'
                disabled={isSubmitting}
                className={classes.registerBtn}
              >
                Continuar
              </Button>
            </Box>
            <Box
              p={1}
              flexDirection='column'
              alignItems='center'
              className={classes.registerBtnWrapper}
            >
              <Field
                component={CheckboxWithLabel}
                type='checkbox'
                name='termsAndConditions'
                Label={{
                  label: (
                    <Typography align='center' variant='body2'>
                      He leído y acepto los{' '}
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <Link underline='always' onClick={handleShowTermsModal}>
                        términos y condiciones
                      </Link>
                      <br />y la{' '}
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <Link underline='always' onClick={handleShowPrivacyModal}>
                        política de privacidad
                      </Link>
                    </Typography>
                  ),
                }}
              />
              <ErrorMessage name='termsAndConditions'>
                {(msg) => <FormHelperText error> {msg}</FormHelperText>}
              </ErrorMessage>
            </Box>
          </Form>
        )}
      </Formik>

      <TermsAndConditionsModal
        open={termsModalIsOpen}
        onClose={() => setTermsModalIsOpen(false)}
      />
      <PrivacyPolicyModal
        open={privacyModalIsOpen}
        onClose={() => setPrivacyModalIsOpen(false)}
      />
    </>
  );
}
