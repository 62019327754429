import React from 'react';

import {
  Box,
  Button,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
  Typography,
  CircularProgress,
} from '@material-ui/core';

import { BaseModal } from 'components';

import useStyles from '../styles';

interface Props {
  openConfirm: boolean;
  loadingDelete: boolean;
  handleCloseConfirm: () => void;
  handleCloseDelete: () => void;
}

const WarningDeleteModal = ({
  openConfirm,
  loadingDelete,
  handleCloseDelete,
  handleCloseConfirm,
}: Props) => {
  const classes = useStyles();

  return (
    <BaseModal open={openConfirm} onClose={handleCloseConfirm}>
      <Box p={5}>
        <DialogTitle id='confirm-modal-title' disableTypography>
          <Typography
            className={classes.bankAccountsTitle}
            variant='h5'
            color='secondary'
            align='center'
            gutterBottom
          >
            Eliminar <span>cuenta</span>
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='confirm-modal-description'>
            ¿Está seguro de eliminar la cuenta bancaria?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseConfirm}
            variant='contained'
            color='secondary'
          >
            No
          </Button>
          <Button
            onClick={handleCloseDelete}
            variant='contained'
            color='primary'
            autoFocus
          >
            Sí
          </Button>
        </DialogActions>
        {loadingDelete && (
          <div className={classes.deleteLoadingContainer}>
            <CircularProgress />
          </div>
        )}
      </Box>
    </BaseModal>
  );
};

export default WarningDeleteModal;
