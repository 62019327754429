import React, { useState, useEffect, useCallback } from 'react';
import { Box, Grid, Typography, CircularProgress } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import { api } from 'utils';
import { commonError } from 'common/errorMessages';

import DiscountEntity from './components/DiscountEntity';
import EnterCodeModal from './components/EnterCodeModal';
import useStyles from './styles';

interface Entity {
  id: number;
  created: string;
  modified: string;
  companyName: string;
}

export default function GetDiscounts() {
  const [open, setOpen] = useState(false);
  const [entities, setEntities] = useState<Entity[]>([]);
  const [loadingEntities, setLoadingEntities] = useState(false);
  const [firstTime, setFirstTime] = useState(true);
  const [currentEntity, setCurrentEntity] = useState<Entity | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const handleGetEntities = useCallback(async () => {
    setLoadingEntities(true);
    if (firstTime) {
      setEntities([]);
    }
    try {
      const {
        data: { results },
      } = await api.get(`configurations/agreements/`);
      if (firstTime) {
        setFirstTime(false);
      }
      setEntities(results);
      setLoadingEntities(false);
    } catch (error) {
      enqueueSnackbar(commonError, { variant: 'error' });
      setLoadingEntities(false);
    }
  }, [enqueueSnackbar, firstTime]);

  useEffect(() => {
    handleGetEntities();
  }, [handleGetEntities]);

  const handleClickOpen = (entity: Entity) => {
    setOpen(true);
    setCurrentEntity(entity);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentEntity(null);
  };

  return (
    <Box pt={5}>
      <Grid container justify='center'>
        <Grid item xs={12} md={10}>
          <Typography
            variant='h5'
            color='secondary'
            gutterBottom
            align='center'
            className={classes.getDiscountsTitle}
          >
            Obtén tasas <span>preferenciales</span>
          </Typography>
          <Typography color='textSecondary' align='center'>
            Disfruta de increibles beneficios <br /> gracias a nuestros
            convenios empresariales.
          </Typography>
          <Box py={6}>
            {loadingEntities ? (
              <Grid container className={classes.loadingTableContainer}>
                <Grid item>
                  <CircularProgress />
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={6}>
                {entities.length > 0 ? (
                  <>
                    {entities.map((entity) => (
                      <DiscountEntity
                        id={entity.id}
                        companyName={entity.companyName}
                        handleClickOpen={() => handleClickOpen(entity)}
                      />
                    ))}
                  </>
                ) : (
                  <Grid container spacing={6}>
                    <Grid item md={12}>
                      <Typography color='secondary' align='center'>
                        No hay convenios actualmente
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            )}
          </Box>
        </Grid>
      </Grid>
      <EnterCodeModal
        open={open}
        handleClose={handleClose}
        entity={currentEntity as Entity}
      />
    </Box>
  );
}
