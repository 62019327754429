import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Typography, Link as MuiLink, Container } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { mainRoute as homeRoute } from '../../routes';

const useStyles = makeStyles(() =>
  createStyles({
    notFound: {
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',

      '& > span': {
        display: 'block',
        fontSize: 100,
      },
    },
  })
);

function NotFound() {
  const classes = useStyles();

  return (
    <Container maxWidth='md' className={classes.notFound}>
      <span>404</span>
      <Typography>
        No se encontró la página, regresar al{' '}
        <MuiLink component={Link} to={homeRoute}>
          inicio
        </MuiLink>
      </Typography>
    </Container>
  );
}

export default NotFound;
