import React, { createContext, useContext, useEffect, useState } from 'react';

import { useLocalStorage } from 'utils/hooks';
import { addTokenInterceptor, removeInterceptor } from 'utils/api';
import ClientType from 'common/clientType';

const defaultContext = {
  sessionToken: null,
  currentClientType: null,
  isAuthenticated: false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setToken: (s: string) => {},
  removeToken: () => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setCurrentClientType: (c: ClientType | null) => {},
};

interface SessionContextI {
  sessionToken: string | null;
  currentClientType: ClientType | null;
  isAuthenticated: boolean;
  setToken: (s: string) => void;
  removeToken: () => void;
  setCurrentClientType: (c: ClientType) => void;
}

interface SessionProviderProps {
  children: React.ReactNode;
}

const SessionContext = createContext<SessionContextI>(defaultContext);

export function SessionProvider({ children }: SessionProviderProps) {
  const [loading, setLoading] = useState(true);
  const [tokenInterceptorId, setTokenInterceptorId] = useState<number | null>(
    null
  );
  const [
    currentClientType,
    setCurrentClientType,
  ] = useLocalStorage<ClientType | null>('currentClientType', null);
  const [sessionToken, setSessionToken] = useLocalStorage<string | null>(
    'sessionToken',
    null
  );

  useEffect(() => {
    setLoading(true);
    if (!sessionToken) {
      setLoading(false);
      return;
    }
    const id = addTokenInterceptor(sessionToken);
    setTokenInterceptorId(id);
    setLoading(false);
  }, [sessionToken]);

  const removeToken = () => {
    setLoading(true);
    if (tokenInterceptorId === null || sessionToken === null) {
      setLoading(false);
      return;
    }
    setTokenInterceptorId(null);
    setSessionToken(null);
    setCurrentClientType(null);
    removeInterceptor(tokenInterceptorId);
    setLoading(false);
  };

  const context: SessionContextI = {
    sessionToken,
    currentClientType,
    isAuthenticated: tokenInterceptorId !== null,
    setToken: setSessionToken,
    removeToken,
    setCurrentClientType,
  };

  return (
    <SessionContext.Provider value={context}>
      {!loading && children}
    </SessionContext.Provider>
  );
}

export const useSessionContext = () => useContext(SessionContext);
