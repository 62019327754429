import React from 'react';

import { Box, Button, Grid, Typography } from '@material-ui/core';

import useStyles from './styles';

export default function EndView() {
  const classes = useStyles();

  return (
    <Grid container justify='center' className={classes.endViewWrapper}>
      <Grid item>
        <Typography align='center' variant='h5' color='secondary' gutterBottom>
          ¡Gracias!
        </Typography>
        <Box py={3}>
          <Typography align='center' gutterBottom>
            Te hemos enviado un correo electrónico
            <br /> para que puedas verificar tu cuenta.
          </Typography>
        </Box>
        <Button variant='contained' color='secondary' href='/login'>
          Iniciar Sesión
        </Button>
      </Grid>
    </Grid>
  );
}
