import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    getDiscountsTitle: {
      '& span': {
        color: theme.palette.primary.main,
      },
    },
    getDiscountsBtn: {
      textTransform: 'capitalize',
      fontWeight: 'normal',
      display: 'block',
      margin: '0 auto',
    },
    modalInput: {
      marginBottom: theme.spacing(3),
    },
    loadingTableContainer: {
      height: '100%',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: 200,
    },
    modalTitle: {
      textTransform: 'uppercase',
    },
    deleteLoadingContainer: {
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      background: 'rgba(0,0,0, 0.2)',
      top: 0,
      left: 0,
    },
  })
);
