import React from 'react';
import { Backdrop, CircularProgress, Fade } from '@material-ui/core';
import clsx from 'clsx';

import useStyles from './styles';

const defaultProps = {
  absolutePosition: false,
};
type SelectAccountStepProps = {
  loading: boolean;
} & typeof defaultProps;

export default function BackdropLoading({
  loading,
  absolutePosition,
}: SelectAccountStepProps) {
  const classes = useStyles();

  return (
    <Fade
      in={loading}
      style={{
        transitionDelay: loading ? '300ms' : '0ms',
      }}
      unmountOnExit
    >
      <Backdrop
        className={clsx(classes.backdrop, absolutePosition && classes.absolute)}
        open
      >
        <CircularProgress color='inherit' />
      </Backdrop>
    </Fade>
  );
}

BackdropLoading.defaultProps = defaultProps;
