import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      maxWidth: 'max-content',
      width: '100%',
      outline: 0,
      '&:focus': {
        outline: 0,
      },

      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(0, 3),
      },

      [theme.breakpoints.down('xs')]: {
        height: '100%',
      },
    },
    card: {
      position: 'relative',
      borderRadius: 23,
      overflowY: 'auto',

      [theme.breakpoints.down('xs')]: {
        borderRadius: 0,
        height: '100%',
        minHeight: '100vh',
        minWidth: '100vw',
        '@media screen and (-webkit-min-device-pixel-ratio:0)': {
          /* mobile viewport bug fix */
          minHeight: '-webkit-fill-available',
        },
      },
    },
    closeButton: {
      color: theme.palette.secondary.main,
      fontSize: 14,
      position: 'absolute',
      right: theme.spacing(1.5),
      top: theme.spacing(1),
      width: 50,
      height: 50,
      [theme.breakpoints.only('xs')]: {
        top: theme.spacing(3),
      },
    },
    baseModal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  })
);
