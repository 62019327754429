import React from 'react';
import { Box, Container, Grid, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import { useSessionContext } from 'context/Session';
import { useProfileContext } from 'context/Profile';
import ClientType from 'common/clientType';
import { commonError } from 'common/errorMessages';

import useStyles from './styles';
import CompanyForm from './CompanyForm';
import PersonForm from './PersonForm';

export default function ProfileEdit() {
  const { client, updateClient } = useProfileContext();
  const { currentClientType } = useSessionContext();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async (values: any) => {
    await updateClient(values);
    enqueueSnackbar('Perfil actualziado exitósamente', { variant: 'success' });
  };

  const handleError = () => {
    enqueueSnackbar(commonError, { variant: 'error' });
  };

  return (
    <Container maxWidth='md' className={classes.profileWrapper}>
      <Box mb={3}>
        <Grid item xs={12} md={10}>
          <Typography
            className={classes.profileTitle}
            variant='h5'
            color='secondary'
            gutterBottom
          >
            Tu información <span>está segura</span>
          </Typography>
          <Typography color='textPrimary'>
            Nos preocupamos por la seguridad de tus datos y los protegemos con
            protocolos que garantizan tu total privacidad.
          </Typography>
        </Grid>
      </Box>

      {currentClientType === ClientType.Person ? (
        <PersonForm
          initialValues={client}
          onSubmit={handleSubmit}
          onError={handleError}
        />
      ) : (
        <CompanyForm
          initialValues={client}
          onSubmit={handleSubmit}
          onError={handleError}
        />
      )}
    </Container>
  );
}
