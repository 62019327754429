import Axios from 'axios';
import { configure } from 'axios-hooks';

const axios = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export function runAxiosHooksConfig() {
  configure({
    cache: false,
    axios,
  });
}

export function addTokenInterceptor(token: string) {
  return axios.interceptors.request.use((config) => {
    const newConfig = { ...config };
    newConfig.headers.Authorization = `Token ${token}`;
    return newConfig;
  });
}
export function removeInterceptor(id: number) {
  axios.interceptors.request.eject(id);
}

export function parseError(
  error: { [key: string]: string[] },
  nonFieldAs?: string
) {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { non_field_errors, ...fieldErrors } = error;
  const parsed: { [key: string]: string } = {};
  Object.keys(fieldErrors).forEach(
    // eslint-disable-next-line no-return-assign
    (key) => (parsed[key] = fieldErrors[key].join('\n'))
  );

  if (non_field_errors && nonFieldAs) {
    if (Object.prototype.hasOwnProperty.call(parsed, nonFieldAs)) {
      parsed[nonFieldAs] = [parsed[nonFieldAs], ...non_field_errors].join('\n');
    } else {
      parsed[nonFieldAs] = non_field_errors.join('\n');
    }
  }
  return parsed;
}

export default axios;
