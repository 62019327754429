import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles(() =>
  createStyles({
    formButton: {
      textTransform: 'none',
      fontWeight: 'normal',
    },
  })
);
