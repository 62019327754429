import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    loginInput: {
      '& [class*="MuiInputBase-root"]': {
        backgroundColor: theme.palette.common.white,
      },
    },
    loginSubmitBtn: {
      display: 'block',
      margin: theme.spacing(3, 'auto'),

      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
  })
);
