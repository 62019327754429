import { Currency } from 'common/money';

export interface AccountData {
  id: number;
  currency: Currency;
  documentType: string;
  number: string;
  interbankNumber: string;
  holdersName: string;
  documentNumber: string;
  bank: number;
  bankName: string;
  accountType: number;
  accountTypeName: string;
}

export function formatAccountsData(data: AccountData[]) {
  const builtMap = new Map<number, AccountData>();
  data.forEach((account) => {
    builtMap.set(account.id, account);
  });
  return builtMap;
}
