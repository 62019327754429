import { Document } from 'common/document';
import { Yup } from './index';

function getDniValidator(schema: Yup.StringSchema = Yup.string().required()) {
  return schema
    .matches(/^\d+$/, 'DNI solo puede contener números')
    .length(8, 'DNI debe contener 8 caracteres');
}
function getRucValidator(schema: Yup.StringSchema = Yup.string().required()) {
  return schema
    .matches(/^\d+$/, 'RUC solo puede contener números')
    .length(11, 'RUC debe contener 11 caracteres');
}

export const dniValidator = getDniValidator();

export const rucValidator = getRucValidator();

export function documentNumberValidator(
  documentType: Document,
  schema: Yup.StringSchema
) {
  switch (documentType) {
    case Document.DNI:
      return getDniValidator(schema);
    case Document.RUC:
      return getRucValidator(schema);
    case Document.IMMIGRATION:
      return schema.length(
        11,
        'Carnet de extranjería debe contener 11 caracteres'
      );
    default:
      return schema;
  }
}

export const passwordConfirmValidator = Yup.string()
  .required()
  .oneOf([Yup.ref('password')], 'Ambas contraseñas deben ser iguales');

export const nonRequiredPasswordConfirmValidator = Yup.string()
  .nullable()
  .when('password', (password: string, s: Yup.StringSchema) =>
    password ? s.required() : s
  )
  .oneOf([Yup.ref('password')], 'Ambas contraseñas deben ser iguales');
