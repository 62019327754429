import React, { useCallback, useEffect, useState } from 'react';
import { debounce, round } from 'lodash';
import clsx from 'clsx';
import { Box, Button, Grid, IconButton, withStyles } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import CountUp from 'react-countup';

import { Currency, CurrencyName, CurrencySign } from 'common/money';

import {
  useCalculatorStyles,
  useFlipStyles,
  useInputStyles,
  usePriceCardStyles,
} from './styles';

const HAS_DECIMAL = /\.\d/;
const VALID_NUMBER = /^\d+(\.)?\d{0,2}$/;

interface PriceCardProps {
  title: string;
  value: string;
}

function PriceCard({ title, value }: PriceCardProps) {
  const classes = usePriceCardStyles();

  return (
    <Grid item xs={6}>
      <Box py={1.5} className={classes.priceCard}>
        <h1>{title}</h1>
        <div className={classes.value}>
          <p className={classes.currency}>S/</p>
          <p className={classes.price}>
            <CountUp
              start={0}
              end={Number(value)}
              decimals={3}
              delay={0.5}
              duration={0.75}
            />
          </p>
        </div>
      </Box>
    </Grid>
  );
}

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  currency: Currency;
  type: 'send' | 'receive';
  wide: boolean;
}

function Input({ currency, type, wide, ...rest }: InputProps) {
  const classes = useInputStyles();

  const currencySign = CurrencySign[currency];
  const currencyName = CurrencyName[currency];
  return (
    <Box my={1} py={2} pl={3} pr={4} className={classes.input}>
      <span className={classes.currency}>{currency}</span>
      <span className={classes.currencySign}>{currencySign}</span>
      <div className={clsx(classes.inputInput, wide && classes.wideInput)}>
        <label htmlFor={type}>
          {type === 'receive' ? 'Recibes ' : 'Envías '}
          {currencyName}
        </label>
        <input id={type} {...rest} />
      </div>
    </Box>
  );
}

function Flip({ onClick }: { onClick: () => void }) {
  const [clicked, setClicked] = useState(false);
  const classes = useFlipStyles();

  const handleClick = () => {
    onClick();
    setClicked((v) => !v);
  };

  return (
    <IconButton
      onClick={handleClick}
      onAnimationEnd={() => {
        setClicked((v) => !v);
      }}
      className={classes.flip}
    >
      <FontAwesomeIcon
        className={clsx({ [classes.rotate]: clicked })}
        icon={faSyncAlt}
      />
    </IconButton>
  );
}

const LargeButton = withStyles({
  root: {
    borderRadius: 16,
    paddingTop: 12,
    paddingBottom: 12,
  },
})(Button);

const calculatorDefaultProps = {
  defaultSendAmount: '1000',
  defaultReceiveAmount: '0',
  defaultSendCurrency: Currency.USD,
  wide: false,
};
type CalculatorProps = {
  buy: number;
  sell: number;
  onExchange?: (sendAmount: string, sendCurrency: Currency) => void;
  onFlip?: (newCurrency: Currency) => void;
  buttonText: string;
  extraButtonText?: string;
  onClickExtra?: () => void;
  className?: string;
} & typeof calculatorDefaultProps;

function Calculator({
  buy,
  sell,
  defaultSendAmount,
  defaultReceiveAmount,
  defaultSendCurrency,
  onExchange,
  onFlip,
  buttonText,
  extraButtonText,
  onClickExtra,
  className,
  wide,
}: CalculatorProps) {
  const classes = useCalculatorStyles();
  const [sendReceive, setSendReceive] = useState({
    send: defaultSendCurrency,
    receive: defaultSendCurrency === Currency.USD ? Currency.PEN : Currency.USD,
  });
  const [sendAmount, setSendAmount] = useState(defaultSendAmount);
  const [receiveAmount, setReceiveAmount] = useState(defaultReceiveAmount);

  useEffect(() => {
    setReceiveAmount(defaultReceiveAmount);
  }, [defaultReceiveAmount]);

  useEffect(() => {
    setSendAmount(defaultSendAmount);
  }, [defaultSendAmount]);
  useEffect(() => {
    setSendReceive({
      send: defaultSendCurrency,
      receive:
        defaultSendCurrency === Currency.USD ? Currency.PEN : Currency.USD,
    });
  }, [defaultSendCurrency]);

  const handleProceedExchange = () => {
    if (!onExchange) {
      return;
    }
    onExchange(sendAmount, sendReceive.send);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const cleanNumber = useCallback(
    debounce((number: string, type: 'send' | 'receive') => {
      const realNumber = Number(number);
      let cleanedNumber = String(realNumber);
      if (number.match(HAS_DECIMAL)) {
        cleanedNumber = realNumber.toFixed(2);
      }

      if (type === 'send') {
        setSendAmount(cleanedNumber);
      } else {
        setReceiveAmount(cleanedNumber);
      }
    }, 800),
    []
  );

  const updateReceiveAmount = (send: string, sendCurrency?: Currency) => {
    const currency = sendCurrency || sendReceive.send;
    let receive;
    if (currency === 'USD') {
      receive = round(Number(send) * buy, 2);
    } else {
      receive = round(Number(send) / sell, 2);
    }
    let receiveString = String(receive);
    if (receiveString.match(HAS_DECIMAL)) {
      receiveString = receive.toFixed(2);
    }
    setReceiveAmount(receiveString);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdateReceiveAmount = useCallback(
    debounce(updateReceiveAmount, 500),
    [sendReceive, buy, sell]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateSendAmount = useCallback(
    debounce((receive: string) => {
      let send;
      if (sendReceive.receive === 'USD') {
        send = round(Number(receive) * sell, 2);
      } else {
        send = round(Number(receive) / buy, 2);
      }
      let sendString = String(send);
      if (sendString.match(HAS_DECIMAL)) {
        sendString = send.toFixed(2);
      }
      setSendAmount(sendString);
    }, 500),
    [sendReceive, buy, sell]
  );

  const handleSendAmountChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (!value.match(VALID_NUMBER) || value.length > 8) {
      return;
    }
    setSendAmount(value);
    cleanNumber(value, 'send');
    debouncedUpdateReceiveAmount(value);
  };

  const handleReceiveAmountChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (!value.match(VALID_NUMBER) || value.length > 8) {
      return;
    }
    setReceiveAmount(value);
    cleanNumber(value, 'receive');
    updateSendAmount(value);
  };

  const handleFlipClick = () => {
    setSendReceive((current) => {
      if (current.receive === 'USD') {
        updateReceiveAmount(sendAmount, Currency.USD);
        if (onFlip) {
          onFlip(Currency.USD);
        }
        return { receive: Currency.PEN, send: Currency.USD };
      }
      updateReceiveAmount(sendAmount, Currency.PEN);
      if (onFlip) {
        onFlip(Currency.PEN);
      }
      return { receive: Currency.USD, send: Currency.PEN };
    });
  };

  return (
    <div className={clsx(classes.calculator, className)}>
      <Box marginBottom={1} display='flex' justifyContent='center'>
        <Grid
          container
          spacing={2}
          className={clsx(
            wide && classes.widePriceCards,
            classes.cardContainer
          )}
        >
          <PriceCard title='compra' value={buy.toFixed(3)} />
          <PriceCard title='venta' value={sell.toFixed(3)} />
        </Grid>
      </Box>
      <div className={classes.inputsContainer}>
        <Input
          currency={sendReceive.send}
          type='send'
          value={sendAmount}
          wide={wide}
          onChange={handleSendAmountChange}
        />
        <Flip onClick={handleFlipClick} />
        <Input
          currency={sendReceive.receive}
          type='receive'
          value={receiveAmount}
          wide={wide}
          onChange={handleReceiveAmountChange}
        />
      </div>
      <LargeButton
        variant='contained'
        color='primary'
        fullWidth
        onClick={handleProceedExchange}
      >
        {buttonText}
      </LargeButton>
      {!!extraButtonText && (
        <LargeButton
          variant='contained'
          color='secondary'
          fullWidth
          onClick={onClickExtra}
        >
          {extraButtonText}
        </LargeButton>
      )}
    </div>
  );
}

Calculator.defaultProps = calculatorDefaultProps;

export default Calculator;
