import React from 'react';
import { Backdrop, Card, Fade, IconButton, Modal } from '@material-ui/core';

import useStyles from './styles';

interface BaseModalProps {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

export default function BaseModal({ open, onClose, children }: BaseModalProps) {
  const classes = useStyles();

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      className={classes.baseModal}
    >
      <Fade in={open}>
        <div className={classes.container}>
          <Card className={classes.card}>
            <IconButton className={classes.closeButton} onClick={onClose}>
              X
            </IconButton>
            {children}
          </Card>
        </div>
      </Fade>
    </Modal>
  );
}
