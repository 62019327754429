import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  ButtonBase,
  Grid,
  Radio,
  Typography,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

import { BaseModal } from 'components';
import womanCard from 'assets/images/exchange/woman-card.png';
import { Currency, CurrencyName } from 'common/money';

import useStyles from './styles';

interface AccountType {
  id: number;
  currency: Currency;
  number: string;
  holdersName: string;
  bankName: string;
  accountTypeName: string;
}

interface AccountCardProps {
  account: AccountType;
  checked: boolean;
  onClick: () => void;
}

function AccountCard({ account, checked, onClick }: AccountCardProps) {
  const classes = useStyles();

  return (
    <Grid item>
      <ButtonBase
        centerRipple
        onClick={onClick}
        className={classes.accountCard}
      >
        <Radio
          value={`${account.id}`}
          checked={checked}
          name='radio-button-demo'
          inputProps={{ 'aria-label': 'A' }}
          size='small'
          color='primary'
          className={classes.radio}
        />
        <img src={womanCard} alt='woman-card' />
        <Typography variant='body2' align='left'>
          Banco: {account.bankName}
          <br />
          Cuenta: {account.number}
          <br />
          Titular: {account.holdersName}
          <br />
          Moneda: {CurrencyName[account.currency]}
        </Typography>
      </ButtonBase>
    </Grid>
  );
}

interface ClientAccountsModalProps {
  open: boolean;
  onClose: () => void;
  accountsMap: Map<number, AccountType>;
  selectedAccount: number;
  setSelectedAccount: (id: number) => void;
  onOpenNewAccountModal: () => void;
}

export default function ClientAccountsModal({
  open,
  onClose,
  accountsMap,
  selectedAccount,
  setSelectedAccount,
  onOpenNewAccountModal,
}: ClientAccountsModalProps) {
  const [selected, setSelected] = useState(selectedAccount);
  const classes = useStyles();

  useEffect(() => {
    setSelected(selectedAccount);
  }, [selectedAccount]);

  const handleClose = () => {
    setSelected(selectedAccount);
    onClose();
  };

  const handleAccept = () => {
    setSelectedAccount(selected);
    onClose();
  };

  return (
    <BaseModal open={open} onClose={handleClose}>
      <Box display='flex' flexDirection='column' alignItems='center' p={7}>
        <Typography
          variant='h5'
          color='secondary'
          display='inline'
          align='center'
          className={classes.title}
        >
          ¿En qué cuenta deseas
          <br />
          <Typography variant='inherit' component='span' color='primary'>
            recibir tu dinero?
          </Typography>
        </Typography>
        <Grid container justify='center' spacing={2} className={classes.grid}>
          {Array.from(accountsMap).map(([id, account]) => (
            <AccountCard
              key={id}
              account={account}
              checked={id === selected}
              onClick={() => setSelected(id)}
            />
          ))}
          <Grid item>
            <Button
              variant='contained'
              color='secondary'
              centerRipple
              disableElevation
              onClick={onOpenNewAccountModal}
              className={classes.addButton}
            >
              <FontAwesomeIcon icon={faPlusCircle} />
              Agregar Cuenta
            </Button>
          </Grid>
        </Grid>
        <Button
          variant='contained'
          color='primary'
          onClick={handleAccept}
          className={classes.selectButton}
        >
          Seleccionar
        </Button>
      </Box>
    </BaseModal>
  );
}
