import React from 'react';

import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function OperationsIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox='0 0 22.1 21.3'>
      <g>
        <path
          d='M2.8,10.6c0-0.2,0-0.3,0-0.5c0.1-1.3,0.5-2.5,1.1-3.6c1.7-3,5.2-4.7,8.7-4.2c2.2,0.3,4,1.4,5.4,3.1
          c0,0.1,0.1,0.1,0.1,0.2c-0.2,0-0.4,0-0.6,0c-0.2,0-0.3,0-0.2,0.2c0,0.4,0,0.9,0,1.3c0,0.2,0.1,0.2,0.2,0.2c0.8,0,1.5,0,2.3,0
          c0.5,0,0.8-0.2,0.9-0.6c0.3-0.8,0.5-1.6,0.8-2.4c0-0.1,0-0.2-0.1-0.2C21,4,20.6,3.8,20.1,3.7c-0.2-0.1-0.2,0-0.3,0.1
          c-0.1,0.3-0.2,0.5-0.3,0.8c-0.1,0-0.1-0.1-0.2-0.2c-1.4-1.8-3.3-3-5.5-3.6c-0.6-0.1-1.1-0.2-1.7-0.3c-0.6,0-1.1,0-1.7,0
          C9.9,0.6,9.3,0.6,8.7,0.8C6,1.5,4,3.1,2.5,5.4C1.7,6.7,1.2,8.2,1.1,9.8c0,0,0,0.1,0,0.1c0,0.3,0,0.6,0,0.8c0.5,0,1.1,0,1.6,0
          C2.8,10.8,2.8,10.7,2.8,10.6z'
        />
        <path
          d='M20,10.8c-0.1,0-0.1,0.1-0.2,0.2c0,0.2,0,0.4,0,0.5c-0.2,2.1-1,3.9-2.5,5.4c-2,2-4.5,2.8-7.3,2.4
          c-2.2-0.3-4-1.4-5.4-3.1c0-0.1-0.1-0.1-0.1-0.2c0.2,0,0.4,0,0.6,0c0.2,0,0.3,0,0.2-0.2c0-0.4,0-0.9,0-1.3c0-0.2-0.1-0.2-0.2-0.2
          c-0.8,0-1.5,0-2.3,0c-0.5,0-0.8,0.2-0.9,0.6c-0.3,0.8-0.5,1.6-0.8,2.4c0,0.1,0,0.2,0.1,0.2c0.4,0.1,0.9,0.3,1.3,0.4
          c0.2,0.1,0.2,0,0.3-0.1C2.9,17.5,3,17.2,3.1,17c0.1,0,0.1,0.1,0.2,0.2c1.4,1.8,3.3,3,5.6,3.6c0.6,0.1,1.1,0.2,1.7,0.3
          c0.6,0,1.1,0,1.7,0c0.6-0.1,1.2-0.1,1.8-0.3c4-1.2,6.5-3.7,7.5-7.7c0.1-0.5,0.2-0.9,0.2-1.4c0-0.3,0-0.6,0-0.8
          C21.1,10.8,20.6,10.8,20,10.8z'
        />
        <g>
          <path
            d='M11.4,9.8c-0.6,0-1.1,0.4-1.1,1.1c0,0.6,0.4,1.1,1.1,1.1c0.6,0,1.1-0.4,1.1-1.1C12.5,10.4,12.1,9.9,11.4,9.8
            z'
          />
          <path
            d='M11.3,4.7c-3.4,0-6,2.7-6,6c0,3.4,2.7,6,6.1,6c3.4,0,6-2.7,6-6C17.4,7.4,14.7,4.8,11.3,4.7z M15,11.6
            c-0.2,0.1-0.5,0.1-0.7,0.2c-0.1,0-0.1,0-0.1,0.1c0,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.1,0,0.1c0.1,0.2,0.3,0.4,0.4,0.6
            c0.1,0.2,0.1,0.2,0,0.4c-0.2,0.2-0.4,0.4-0.5,0.5c-0.1,0.1-0.2,0.2-0.4,0.1c-0.2-0.1-0.4-0.2-0.6-0.4c-0.1,0-0.1,0-0.2,0
            c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.1,0.1-0.1,0.1c-0.1,0.2-0.1,0.5-0.2,0.7c0,0.1-0.1,0.2-0.2,0.2c-0.3,0-0.7,0-1,0
            c-0.1,0-0.1-0.1-0.2-0.2c-0.1-0.2-0.1-0.5-0.2-0.7c0-0.1,0-0.1-0.1-0.1c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.1,0-0.2,0
            c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.2,0.1-0.4,0c-0.2-0.2-0.4-0.4-0.5-0.5c-0.1-0.1-0.1-0.2,0-0.4c0.1-0.2,0.2-0.4,0.4-0.6
            c0-0.1,0-0.1,0-0.2c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1-0.1-0.1-0.1-0.1c-0.2-0.1-0.5-0.1-0.7-0.2c-0.1,0-0.2-0.1-0.2-0.2
            c0-0.3,0-0.7,0-1c0-0.1,0.1-0.1,0.2-0.2c0.2-0.1,0.5-0.1,0.7-0.2c0.1,0,0.1,0,0.1-0.1c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.1,0-0.1
            C8.6,9.3,8.5,9.1,8.4,8.9c-0.1-0.2-0.1-0.2,0-0.4C8.6,8.3,8.8,8.2,9,8c0.1-0.1,0.2-0.1,0.4,0C9.6,8,9.8,8.2,10,8.3
            c0.1,0,0.1,0,0.1,0c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1-0.1,0.1-0.1c0.1-0.3,0.1-0.5,0.2-0.8c0-0.2,0.1-0.2,0.3-0.2
            c0.3,0,0.5,0,0.8,0c0.2,0,0.3,0.1,0.3,0.2c0.1,0.2,0.1,0.5,0.2,0.7c0,0.1,0,0.1,0.1,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.2,0
            C13,8.2,13.2,8,13.4,7.9c0.2-0.1,0.3-0.1,0.4,0c0.2,0.2,0.4,0.4,0.5,0.5c0.1,0.1,0.1,0.2,0,0.4c-0.1,0.2-0.3,0.4-0.4,0.6
            c0,0,0,0.1,0,0.1c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.1,0.1,0.1c0.3,0.1,0.5,0.1,0.8,0.2c0.2,0,0.2,0.1,0.2,0.3c0,0.3,0,0.5,0,0.8
            C15.3,11.5,15.2,11.6,15,11.6z'
          />
        </g>
      </g>
    </SvgIcon>
  );
}
