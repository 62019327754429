import React from 'react';

import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function ExchangeIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox='0 0 22.1 21.3'>
      <g>
        <path
          d='M12.2,0.5c0.6,0.1,1.2,0.2,1.7,0.3c2.2,0.6,4.1,1.8,5.5,3.6c0,0.1,0.1,0.1,0.2,0.2c0.1-0.3,0.2-0.5,0.3-0.8
					c0-0.1,0.1-0.2,0.3-0.1C20.6,3.8,21,4,21.4,4.1c0.1,0,0.2,0.1,0.1,0.2c-0.3,0.8-0.5,1.6-0.8,2.4c-0.1,0.4-0.5,0.6-0.9,0.6
					c-0.8,0-1.5,0-2.3,0c-0.2,0-0.3,0-0.2-0.2c0-0.4,0-0.9,0-1.3c0-0.2,0.1-0.2,0.2-0.2c0.2,0,0.4,0,0.6,0c0-0.1-0.1-0.2-0.1-0.2
					c-1.4-1.7-3.2-2.7-5.4-3.1c-3.4-0.5-7,1.2-8.7,4.2c-0.6,1.1-1,2.3-1.1,3.6c0,0.2,0,0.3,0,0.5c0,0.1,0,0.2-0.2,0.2
					c-0.5,0-1.1,0-1.6,0c0-0.3,0-0.6,0-0.8c0,0,0-0.1,0-0.1c0.1-1.6,0.6-3.1,1.5-4.4C4,3.1,6,1.5,8.7,0.8c0.6-0.2,1.2-0.2,1.8-0.3
					C11.1,0.5,11.6,0.5,12.2,0.5z'
        />
        <path
          d='M10.5,21c-0.6-0.1-1.2-0.2-1.7-0.3c-2.3-0.6-4.1-1.8-5.6-3.6c0-0.1-0.1-0.1-0.2-0.2
					c-0.1,0.3-0.2,0.5-0.3,0.8c0,0.1-0.1,0.2-0.3,0.1c-0.4-0.2-0.9-0.3-1.3-0.4c-0.1,0-0.2-0.1-0.1-0.2c0.3-0.8,0.5-1.6,0.8-2.4
					c0.1-0.4,0.5-0.6,0.9-0.6c0.8,0,1.5,0,2.3,0c0.2,0,0.3,0,0.2,0.2c0,0.4,0,0.9,0,1.3c0,0.2-0.1,0.2-0.2,0.2c-0.2,0-0.4,0-0.6,0
					c0,0.1,0.1,0.2,0.1,0.2c1.4,1.7,3.2,2.8,5.4,3.1c2.8,0.4,5.2-0.4,7.3-2.4c1.5-1.5,2.3-3.3,2.5-5.4c0-0.2,0-0.4,0-0.5
					c0-0.1,0-0.2,0.2-0.2c0.5,0,1.1,0,1.6,0c0,0.3,0,0.6,0,0.8c-0.1,0.5-0.1,0.9-0.2,1.4c-1,4-3.5,6.6-7.5,7.7
					c-0.6,0.2-1.2,0.2-1.8,0.3C11.6,21,11.1,21,10.5,21z'
        />
        <path
          d='M11.3,4.7c3.3,0,6,2.7,6,6c0,3.3-2.6,6-6,6c-3.4,0-6-2.7-6.1-6C5.3,7.4,8,4.8,11.3,4.7z M10.4,12.5
					c-0.5,0-0.9,0-1.4,0c-0.1,0-0.2,0-0.2,0.2c0,0.4,0,0.8,0,1.3c0,0.2,0.1,0.2,0.2,0.2c0.4,0,0.9,0,1.3,0c0.2,0,0.2,0.1,0.2,0.2
					c0,0.2,0,0.3,0,0.5c0,0.1,0,0.2,0.2,0.2c0.4,0,0.9,0,1.3,0c0.1,0,0.2-0.1,0.2-0.2c0-0.2,0-0.3,0-0.5c0-0.1,0-0.2,0.2-0.3
					c0.9-0.3,1.7-1.2,1.5-2.3c-0.1-0.9-0.8-1.7-1.7-1.8c-0.4-0.1-0.8,0-1.2,0c-0.3,0-0.5-0.2-0.5-0.5c0-0.3,0.2-0.4,0.5-0.4
					c0.1,0,0.1,0,0.2,0c0.9,0,1.7,0,2.6,0c0.2,0,0.2,0,0.2-0.2c0-0.4,0-0.8,0-1.2c0-0.2,0-0.2-0.2-0.2c-0.4,0-0.8,0-1.3,0
					c-0.2,0-0.2-0.1-0.2-0.2c0-0.2,0-0.3,0-0.5c0-0.1,0-0.2-0.2-0.2c-0.4,0-0.9,0-1.3,0c-0.1,0-0.2,0.1-0.2,0.2c0,0.2,0,0.3,0,0.5
					c0,0.2-0.1,0.2-0.2,0.3c-0.9,0.3-1.5,1.1-1.5,2c0,1.2,0.9,2.1,2.1,2.1c0.3,0,0.6,0,0.9,0c0.3,0,0.5,0.3,0.4,0.6
					c-0.1,0.2-0.2,0.3-0.5,0.3C11.3,12.5,10.8,12.5,10.4,12.5z'
        />
      </g>
    </SvgIcon>
  );
}
