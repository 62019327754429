import React from 'react';

import {
  Box,
  Container,
  Grid,
  Paper,
  Stepper,
  Step,
  StepLabel,
  Typography,
} from '@material-ui/core';

import {
  CorporateEntityForm,
  EndView,
  NaturalPersonForm,
  StartView,
} from './components';
import useStyles from './styles';

function getStepContent(
  stepIndex: number,
  isNaturalPerson: boolean,
  handleIsNaturalPerson: (value: boolean) => void,
  handleBack: () => void,
  handleNext: () => void
) {
  switch (stepIndex) {
    case 0:
      return <StartView handleIsNaturalPerson={handleIsNaturalPerson} />;
    case 1:
      return isNaturalPerson ? (
        <NaturalPersonForm handleBack={handleBack} handleNext={handleNext} />
      ) : (
        <CorporateEntityForm handleBack={handleBack} handleNext={handleNext} />
      );
    case 2:
      return <EndView />;
    default:
      return 'Unknown stepIndex';
  }
}

export default function Register() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [isNaturalPerson, setIsNaturalPerson] = React.useState(true);
  const steps = [0, 1, 2];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleIsNaturalPerson = (value: boolean) => {
    setIsNaturalPerson(value);
    handleNext();
  };

  return (
    <div className={classes.mainContainer}>
      <Container maxWidth='md' className={classes.root}>
        <Paper className={classes.registerWrapper} elevation={3}>
          <Typography variant='h5' color='secondary' align='center'>
            REGÍSTRATE
          </Typography>
          <Grid container justify='center'>
            <Grid item xs={12} md={6}>
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((id) => (
                  <Step key={id}>
                    <StepLabel />
                  </Step>
                ))}
              </Stepper>
              <Box className={classes.instructions}>
                {getStepContent(
                  activeStep,
                  isNaturalPerson,
                  handleIsNaturalPerson,
                  handleBack,
                  handleNext
                )}
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </div>
  );
}
