import React from 'react';
import { Box, Container, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import useStyles from './styles';

export default function ErrorMessage() {
  const classes = useStyles();
  return (
    <Container maxWidth='sm'>
      <Box display='flex' justifyContent='center' flexDirection='column'>
        <p className={classes.errorIcon}>
          <FontAwesomeIcon icon={faExclamationTriangle} />
        </p>
        <Typography variant='h5' align='center' color='secondary'>
          Hubo un error, por favor vuelva a intentarlo.
        </Typography>
      </Box>
    </Container>
  );
}
