import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import whiteBackground from 'assets/images/common/curly-white-background.png';

import { headerHeight, headerHeightSm } from 'theme/mainTheme';

export default makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      background: `url(${whiteBackground})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100% 100%',
      [theme.breakpoints.down('sm')]: {
        backgroundSize: 'cover',
      },
    },
    root: {
      width: '100%',
      marginTop: headerHeight,
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(10),

      [theme.breakpoints.down('sm')]: {
        marginTop: headerHeightSm,
      },
    },
    registerWrapper: {
      paddingTop: theme.spacing(7),
      paddingBottom: theme.spacing(7),
      borderRadius: '16px',
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  })
);
