import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    registerBtn: {
      marginBottom: theme.spacing(3),
      textTransform: 'capitalize',
    },
    link: {
      fontWeight: theme.typography.fontWeightMedium,
    },
  })
);
