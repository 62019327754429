import React, { createContext, useContext, useEffect, useState } from 'react';
import useAxios from 'axios-hooks';
import { round } from 'lodash';
import { useSnackbar } from 'notistack';

import { Currency } from 'common/money';
import { ExchangeType } from 'common/exchangeType';
import { Document } from 'common/document';
import { api } from 'utils';
import { exchangeRateError } from 'common/errorMessages';

type Exchange = {
  id: number;
  rate: string;
  fromBank: number;
  fromBankName: string;
  fromAdminAccount: {
    id: number;
    bankName: string;
    accountTypeName: string;
    created: string;
    modified: string;
    currency: Currency;
    number: string;
    interbankNumber: string;
    bank: number;
    accountType: number;
    holdersName: string;
    documentType: Document;
    documentNumber: string;
  };
  exchangeType: ExchangeType;
  date: string;
  amount: string;
  depotAccount: number;
  numberDepotAccount: string;
  holderDepotAccount: string;
  depotAccountCurrency: Currency;
  changedAmount: string;
  token: string;
  state: string;
  history: boolean;
  coupon: string | null;
};

type Money = {
  amount: string;
  currency: Currency;
};

const defaultContext = {
  buy: 1,
  sell: 1,
  sendValue: {
    amount: '1000',
    currency: Currency.USD,
  },
  setSendValue: () => {},
  getReceiveAmount: () => '1000',
  getReceiveCurrency: () => Currency.USD,
  getExchangeRate: () => '1',
  createExchange: async () => null,
  loadExchange: async () => null,
  addCoupon: async () => null,
  clearExchange: () => {},
  exchange: null,
  coupon: null,
};

interface ExchangeContextI {
  buy: number;
  sell: number;
  sendValue: {
    amount: string;
    currency: Currency;
  };
  setSendValue: (v: Partial<Money>) => void;
  getReceiveAmount: () => string;
  getReceiveCurrency: () => Currency;
  getExchangeRate: () => string;
  createExchange: (depotAccount: number, fromBank: number) => Promise<any>;
  loadExchange: (token: string) => Promise<any>;
  addCoupon: (coupon: string) => Promise<any>;
  clearExchange: () => void;
  exchange: Exchange | null;
  coupon: string | null;
}

interface ExchangeProviderProps {
  children: React.ReactNode;
}

const ExchangeContext = createContext<ExchangeContextI>(defaultContext);

export function ExchangeProvider({ children }: ExchangeProviderProps) {
  const [buy, setBuy] = useState(defaultContext.buy);
  const [sell, setSell] = useState(defaultContext.sell);
  const [sendValue, setSendValue] = useState(defaultContext.sendValue);
  const [exchange, setExchange] = useState<Exchange | null>(
    defaultContext.exchange
  );
  const [coupon, setCoupon] = useState<string | null>(defaultContext.coupon);
  const { enqueueSnackbar } = useSnackbar();
  const [{ data, loading, error }, fetchExchange] = useAxios(
    'configurations/exchange-rate/'
  );

  useEffect(() => {
    if (loading) {
      return;
    }

    if (!error) {
      const { purchaseRate, saleRate } = data;
      setBuy(Number(purchaseRate));
      setSell(Number(saleRate));
    } else {
      enqueueSnackbar(exchangeRateError, { variant: 'error' });
    }
  }, [data, loading, error, enqueueSnackbar]);

  const handleSetValue = (newState: Partial<Money>) => {
    setSendValue((current) => ({ ...current, ...newState }));
  };

  const getReceiveAmount = () => {
    if (exchange) {
      return exchange.changedAmount;
    }

    if (sendValue.currency === Currency.USD) {
      return String(round(Number(sendValue.amount) * buy, 2));
    }
    return String(round(Number(sendValue.amount) / sell, 2));
  };
  const getReceiveCurrency = () => {
    if (exchange) {
      return exchange.depotAccountCurrency;
    }
    if (sendValue.currency === Currency.USD) {
      return Currency.PEN;
    }
    return Currency.USD;
  };
  const getExchangeRate = () => {
    if (exchange) {
      return exchange.rate;
    }

    if (sendValue.currency === Currency.USD) {
      return String(buy);
    }
    return String(sell);
  };

  const createExchange = async (depotAccount: number, fromBank: number) => {
    // NOTE: exceptions must be handled outside
    const success = await api.post('clients/exchange/', {
      amount: sendValue.amount,
      depotAccount,
      fromBank,
      exchangeType:
        sendValue.currency === Currency.PEN
          ? ExchangeType.Purchase
          : ExchangeType.Sale,
      ...(coupon && { coupon }),
    });
    setExchange(success.data);
  };

  const loadExchange = async (token: string) => {
    // NOTE: exceptions must be handled outside
    const success = await api.get(`clients/exchange/${token}`);
    const loadedExchange: Exchange = success.data;

    setExchange(loadedExchange);
    setSendValue({
      amount: loadedExchange.amount,
      currency:
        loadedExchange.exchangeType === ExchangeType.Sale
          ? Currency.USD
          : Currency.PEN,
    });
  };

  const addCoupon = async (newCoupon: string) => {
    // NOTE: exceptions must be handled outside
    const response = await api.get(`clients/discounts-request/${newCoupon}`);
    const { purchaseRate, saleRate } = response.data.exchangeRate;

    setBuy(Number(purchaseRate));
    setSell(Number(saleRate));
    setCoupon(newCoupon);
  };

  const clearExchange = () => {
    if (coupon) {
      // when a coupon is applied the buy and sell values are modified so they need to be reset
      fetchExchange();
    }
    setSendValue(defaultContext.sendValue);
    setExchange(defaultContext.exchange);
    setCoupon(defaultContext.coupon);
  };

  const context: ExchangeContextI = {
    buy,
    sell,
    sendValue,
    exchange,
    coupon,
    setSendValue: handleSetValue,
    getReceiveAmount,
    getReceiveCurrency,
    getExchangeRate,
    createExchange,
    loadExchange,
    addCoupon,
    clearExchange,
  };

  return (
    <ExchangeContext.Provider value={context}>
      {children}
    </ExchangeContext.Provider>
  );
}

export const useExchangeContext = () => useContext(ExchangeContext);
