import React, { memo } from 'react';

import {
  Box,
  DialogContent,
  DialogContentText,
  Typography,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';

import { BaseModal } from 'components';
import useStyles from '../styles';

interface Props {
  open: boolean;
  loadingDelete: boolean;
  handleCloseModal: () => void;
  handleCloseDeleteOrderModal: () => void;
}

const WarningDeleteOrder = memo(
  ({
    open,
    handleCloseDeleteOrderModal,
    handleCloseModal,
    loadingDelete,
  }: Props) => {
    const classes = useStyles();

    return (
      <BaseModal open={open} onClose={handleCloseModal}>
        <Box p={5}>
          <DialogTitle id='confirm-modal-title' disableTypography>
            <Typography
              className={classes.deleteModalTitle}
              variant='h5'
              color='secondary'
              align='center'
              gutterBottom
            >
              Eliminar <span>transacción</span>
            </Typography>
          </DialogTitle>

          <DialogContent>
            <DialogContentText id='confirm-modal-description'>
              ¿Está seguro de eliminar la transferencia de su historial?
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button
              onClick={handleCloseModal}
              variant='contained'
              color='secondary'
            >
              No
            </Button>
            <Button
              onClick={handleCloseDeleteOrderModal}
              variant='contained'
              color='primary'
              autoFocus
            >
              Sí
            </Button>
          </DialogActions>
          {loadingDelete && (
            <div className={classes.deleteLoadingContainer}>
              <CircularProgress />
            </div>
          )}
        </Box>
      </BaseModal>
    );
  }
);

export default WarningDeleteOrder;
