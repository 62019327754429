import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import cityImg from 'assets/images/login/city.png';

export default makeStyles((theme: Theme) =>
  createStyles({
    gridCard: {
      display: 'flex',
      justifyContent: 'center',
    },
    cardWrapper: {
      width: 380,
      height: 300,
      backgroundImage: `url(${cityImg})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100% 100%',
      overflow: 'visible',
    },
    media: {
      height: 160,
      backgroundSize: 'contain',
      marginTop: theme.spacing(7),
    },
    cardContent: {
      padding: 0,
    },
    cardActionsBtn: {
      display: 'block',
      margin: '0 auto',
    },
    cardSmText: {
      fontStyle: 'italic',
    },
    cardBigText: {
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
  })
);
