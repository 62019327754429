import React from 'react';
import { IconButton, InputAdornment } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

interface PasswordAdornmentProps {
  onClick: () => void;
  show: boolean;
}

export default function PasswordAdornment({
  onClick,
  show,
}: PasswordAdornmentProps) {
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <InputAdornment position='end'>
      <IconButton
        onClick={onClick}
        onMouseDown={handleMouseDownPassword}
        edge='end'
        tabIndex={-1}
      >
        {show ? (
          <FontAwesomeIcon icon={faEyeSlash} size='xs' />
        ) : (
          <FontAwesomeIcon icon={faEye} size='xs' />
        )}
      </IconButton>
    </InputAdornment>
  );
}
