import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Hidden,
  MenuItem,
  TextField,
  Typography,
  Tooltip,
  useMediaQuery,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import useAxios from 'axios-hooks';
import { useSnackbar } from 'notistack';

import { BackdropLoading } from 'components';
import { CreditCardsIcon } from 'components/CustomIcons';
import { Currency, CurrencyName } from 'common/money';
import { commonError } from 'common/errorMessages';
import { useExchangeContext } from 'context/Exchange';
import { parseError } from 'utils/api';

import ClientAccountsModal from '../ClientAccountsModal';
import CreateAccountModal from '../CreateAccountModal';
import ExchangeInfo from '../ExchangeInfo';
import useStyles from './styles';
import { formatAccountsData, AccountData } from './dataHandler';

type BanksList = Array<{ id: number; name: string }>;

function SelectedAccount({ account }: { account: AccountData }) {
  const classes = useStyles();
  const currencyName = CurrencyName[account.currency as Currency];

  return (
    <Typography variant='h6' align='center'>
      Cuenta seleccionada
      <span className={classes.accountNumber}>{account.number}</span>
      <span className={classes.accountDescription}>
        <strong>{account.bankName}</strong> - {account.accountTypeName}{' '}
        {currencyName}
      </span>
    </Typography>
  );
}

type SelectAccountStepProps = {
  onPressNext: () => void;
  onPressPrev: () => void;
  children: React.ReactNode;
};

export default function SelectAccountsStep({
  onPressNext,
  onPressPrev,
  children,
}: SelectAccountStepProps) {
  const {
    sendValue,
    getReceiveAmount,
    getReceiveCurrency,
    getExchangeRate,
    createExchange,
  } = useExchangeContext();
  const [openAccountsModal, setOpenAccountsModal] = useState(false);
  const [openNewAccountModal, setOpenNewAccountModal] = useState(false);
  const [banksList, setBanksList] = useState<BanksList>([]);
  const [accountsMap, setAccountsMap] = useState<Map<number, any>>(new Map());
  const [selectedAccountId, setSelectedAccountId] = useState(0);
  const [selectedBankId, setSelectedBankId] = useState<string | number>('');
  const [creationLoading, setCreationLoading] = useState(false);
  const [
    { data: banksData, error: banksError, loading: banksLoading },
  ] = useAxios('configurations/banks/');
  const [
    { data: accountsData, error: accountsError, loading: accountsLoading },
  ] = useAxios({
    url: 'clients/accounts/',
    params: {
      currency: getReceiveCurrency(),
    },
  });
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const { enqueueSnackbar } = useSnackbar();

  const loading = banksLoading || accountsLoading || creationLoading;
  const selectedAccount = accountsMap.get(selectedAccountId);

  useEffect(() => {
    if (banksLoading) {
      return;
    }

    if (!banksError) {
      const { results } = banksData;
      setBanksList(results);
    } else {
      enqueueSnackbar(commonError, { variant: 'error' });
    }
  }, [banksData, banksError, banksLoading, enqueueSnackbar]);

  useEffect(() => {
    if (accountsLoading) {
      return;
    }

    if (!accountsError) {
      const { results } = accountsData;
      setAccountsMap(formatAccountsData(results));
    } else {
      enqueueSnackbar(commonError, { variant: 'error' });
    }
  }, [accountsData, accountsError, accountsLoading, enqueueSnackbar]);

  const handleAddAccount = (newAccount: AccountData) => {
    const newAccountsMap = new Map(accountsMap);
    newAccountsMap.set(newAccount.id, newAccount);
    setAccountsMap(newAccountsMap);
    setSelectedAccountId(newAccount.id);
  };

  const handleCreateExchange = async () => {
    if (!selectedBankId || !selectedAccountId) {
      return;
    }
    setCreationLoading(true);
    try {
      await createExchange(selectedAccountId, Number(selectedBankId));
      enqueueSnackbar('Operación procesada exitósamente', {
        variant: 'success',
      });
      setCreationLoading(false);
      onPressNext();
    } catch (e) {
      if (e?.response?.data) {
        const requestErrors = parseError(e.response.data);
        enqueueSnackbar(Object.values(requestErrors).join('\n'), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar(commonError, { variant: 'error' });
      }
      setCreationLoading(false);
    }
  };

  const handleOpenAccountsModal = () => {
    setOpenAccountsModal(true);
  };
  const handleCloseAccountsModal = () => {
    setOpenAccountsModal(false);
  };
  const handleOpenNewAccountModal = () => {
    setOpenNewAccountModal(true);
  };
  const handleCloseNewAccountModal = () => {
    setOpenNewAccountModal(false);
  };
  const handleOpenNewAccountFromAccounts = () => {
    setOpenAccountsModal(false);
    setOpenNewAccountModal(true);
  };

  return (
    <>
      <Box
        display='flex'
        alignItems='center'
        flexDirection='column'
        width='100%'
      >
        <Typography
          variant='h5'
          color='secondary'
          display='inline'
          align='center'
        >
          Tu cambio para{' '}
          <Typography variant='inherit' component='span' color='primary'>
            hoy es:
          </Typography>
        </Typography>
        {children}

        <Container maxWidth='md' disableGutters>
          <ExchangeInfo
            sendCurrency={sendValue.currency}
            sendValue={sendValue.amount}
            receiveCurrency={getReceiveCurrency()}
            receiveValue={getReceiveAmount()}
            exchange={getExchangeRate()}
          />
        </Container>
        <Container maxWidth='lg' disableGutters>
          <Grid
            container
            justify='center'
            spacing={matches ? 8 : 0}
            className={classes.dataContainer}
          >
            <Grid item xs={12} md={6} className={classes.dataItem}>
              <div className={classes.textContainer}>
                <Typography
                  variant='h5'
                  color='secondary'
                  display='inline'
                  align='center'
                >
                  ¿Desde qué banco envías
                  <br />
                  <Typography
                    variant='inherit'
                    component='span'
                    color='primary'
                  >
                    tu dinero?
                  </Typography>
                </Typography>

                <Typography variant='h6' align='center'>
                  Selecciona el banco de donde transferiras tu dinero
                </Typography>
              </div>

              <TextField
                variant='outlined'
                label='- Selecciona un banco -'
                fullWidth
                className={classes.selectInput}
                size='small'
                select
                value={selectedBankId}
                margin='normal'
                onChange={(e) => setSelectedBankId(Number(e.target.value))}
              >
                {banksList.map(({ id, name }) => (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Hidden smUp>
              <Grid item xs={12}>
                <Box py={2}>
                  <Divider className={classes.divider} />
                </Box>
              </Grid>
            </Hidden>
            <Grid item xs={12} md={6} className={classes.dataItem}>
              <div className={classes.textContainer}>
                <Typography
                  variant='h5'
                  color='secondary'
                  display='inline'
                  align='center'
                >
                  ¿En qué cuenta deseas
                  <br />
                  <Typography
                    variant='inherit'
                    component='span'
                    color='primary'
                  >
                    recibir tu dinero?
                  </Typography>
                </Typography>
                {selectedAccountId && selectedAccount ? (
                  <SelectedAccount account={selectedAccount} />
                ) : (
                  <Typography variant='h6' align='center'>
                    Selecciona la cuenta en donde recibirás tu dinero
                  </Typography>
                )}
              </div>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Button
                    variant='contained'
                    color='secondary'
                    size='large'
                    fullWidth
                    startIcon={<CreditCardsIcon />}
                    onClick={handleOpenAccountsModal}
                    classes={{
                      root: classes.button,
                      startIcon: classes.buttonIconPrimary,
                      label: classes.buttonLabel,
                    }}
                    disabled={!accountsMap.size}
                  >
                    Mis Cuentas
                  </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button
                    variant='contained'
                    color='primary'
                    size='large'
                    fullWidth
                    startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
                    onClick={handleOpenNewAccountModal}
                    classes={{
                      root: classes.button,
                      startIcon: classes.buttonIconSecondary,
                      label: classes.buttonLabel,
                    }}
                  >
                    Agregar Cuenta
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>

        <Grid container justify='center' spacing={2}>
          <Grid item>
            <Button
              color='primary'
              variant='outlined'
              onClick={onPressPrev}
              className={classes.stepButton}
            >
              Regresar
            </Button>
          </Grid>
          <Grid item>
            <Tooltip
              title='Antes de seguir selecciona un banco y una cuenta'
              placement='top'
            >
              <span>
                <Button
                  color='primary'
                  variant='contained'
                  onClick={handleCreateExchange}
                  disabled={!selectedAccountId || !selectedBankId}
                  className={classes.stepButton}
                >
                  Procesar
                </Button>
              </span>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
      <ClientAccountsModal
        open={openAccountsModal}
        onClose={handleCloseAccountsModal}
        accountsMap={accountsMap}
        selectedAccount={selectedAccountId}
        setSelectedAccount={setSelectedAccountId}
        onOpenNewAccountModal={handleOpenNewAccountFromAccounts}
      />
      <CreateAccountModal
        open={openNewAccountModal}
        initialCurrency={getReceiveCurrency()}
        onClose={handleCloseNewAccountModal}
        onSuccess={handleAddAccount}
      />
      <BackdropLoading loading={loading} />
    </>
  );
}
