import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    code: {
      fontWeight: theme.typography.fontWeightRegular,
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(4),
    },
    card: {
      border: '3px solid #E8E8E8',
      borderRadius: 7,
      boxShadow: theme.custom.inputShadow,
      padding: theme.spacing(5, 4),
    },
    textWithDecorations: {
      color: '#606060',
      '& > strong': {
        color: theme.palette.primary.main,
      },
    },
    description: {
      fontSize: '1.125rem',
    },
    descriptionGrid: {
      '& > div': {
        minWidth: 'max-content',
      },
    },
    descriptionItem: {
      '& > span': {
        color: theme.palette.common.black,
        fontWeight: theme.typography.fontWeightBold,
        display: 'block',
      },
    },
    button: {
      display: 'block',
      margin: theme.spacing(3, 'auto', 0),
      textTransform: 'none',
      fontWeight: 'normal',
    },
    errorIcon: {
      textAlign: 'center',
      fontSize: '10rem',
      color: theme.palette.warning.light,
    },
  })
);
