import { createMuiTheme } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import types from '@material-ui/lab/themeAugmentation';

// Follow https://material-ui.com/guides/typescript/#customization-of-theme
// to add custom attributes to the theme
declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    custom: {
      inputShadow: string;
      dashboardDrawer: {
        width: number;
      };
    };
  }
  interface ThemeOptions {
    custom?: {
      inputShadow?: string;
      dashboardDrawer?: {
        width?: number;
      };
    };
  }
}

const primaryColor = '#70A7B6';
const secondaryColor = '#023750';

export const fontMyriad = ['Myriad', 'sans-serif'].join(',');
export const fontLeagueSpartan = ['League Spartan', 'sans-serif'].join(',');
export const fontJosefinSans = ['Josefin Sans', 'sans-serif'].join(',');

const theme = createMuiTheme({
  palette: {
    primary: { main: primaryColor, light: '#a1d9e8', dark: '#407886' },
    secondary: { main: secondaryColor },
    grey: {
      300: '#e5e5e5',
    },
    text: {
      primary: '#5b5b5b',
      secondary: '#afafaf',
    },
  },
  typography: {
    button: {
      fontWeight: 700,
      lineHeight: 1.35,
      fontSize: '1rem',
    },
    h3: {
      fontFamily: fontLeagueSpartan,
      fontWeight: 400,
    },
    h4: {
      fontFamily: fontLeagueSpartan,
      fontWeight: 400,
    },
    h5: {
      fontFamily: fontLeagueSpartan,
      fontWeight: 400,
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: { overflowX: 'hidden' },
      },
    },
    MuiButton: {
      root: {
        minHeight: 36,
        borderRadius: 8,
        padding: '6px 26px',
      },
      contained: {
        boxShadow: 'initial',
      },
      containedPrimary: { color: '#fff' },
      outlined: {
        padding: '6px 24px',
        borderWidth: 2,
        '&:hover': {
          borderWidth: 2,
        },
        '&$disabled': {
          borderWidth: 2,
        },
      },
      outlinedPrimary: {
        borderColor: primaryColor,
        borderWidth: 2,
        '&:hover': {
          borderWidth: 2,
        },
      },
      outlinedSecondary: {
        borderColor: secondaryColor,
        borderWidth: 2,
        '&:hover': {
          borderWidth: 2,
        },
        '&$disabled': {
          borderWidth: 2,
        },
      },
      text: {
        padding: '6px 24px',
      },
    },
    MuiStepIcon: {
      root: {
        '&$active': {
          color: secondaryColor,
        },
        '&$completed': {
          color: secondaryColor,
        },
      },
      text: {
        fill: '#fff',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 8,
        '& $notchedOutline': {
          borderWidth: 2,
          borderColor: '#E8E8E8',
        },
      },
      input: {
        '&::placeholder': {
          fontStyle: 'italic',
        },
      },
    },
    MuiInputLabel: {
      outlined: {
        fontStyle: 'italic',
      },
    },
    MuiSelect: {
      icon: {
        color: primaryColor,
      },
    },
    MuiAutocomplete: {
      popupIndicator: {
        color: primaryColor,
      },
    },
  },
  custom: {
    inputShadow: `5px 5px 10px 0 ${primaryColor}1A`,
    dashboardDrawer: {
      width: 300,
    },
  },
});

export const headerHeight = theme.spacing(15);
export const headerHeightSm = theme.spacing(10);
export const globalSecondaryColor = secondaryColor;

export default theme;
