import React from 'react';
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Typography,
} from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import { useSnackbar } from 'notistack';

import { BaseModal, CustomTextField } from 'components';
import sideCard from 'assets/images/exchange/discount-side-card.png';
import { Yup } from 'utils';
import { commonError } from 'common/errorMessages';

import useStyles from './styles';

interface DiscountCodeModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (c: string) => Promise<any>;
}

const validationSchema = Yup.object({
  code: Yup.string().required(),
});

const initialValues = {
  code: '',
};

export default function DiscountCodeModal({
  open,
  onClose,
  onSubmit,
}: DiscountCodeModalProps) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const handleAccept = () => {
    onClose();
  };

  return (
    <BaseModal open={open} onClose={onClose}>
      <Card className={classes.discountCodeWrapper}>
        <CardMedia
          className={classes.discountCodeSideImg}
          image={sideCard}
          title='Ingrese código de descuento'
        />
        <div className={classes.discountCodeDetails}>
          <CardContent className={classes.discountCodeContent}>
            <Typography
              className={classes.discountCodeTitle}
              variant='h5'
              color='secondary'
              align='center'
              gutterBottom
            >
              Ingresa tu código
              <br /> de <span>mejora</span>
            </Typography>
            <Typography color='textSecondary' gutterBottom align='center'>
              Obtén el mejor cambio <br /> con un mayor beneficio
            </Typography>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={async (
                values: typeof initialValues,
                { setFieldError }
              ) => {
                try {
                  await onSubmit(values.code);
                  handleAccept();
                  enqueueSnackbar('Código de mejora añadido con éxito', {
                    variant: 'success',
                  });
                } catch (e) {
                  if (e?.response?.status === 404) {
                    setFieldError(
                      'code',
                      'El código de mejora es inválido o ha caducado'
                    );
                  } else {
                    enqueueSnackbar(commonError, {
                      variant: 'error',
                    });
                  }
                }
              }}
            >
              {({ isSubmitting }) => (
                <Form>
                  <Field
                    component={CustomTextField.AlphaNumericUpperCase}
                    name='code'
                    variant='outlined'
                    margin='dense'
                    size='small'
                    label='Ingresa tu código'
                    className={classes.discountCodeInput}
                  />
                  <Button
                    type='submit'
                    variant='contained'
                    color='primary'
                    disabled={isSubmitting}
                    className={classes.discountCodeBtn}
                    // onClick={handleAccept}
                  >
                    Aplicar
                  </Button>
                </Form>
              )}
            </Formik>
          </CardContent>
        </div>
      </Card>
    </BaseModal>
  );
}
