import React from 'react';
import { CircularProgress, Container, Grid } from '@material-ui/core';
import { Img } from 'react-image';
import { Link } from 'react-router-dom';

import logoText from 'assets/images/common/logo-text.png';

import { mainRoute as appMainRoute } from '../../routes';
import useStyles from './styles';
import {
  LoginForm,
  ClientTypeSelector,
  RecoverPasswordForm,
  ResetPasswordForm,
} from './componets';

interface LoginWrapperProps {
  children: React.ReactNode;
}

function LoginWrapper({ children }: LoginWrapperProps) {
  const classes = useStyles();

  return (
    <Container maxWidth={false} className={classes.loginWrapper}>
      <Grid container justify='center'>
        <Grid item xs={12}>
          <Link to={appMainRoute} className={classes.loginLogoImg}>
            <Img src={logoText} height='170' loader={<CircularProgress />} />
          </Link>
        </Grid>
        {children}
      </Grid>
    </Container>
  );
}

export default function Login() {
  return (
    <LoginWrapper>
      <LoginForm />
    </LoginWrapper>
  );
}

export function ClientType() {
  return (
    <LoginWrapper>
      <ClientTypeSelector />
    </LoginWrapper>
  );
}

export function RecoverPassword() {
  return (
    <LoginWrapper>
      <RecoverPasswordForm />
    </LoginWrapper>
  );
}

export function ResetPassword() {
  return (
    <LoginWrapper>
      <ResetPasswordForm />
    </LoginWrapper>
  );
}
