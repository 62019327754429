import React from 'react';
import {
  Box,
  Button,
  Grid,
  Link as MuiLink,
  Typography,
} from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import { Field, Form, Formik } from 'formik';
import { Link, useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { api, Yup } from 'utils';
import { parseError } from 'utils/api';
import { loginRoute } from 'routes';
import { commonError } from 'common/errorMessages';

import useStyles from './styles';

const initialValues = {
  email: '',
};

const validationSchema = Yup.object({
  email: Yup.string().required().email().max(254),
});

export default function RecoverPasswordForm() {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  return (
    <>
      <Grid item xs={10} sm={6} lg={3}>
        <Box color='common.white'>
          <Typography color='inherit' align='center' gutterBottom>
            Ingresa tu correo electrónico y te enviaremos instrucciones para que
            recuperes tu contraseña
          </Typography>
        </Box>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (
            { email }: typeof initialValues,
            { setSubmitting, setErrors }
          ) => {
            try {
              await api.post('clients/request-change-password/', {
                email,
              });
              setSubmitting(false);
              history.push(loginRoute, {
                message:
                  'Correo de recuperación de contraseña se envió exitósamente',
              });
            } catch (e) {
              if (e?.response?.data) {
                setErrors(parseError(e.response.data));
              } else {
                enqueueSnackbar(commonError, { variant: 'error' });
              }
              setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <Field
                component={TextField}
                name='email'
                type='email'
                autoComplete='email'
                placeholder='Ingresa tu correo electrónico'
                variant='outlined'
                size='small'
                margin='normal'
                fullWidth
                className={classes.loginInput}
              />
              <Button
                type='submit'
                variant='contained'
                color='primary'
                className={classes.loginSubmitBtn}
                disabled={isSubmitting}
              >
                Recuperar contraseña
              </Button>
            </Form>
          )}
        </Formik>
        <Typography color='primary' gutterBottom align='center'>
          <MuiLink component={Link} to={loginRoute} underline='always'>
            Regresar
          </MuiLink>
        </Typography>
      </Grid>
    </>
  );
}
