import React from 'react';
import {
  AppBar,
  Avatar,
  Box,
  IconButton,
  CircularProgress,
  Divider,
  Drawer,
  Link as MuiLink,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Hidden,
  SwipeableDrawer,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Img } from 'react-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle, faList } from '@fortawesome/free-solid-svg-icons';
import { Link, useHistory, useLocation } from 'react-router-dom';

import {
  CreditCardsIcon,
  DiscountIcon,
  ExchangeIcon,
  OperationsIcon,
} from 'components/CustomIcons';
import logoText from 'assets/images/common/logo-text.png';
import { useSessionContext } from 'context/Session';
import { mainRoute as appMainRoute, selectTypeRoute } from 'routes';
import { useProfileContext } from 'context/Profile';
import ClientType from 'common/clientType';

import {
  bankAccountsRoute,
  exchangeRoute,
  getDiscountsRoute,
  operationsRoute,
  profileRoute,
} from '../../routes';

import useStyles from './styles';

export default function SideNav() {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const { pathname } = useLocation();
  const history = useHistory();
  const { removeToken, currentClientType } = useSessionContext();
  const { client } = useProfileContext();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  let name = '';
  if (client && currentClientType) {
    name =
      currentClientType === ClientType.Person
        ? `${client.name} ${client.fathersLastName || client.mothersLastName}`
        : client.businessName;
  }

  const handleLogout = () => {
    removeToken();
    history.push(appMainRoute);
  };

  const handleChangeUserType = () => {
    history.push(selectTypeRoute);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const navContent = (
    <>
      <Box pb={3}>
        <Avatar className={classes.sideNavAvatar} />
        <Typography
          variant='h6'
          align='center'
          className={classes.sideNavWelcomeText}
        >
          BIENVENIDO(A)
        </Typography>
        <Typography color='primary' align='center'>
          {name}
        </Typography>
      </Box>
      <Divider className={classes.sideNavDivider} />
      <List>
        <ListItem
          button
          disableGutters
          selected={pathname === profileRoute}
          onClick={() => {
            history.push(profileRoute);
            matches && handleClose();
          }}
          className={classes.sideNavItem}
        >
          <ListItemIcon className={classes.sideNavItemIcon}>
            <FontAwesomeIcon icon={faUserCircle} size='2x' />
          </ListItemIcon>
          <ListItemText
            primary='Mi Perfil'
            className={classes.sideNavItemText}
          />
        </ListItem>
        <ListItem
          button
          disableGutters
          selected={pathname.startsWith(exchangeRoute)}
          onClick={() => {
            history.push(exchangeRoute);
            matches && handleClose();
          }}
          className={classes.sideNavItem}
        >
          <ListItemIcon className={classes.sideNavItemIcon}>
            <ExchangeIcon />
          </ListItemIcon>
          <ListItemText primary='Cambiar' className={classes.sideNavItemText} />
        </ListItem>
        <ListItem
          button
          disableGutters
          selected={pathname === operationsRoute}
          onClick={() => {
            history.push(operationsRoute);
            matches && handleClose();
          }}
          className={classes.sideNavItem}
        >
          <ListItemIcon className={classes.sideNavItemIcon}>
            <OperationsIcon />
          </ListItemIcon>
          <ListItemText
            primary='Mis Operaciones'
            className={classes.sideNavItemText}
          />
        </ListItem>
        <ListItem
          button
          disableGutters
          selected={pathname === bankAccountsRoute}
          onClick={() => {
            history.push(bankAccountsRoute);
            matches && handleClose();
          }}
          className={classes.sideNavItem}
        >
          <ListItemIcon className={classes.sideNavItemIcon}>
            <CreditCardsIcon />
          </ListItemIcon>
          <ListItemText
            primary='Cuentas Bancarias'
            className={classes.sideNavItemText}
          />
        </ListItem>
        <ListItem
          button
          disableGutters
          selected={pathname === getDiscountsRoute}
          onClick={() => {
            history.push(getDiscountsRoute);
            matches && handleClose();
          }}
          className={classes.sideNavItem}
        >
          <ListItemIcon className={classes.sideNavItemIcon}>
            <DiscountIcon />
          </ListItemIcon>
          <ListItemText
            primary='Obtener Descuentos'
            className={classes.sideNavItemText}
          />
        </ListItem>
      </List>
      <List>
        <ListItem
          button
          className={classes.sideNavBottomItem}
          onClick={() => {
            handleLogout();
            matches && handleClose();
          }}
        >
          <ListItemText
            primary='Cerrar Sesión'
            className={classes.sideNavBottomItemText}
          />
        </ListItem>
        <ListItem button className={classes.sideNavBottomItem}>
          <ListItemText
            primary='Cambiar de usuario'
            className={classes.sideNavBottomItemText}
            onClick={() => {
              handleChangeUserType();
              matches && handleClose();
            }}
          />
        </ListItem>
      </List>
      <MuiLink component={Link} to={appMainRoute}>
        <Img
          src={logoText}
          height='140'
          className={classes.sideNavLogoImg}
          loader={<CircularProgress />}
        />
      </MuiLink>
    </>
  );

  return (
    <>
      <Hidden smDown implementation='css'>
        <Drawer
          className={classes.sideNavDrawer}
          variant='permanent'
          classes={{
            paper: classes.sideNavDrawerPaper,
          }}
          anchor='left'
        >
          {navContent}
        </Drawer>
      </Hidden>
      <Hidden mdUp implementation='css'>
        <AppBar
          position='fixed'
          color='secondary'
          className={classes.sideNavAppBar}
        >
          <Toolbar className={classes.sideNavToolbar} disableGutters>
            <IconButton color='primary' onClick={handleOpen} edge='start'>
              <FontAwesomeIcon icon={faList} size='lg' />
            </IconButton>
            <p className={classes.logoText}>kambiazo.pe</p>
          </Toolbar>
        </AppBar>
        <SwipeableDrawer
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          classes={{
            paper: classes.sideNavDrawerPaper,
          }}
        >
          {navContent}
        </SwipeableDrawer>
      </Hidden>
    </>
  );
}
