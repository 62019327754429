import React, { memo, useState, useCallback } from 'react';
import { format } from 'date-fns';

import {
  Button,
  Card,
  CardContent,
  CardMedia,
  TextField,
  Typography,
  CircularProgress,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Hidden,
} from '@material-ui/core';

import logo from 'assets/images/common/logo-text-background.png';
import { formatMoney } from 'common/money';
import { BaseModal } from 'components';

import useStyles from '../styles';
import Transaction from '../interfaces';

interface Props {
  open: boolean;
  loadingReport: boolean;
  handleCloseModal: () => void;
  handleCloseReportOperationModal: (transactionNumber: string) => void;
  reportOperation: Transaction;
}

const ReportOperationModal = memo(
  ({
    open,
    reportOperation,
    handleCloseReportOperationModal,
    handleCloseModal,
    loadingReport,
  }: Props) => {
    const classes = useStyles();
    const [operationCodeInput, setOperationCodeInput] = useState('');

    const handleChangeOperationCodeInput = useCallback(
      ({ target: { value } }: React.ChangeEvent<{ value: unknown }>) => {
        setOperationCodeInput(value as string);
      },
      [setOperationCodeInput]
    );

    if (!reportOperation) return null;

    return (
      <BaseModal open={open} onClose={handleCloseModal}>
        <Card className={classes.reportOperationWrapper}>
          <Hidden smDown>
            <CardMedia
              className={classes.reportOperationSideImg}
              image={logo}
              title='Ingresa tu número de operación'
            />
          </Hidden>
          <div className={classes.reportOperationDetails}>
            <CardContent>
              <Typography
                variant='body1'
                color='secondary'
                align='center'
                className={classes.titleOperationModal}
              >
                Ingresa tu número de operación
              </Typography>
              <TextField
                className={classes.reportOperationInput}
                fullWidth
                variant='outlined'
                margin='dense'
                size='small'
                label=''
                value={operationCodeInput}
                onChange={handleChangeOperationCodeInput}
              />
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell className={classes.titleCell}>
                        Titular:
                      </TableCell>
                      <TableCell className={classes.contentCell}>
                        {reportOperation?.holderDepotAccount}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.titleCell}>
                        Fecha y Hora:
                      </TableCell>
                      <TableCell className={classes.contentCell}>
                        {format(
                          new Date(reportOperation.date),
                          "dd/MM/yyyy H:mm'hrs'"
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.titleCell}>
                        Cuenta Origen:
                      </TableCell>
                      <TableCell className={classes.contentCell}>
                        {reportOperation?.numberDepotAccount}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.titleCell}>
                        Cuenta Destino:
                      </TableCell>
                      <TableCell className={classes.contentCell}>
                        {reportOperation?.fromAdminAccount.number}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.titleCell}>
                        Titular Destino:
                      </TableCell>
                      <TableCell className={classes.contentCell}>
                        {reportOperation?.fromAdminAccount.holdersName}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.titleCell}>
                        Monto:
                      </TableCell>
                      <TableCell className={classes.contentCell}>
                        {formatMoney(
                          reportOperation.fromAdminAccount.currency,
                          reportOperation.amount
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <div className={classes.reportButtonContainer}>
                <Button
                  className={classes.reportButton}
                  size='small'
                  variant='contained'
                  color='primary'
                  onClick={() =>
                    handleCloseReportOperationModal(operationCodeInput)
                  }
                >
                  Reportar operación
                </Button>
              </div>
              {loadingReport && (
                <div className={classes.deleteLoadingContainer}>
                  <CircularProgress />
                </div>
              )}
            </CardContent>
          </div>
        </Card>
      </BaseModal>
    );
  }
);

export default ReportOperationModal;
