import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    profileTitle: {
      '& span': {
        color: theme.palette.primary.main,
      },
    },
    profileFormBtn: {
      textTransform: 'capitalize',
      fontWeight: 'normal',
    },
    profileActions: {
      display: 'flex',
      justifyContent: 'flex-end',

      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
      },
    },
    form: {
      '& [class*="MuiInputBase-root"]': {
        boxShadow: theme.custom.inputShadow,
        backgroundColor: theme.palette.common.white,
      },
    },
    profileWrapper: {
      [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(4, 0),
      },
    },
  })
);
