import React, { useEffect, useRef } from 'react';
import {
  Switch,
  BrowserRouter,
  Route,
  RouteProps,
  Redirect,
  useLocation,
} from 'react-router-dom';

import { Footer, Navbar } from 'components';
import { useSessionContext } from 'context/Session';
import {
  ClientType,
  Home,
  Login,
  NotFound,
  Register,
  Dashboard,
  RecoverPassword,
  ResetPassword,
} from 'pages';
import { mainRoute as dashboardRoute } from 'pages/Dashboard/routes';

import {
  loginRoute,
  mainRoute as appMainRoute,
  registerRoute,
  selectTypeRoute,
  recoverPasswordRoute,
  resetPasswordRoute,
} from './routes';

interface PrivateRouteProps extends RouteProps {}

function ScrollToTop() {
  const previousPath = useRef<string | null>(null);
  const { pathname } = useLocation();

  useEffect(() => {
    if (!previousPath.current) {
      previousPath.current = pathname;
      return;
    }
    if (previousPath.current !== pathname) {
      window.scrollTo(0, 0);
      previousPath.current = pathname;
    }
  }, [pathname]);

  return null;
}

function PrivateRoute(props: PrivateRouteProps) {
  const { pathname, state } = useLocation();
  const { isAuthenticated, currentClientType } = useSessionContext();

  if (!isAuthenticated) {
    return (
      <Redirect
        from={pathname}
        to={{ pathname: loginRoute, state: { ...state, redirectTo: pathname } }}
      />
    );
  }
  if (!currentClientType && pathname !== selectTypeRoute) {
    return (
      <Redirect
        from={pathname}
        to={{
          pathname: selectTypeRoute,
          state: { ...state, redirectTo: pathname },
        }}
      />
    );
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Route {...props} />;
}

function RoutesWithHeader() {
  return (
    <>
      <Navbar />
      <Switch>
        <Route path={appMainRoute} exact component={Home} />
        <Route path={registerRoute} exact component={Register} />
        <Route component={NotFound} />
      </Switch>
      <Footer />
    </>
  );
}

function AppRoutes() {
  // Routes should be ordered with the more specific routes at the top
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Switch>
        <PrivateRoute path={dashboardRoute} component={Dashboard} />
        <Route path={loginRoute} exact component={Login} />
        <PrivateRoute path={selectTypeRoute} exact component={ClientType} />
        <Route path={recoverPasswordRoute} exact component={RecoverPassword} />
        <Route path={resetPasswordRoute} exact component={ResetPassword} />
        <Route path='/' component={RoutesWithHeader} />
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
}

export default AppRoutes;
