import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Container,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import useAxios from 'axios-hooks';
import { useSnackbar } from 'notistack';

import { BackdropLoading, BaseModal, CustomTextField } from 'components';
import { Currency, CurrencyName } from 'common/money';
import { DocumentName } from 'common/document';
import { api, Yup } from 'utils';
import { parseError } from 'utils/api';
import { documentNumberValidator } from 'utils/validators';
import { commonError } from 'common/errorMessages';

import useStyles from './styles';

type ListType = Array<{ id: number; name: string }>;

interface CreateAccountModalProps {
  open: boolean;
  onClose: () => void;
  onSuccess: (newAccount: any) => void;
  initialCurrency?: Currency;
}

const defaultProps = {
  initialCurrency: undefined,
};

const initialValues = {
  bank: '',
  accountType: '',
  currency: '',
  number: '',
  interbankNumber: '',
  holdersName: '',
  documentType: '',
  documentNumber: '',
};

const validationSchema = Yup.object({
  bank: Yup.number().required(),
  accountType: Yup.number().required(),
  currency: Yup.string().required(),
  number: Yup.string()
    .required()
    .min(13, 'Número de cuenta debe tener 13 caracteres como mínimo')
    .max(20, 'Número de cuenta solo puede tener 20 caracteres como máximo'),
  interbankNumber: Yup.string()
    .required()
    .length(20, 'Código de cuenta interbancaria debe contener 20 caracteres'),
  holdersName: Yup.string().required(),
  documentType: Yup.string().required(),
  documentNumber: Yup.string()
    .required()
    .when('documentType', documentNumberValidator),
});

export default function CreateAccountModal({
  open,
  onClose,
  onSuccess,
  initialCurrency,
}: CreateAccountModalProps) {
  const classes = useStyles();
  const [banksList, setBanksList] = useState<ListType>([]);
  const [types, setTypes] = useState<ListType>([]);
  const [typesResponse] = useAxios('configurations/accounts-type/');
  const [banksResponse] = useAxios('configurations/banks/');
  const { enqueueSnackbar } = useSnackbar();

  const loadingFetch = typesResponse.loading || banksResponse.loading;

  useEffect(() => {
    const { data, loading, error } = banksResponse;
    if (loading) {
      return;
    }

    if (!error) {
      const { results } = data;
      setBanksList(results);
    } else {
      enqueueSnackbar(commonError, { variant: 'error' });
    }
  }, [banksResponse, enqueueSnackbar]);

  useEffect(() => {
    const { data, loading, error } = typesResponse;
    if (loading) {
      return;
    }

    if (!error) {
      const { results } = data;
      setTypes(results);
    } else {
      enqueueSnackbar(commonError, { variant: 'error' });
    }
  }, [enqueueSnackbar, typesResponse]);

  return (
    <BaseModal open={open} onClose={onClose}>
      <Box display='flex' flexDirection='column' alignItems='center' p={7}>
        <Typography
          variant='h5'
          color='secondary'
          display='inline'
          align='center'
        >
          Agregar una nueva
          <br />
          <Typography variant='inherit' component='span' color='primary'>
            cuenta
          </Typography>
        </Typography>
        <Container maxWidth='xs'>
          <Formik
            initialValues={{
              ...initialValues,
              ...(initialCurrency ? { currency: initialCurrency } : {}),
            }}
            validationSchema={validationSchema}
            onSubmit={async (values: typeof initialValues, { setErrors }) => {
              try {
                const success = await api.post('clients/accounts/', values);
                onSuccess(success.data);
                enqueueSnackbar('Se creó una nueva cuenta exitosamente', {
                  variant: 'success',
                });
                onClose();
              } catch (e) {
                if (e?.response?.data) {
                  setErrors(parseError(e.response.data));
                } else {
                  enqueueSnackbar(commonError, { variant: 'error' });
                }
              }
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <Box my={2}>
                  <Field
                    component={TextField}
                    name='bank'
                    label='- Selecciona un banco -'
                    variant='outlined'
                    fullWidth
                    size='small'
                    select
                    margin='normal'
                  >
                    {banksList.map(({ id, name }) => (
                      <MenuItem key={id} value={id}>
                        {name}
                      </MenuItem>
                    ))}
                  </Field>
                  <Field
                    component={TextField}
                    name='accountType'
                    label='Tipo de cuenta'
                    variant='outlined'
                    fullWidth
                    size='small'
                    select
                    margin='normal'
                  >
                    {types.map(({ id, name }) => (
                      <MenuItem key={id} value={id}>
                        {name}
                      </MenuItem>
                    ))}
                  </Field>
                  <Field
                    component={TextField}
                    name='currency'
                    label='Moneda'
                    variant='outlined'
                    fullWidth
                    size='small'
                    select
                    margin='normal'
                    disabled={!!initialCurrency}
                  >
                    {Object.entries(CurrencyName).map(([key, name]) => (
                      <MenuItem key={key} value={key}>
                        {name}
                      </MenuItem>
                    ))}
                  </Field>
                  <Field
                    component={CustomTextField.OnlyNumbers}
                    name='number'
                    label='Número de la cuenta'
                    variant='outlined'
                    fullWidth
                    size='small'
                    margin='normal'
                  />
                  <Field
                    component={CustomTextField.OnlyNumbers}
                    name='interbankNumber'
                    label='Código de Cuenta Interbancaria'
                    variant='outlined'
                    fullWidth
                    size='small'
                    margin='normal'
                  />
                  <Field
                    component={CustomTextField.LettersUpperCase}
                    name='holdersName'
                    label='Nombre del titular'
                    variant='outlined'
                    fullWidth
                    size='small'
                    margin='normal'
                  />
                  <Field
                    component={TextField}
                    name='documentType'
                    label='Tipo de documento'
                    variant='outlined'
                    fullWidth
                    size='small'
                    select
                    margin='normal'
                  >
                    {Object.entries(DocumentName).map(([key, name]) => (
                      <MenuItem key={key} value={key}>
                        {name}
                      </MenuItem>
                    ))}
                  </Field>
                  <Field
                    component={TextField}
                    name='documentNumber'
                    label='Número de documento'
                    variant='outlined'
                    fullWidth
                    size='small'
                    margin='normal'
                  />
                </Box>

                <Box display='block' mx='auto' clone>
                  <Button
                    variant='contained'
                    color='primary'
                    type='submit'
                    disabled={isSubmitting}
                    className={classes.formButton}
                  >
                    Registrar
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Container>
        <BackdropLoading loading={loadingFetch} absolutePosition />
      </Box>
    </BaseModal>
  );
}

CreateAccountModal.defaultProps = defaultProps;
