import React, { useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { Calculator } from 'components';
import manImage from 'assets/images/home/man.png';
import { useExchangeContext } from 'context/Exchange';
import { Currency } from 'common/money';
import { sectionsIds } from 'components/Navbar';
import { exchangeRoute } from '../../../Dashboard/routes';

import useStyles from './styles';

function ExchangeScreen() {
  const {
    buy,
    sell,
    sendValue,
    setSendValue,
    getReceiveAmount,
    clearExchange,
  } = useExchangeContext();
  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    clearExchange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleProceedExchange = (
    sendAmount: string,
    sendCurrency: Currency
  ) => {
    setSendValue({
      amount: sendAmount,
      currency: sendCurrency,
    });

    history.push(exchangeRoute, {
      message: 'Debe iniciar sesión para continuar.',
    });
  };

  return (
    <div className={classes.exchangeScreen} id={sectionsIds.home}>
      <img src={manImage} alt='man' />
      <div className={classes.textAndCalculator}>
        <div className={classes.text}>
          <Typography variant='h3' color='secondary' className={classes.title}>
            Cambia dólares desde tu celular al{' '}
            <Typography variant='inherit' component='span' color='primary'>
              mejor tipo de cambio
            </Typography>
          </Typography>
          <Typography variant='h6' className={classes.subtitle}>
            Compra/vende dólares o paga tu tarjeta de crédito de manera segura y
            rápida sin salir de casa.
          </Typography>
        </div>

        <Calculator
          buy={buy}
          sell={sell}
          buttonText='Iniciar la operación'
          defaultSendAmount={sendValue.amount}
          defaultSendCurrency={sendValue.currency}
          defaultReceiveAmount={getReceiveAmount()}
          onExchange={handleProceedExchange}
          className={classes.calculator}
        />
      </div>
    </div>
  );
}

export default ExchangeScreen;
