import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useCalculatorStyles = makeStyles((theme: Theme) =>
  createStyles({
    calculator: {
      display: 'flex',
      flexFlow: 'column nowrap',
      position: 'relative',

      '& > button:nth-child(2n)': {
        marginTop: theme.spacing(2),
      },

      [theme.breakpoints.up('md')]: {
        minWidth: 'max-content',
      },
    },
    cardContainer: {
      minWidth: 'max-content',
    },
    inputsContainer: {
      position: 'relative',
      display: 'flex',
      flexFlow: 'column nowrap',
      marginBottom: theme.spacing(1.5),
    },
    widePriceCards: {
      maxWidth: 400,
      margin: theme.spacing(0, 'auto'),
      [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(-1),
      },
    },
  })
);

export const usePriceCardStyles = makeStyles((theme: Theme) =>
  createStyles({
    priceCard: {
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      flexFlow: 'column nowrap',
      minWidth: 'max-content',

      borderRadius: 16,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,

      [theme.breakpoints.up('md')]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },

      '& > h1': {
        fontWeight: theme.typography.fontWeightBold,
        fontSize: '0.75rem',
        textTransform: 'uppercase',
      },
    },
    value: {
      display: 'flex',
      alignItems: 'baseline',
    },
    currency: {
      fontWeight: theme.typography.fontWeightBold,
      fontSize: '2rem',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.25rem',
      },
    },
    price: {
      fontWeight: theme.typography.fontWeightBold,
      fontSize: '2.75rem',
      lineHeight: '1em',
      marginLeft: theme.spacing(1),
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.4rem',
      },
    },
  })
);

export const useInputStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      display: 'flex',
      flexWrap: 'nowrap',
      alignItems: 'center',
      border: 'solid 1px #e8e8e8',
      backgroundColor: theme.palette.common.white,
      borderRadius: 16,
      boxShadow: theme.custom.inputShadow,
      maxWidth: '95vw',
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(1, 4, 1, 3),
      },
    },
    currency: {
      fontSize: '1.25rem',
      lineHeight: '1.1em',
      color: '#5b5b5b',

      [theme.breakpoints.down('xs')]: {
        fontSize: '1.0rem',
      },
    },
    currencySign: {
      fontWeight: theme.typography.fontWeightBold,
      fontSize: '2.5rem',
      lineHeight: '1.1em',
      color: theme.palette.common.black,
      marginLeft: theme.spacing(2.5),

      [theme.breakpoints.down('xs')]: {
        fontSize: '2.0rem',
      },
    },
    inputInput: {
      display: 'flex',
      width: '100%',
      flexFlow: 'column nowrap',
      alignItems: 'flex-end',
      marginLeft: 'auto',

      '& > label': {
        fontWeight: theme.typography.fontWeightBold,
        fontSize: '0.75rem',
        textTransform: 'uppercase',
        color: theme.palette.primary.main,
      },

      '& > input': {
        fontSize: '2rem',
        fontWeight: theme.typography.fontWeightBold,
        textAlign: 'right',
        color: theme.palette.common.black,
        background: theme.palette.common.white,
        width: '100%',
        maxWidth: theme.spacing(29),
        border: 0,
        outline: 0,
        padding: 0,
        '&:focus': {
          outline: 0,
        },
        '&:active': {
          outline: 0,
        },
      },
    },
    wideInput: {
      '& > input': {
        maxWidth: 'none',
      },
    },
  })
);
export const useFlipStyles = makeStyles((theme: Theme) =>
  createStyles({
    flip: {
      position: 'absolute',
      width: 45,
      height: 45,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      color: theme.palette.common.white,
      boxShadow: theme.custom.inputShadow,
      backgroundColor: theme.palette.primary.main,

      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
    },
    '@keyframes rotate': {
      '0%': {
        transform: 'rotate(0)',
      },
      '100%': {
        transform: 'rotate(180deg)',
      },
    },
    rotate: {
      animation:
        '$rotate 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55) backwards',
    },
  })
);
