import React, { useState } from 'react';
import {
  Container,
  Grid,
  CircularProgress,
  Typography,
  IconButton,
  Box,
  Hidden,
  Link,
} from '@material-ui/core';
import { Img } from 'react-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInstagram,
  faFacebookF,
  faWhatsapp,
} from '@fortawesome/free-brands-svg-icons';

import logoText from 'assets/images/common/logo-text.png';
import cellphones from 'assets/images/home/cellphones.png';
import locationIcon from 'assets/images/footer/location.png';
import emailIcon from 'assets/images/footer/mail.png';
import phoneIcon from 'assets/images/footer/phone.png';
import { sectionsIds } from '../Navbar';

import useStyles from './styles';
import TermsAndConditionsModal from '../TextPages/TermsAndConditionsModal';
import PrivacyPolicyModal from '../TextPages/PrivacyPolicyModal';

const facebookLink = 'https://www.facebook.com/kambiazo.pe/';
const whatsappLink = 'https://wa.me/51963127080';
const instagramLink = 'https://www.instagram.com/kambiazo.pe/';

export default function Footer() {
  const classes = useStyles();
  const [termsModalIsOpen, setTermsModalIsOpen] = useState(false);
  const [privacyModalIsOpen, setPrivacyModalIsOpen] = useState(false);

  return (
    <>
      <Container
        maxWidth={false}
        className={classes.footer}
        id={sectionsIds.contactUs}
      >
        <Grid
          container
          spacing={5}
          justify='space-around'
          alignItems='flex-start'
        >
          <Grid item xs={12} md>
            <Img
              src={logoText}
              height='170'
              className={classes.footerLogoImg}
              loader={<CircularProgress />}
            />
          </Grid>
          <Grid item container xs={12} sm direction='column' spacing={2}>
            <Grid item className={classes.contactsUs}>
              <Typography variant='h5' color='primary'>
                Contáctanos
              </Typography>
              <div>
                <div className={classes.contactUsItem}>
                  <img src={phoneIcon} alt='teléfono' />

                  <Typography
                    variant='body2'
                    component='a'
                    href='tel:+51963127080'
                    className={classes.footerLink}
                    color='inherit'
                  >
                    (+51) 963 127 080
                  </Typography>
                </div>
                <div className={classes.contactUsItem}>
                  <img src={emailIcon} alt='correo electrónico' />
                  <Typography
                    variant='body2'
                    component='a'
                    href='mailto:contacto@kambiazo.pe'
                    className={classes.footerLink}
                    color='inherit'
                  >
                    contacto@kambiazo.pe
                  </Typography>
                </div>
                <div className={classes.contactUsItem}>
                  <img src={locationIcon} alt='dirección' />
                  <Typography
                    variant='body2'
                    className={classes.footerMaxContent}
                    color='inherit'
                  >
                    José Mariátegui N°125
                    <br />
                    Santa Victoria. Chiclayo - Perú
                  </Typography>
                </div>
              </div>
            </Grid>

            <Grid item>
              <Typography variant='h5' color='primary' gutterBottom>
                Síguenos
              </Typography>
              <Box component='div' mb={2}>
                <IconButton
                  aria-label='instagram'
                  color='inherit'
                  href={instagramLink}
                  rel='noopener noreferrer'
                  target='_blank'
                  className={classes.socialBtn}
                >
                  <FontAwesomeIcon icon={faInstagram} />
                </IconButton>
                <IconButton
                  aria-label='facebook'
                  color='inherit'
                  href={facebookLink}
                  rel='noopener noreferrer'
                  target='_blank'
                  className={classes.socialBtn}
                >
                  <FontAwesomeIcon icon={faFacebookF} />
                </IconButton>
                <IconButton
                  aria-label='whatsapp'
                  color='inherit'
                  href={whatsappLink}
                  rel='noopener noreferrer'
                  target='_blank'
                  className={classes.socialBtn}
                >
                  <FontAwesomeIcon icon={faWhatsapp} />
                </IconButton>
              </Box>
            </Grid>
          </Grid>

          <Grid item container xs={12} sm direction='column' spacing={2}>
            <Grid item>
              <Typography variant='h5' color='primary'>
                Horario de Atención
              </Typography>
              <Typography
                variant='body2'
                className={classes.footerLink}
                color='inherit'
              >
                Lunes a Viernes: 9:00am a 8:00pm
              </Typography>
            </Grid>

            <Grid item>
              <Typography variant='h5' color='primary'>
                Otros horarios
              </Typography>
              <Typography
                variant='body2'
                className={classes.footerLink}
                color='inherit'
              >
                Abonamos el día siguiente hábil
              </Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12} sm direction='column' spacing={2}>
            <Grid item>
              <Typography variant='h5' color='primary'>
                Legales
              </Typography>

              <Typography
                variant='body2'
                color='inherit'
                gutterBottom
                className={classes.footerLink}
              >
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link
                  color='inherit'
                  underline='none'
                  onClick={() => setTermsModalIsOpen(true)}
                >
                  TÉRMINOS Y CONDICIONES
                </Link>
              </Typography>

              <Typography
                variant='body2'
                color='inherit'
                gutterBottom
                className={classes.footerLink}
              >
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link
                  color='inherit'
                  underline='none'
                  onClick={() => setPrivacyModalIsOpen(true)}
                >
                  POLÍTICA DE PRIVACIDAD
                </Link>
              </Typography>
            </Grid>
          </Grid>
          <Hidden mdDown>
            <Grid item xs={12} md={4} className={classes.footerBigImg}>
              <Img src={cellphones} loader={<CircularProgress />} />
            </Grid>
          </Hidden>
        </Grid>
      </Container>

      <TermsAndConditionsModal
        open={termsModalIsOpen}
        onClose={() => setTermsModalIsOpen(false)}
      />
      <PrivacyPolicyModal
        open={privacyModalIsOpen}
        onClose={() => setPrivacyModalIsOpen(false)}
      />
    </>
  );
}
