export enum Currency {
  USD = 'USD',
  PEN = 'PEN',
}

export const CurrencySign = {
  [Currency.USD]: '$',
  [Currency.PEN]: 'S/',
};

export const CurrencySignComplete = {
  [Currency.USD]: 'US$.',
  [Currency.PEN]: 'S/.',
};

export const CurrencyName = {
  [Currency.USD]: 'Dólares',
  [Currency.PEN]: 'Soles',
};

// Currency formatter is not used because the client wants a different format
const currencyFormatter = new Intl.NumberFormat('en', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export function formatMoney(
  currency: Currency,
  amount: string | number,
  includeSymbol = true
) {
  const formattedAmount = currencyFormatter.format(amount as number);
  if (!includeSymbol) {
    return formattedAmount;
  }
  return `${CurrencySignComplete[currency]} ${formattedAmount}`;
}
