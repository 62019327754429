import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    discountCodeWrapper: {
      display: 'flex',
      minWidth: theme.breakpoints.values.sm + 50,
      height: 350,
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        paddingTop: theme.spacing(3),
        height: 'auto',
        flexDirection: 'column',
        boxShadow: 'none',
        minWidth: '100%',
      },
    },
    discountCodeSideImg: {
      flex: 1,
      height: '100%',
    },
    discountCodeDetails: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    discountCodeTitle: {
      '& span': {
        color: theme.palette.primary.main,
      },
    },
    discountCodeInput: {
      marginBottom: theme.spacing(3),
    },
    discountCodeBtn: {
      textTransform: 'capitalize',
      fontWeight: 'normal',
      display: 'block',
      margin: '0 auto',
    },
    discountCodeContent: {
      textAlign: 'center',
    },
  })
);
